import { Location } from "types/incident"

export enum ActionType {
  SetAudience = "set audience",
  SetTitle = "set title",
  SetLocation = "set location",
  SetUpdate = "set update",
  SetSendNotificationToAllPolygons = "set send notification to all polygons",
  SetCurrentPolygonNotification = "set current polygon notification",
  SetNotifRadius = "set notif radius",
  ClearPolygonNotification = "clear polygon notification",
}

export interface State {
  audience: number
  title?: string
  location?: Location
  update?: string
  notificationToAllPolygons: boolean
  currentPolygonNotification?: boolean
  notifRadius?: number
}

interface Action {
  type: ActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

const incidentCreationReducer = (state: State, action: Action): State => {
  const newState = { ...state }

  switch (action.type) {
    case ActionType.SetAudience:
      newState.audience = action.data
      break
    case ActionType.SetTitle:
      newState.title = action.data
      break
    case ActionType.SetLocation:
      newState.location = action.data
      break
    case ActionType.SetUpdate:
      newState.update = action.data
      break
    case ActionType.ClearPolygonNotification:
      newState.currentPolygonNotification = false
      newState.notificationToAllPolygons = false
      newState.notifRadius = undefined
      break
    case ActionType.SetSendNotificationToAllPolygons:
      newState.notificationToAllPolygons = action.data
      newState.notifRadius = undefined
      break
    case ActionType.SetCurrentPolygonNotification:
      newState.currentPolygonNotification = action.data
      newState.notificationToAllPolygons = false
      newState.notifRadius = undefined
      break
    case ActionType.SetNotifRadius:
      newState.notificationToAllPolygons = false
      newState.currentPolygonNotification = false
      newState.notifRadius = action.data
      break
    default:
      break
  }

  return newState
}

export default incidentCreationReducer
