import React, { useContext } from "react"
import Container from "components/Common/Container"
import styles from "./GoogleLoginButton.module.css"
import Body, { BodyType } from "components/Texts/Body"
import PSNContext from "components/Contexts/PSNContext"

const GoogleLoginButton = () => {
  const psn = useContext(PSNContext)

  if (!psn) {
    return null
  }

  return (
    <a className={styles.container} href={`/${psn.id}/safetrace/gsuite`}>
      <Container className={styles.button}>
        <Container className={styles.logo}>
          <img src='/google-logo.svg' alt='logo' />
        </Container>
        <Container fill left={2} right={2}>
          <Body type={BodyType.Bold} className={styles.buttonText}>
            Sign in with Google
          </Body>
        </Container>
      </Container>
    </a>
  )
}

export default GoogleLoginButton
