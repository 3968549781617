import { Incident, Revision, IncidentSeverity, Update } from "types/incident"
import { first, last, min } from "lodash"
import { Polygon } from "types/psn"
import { inside, polygon, point, lineString } from "turf"
import pointToLineDistance from "@turf/point-to-line-distance"

export const latestRevision = (incident: Incident): Revision | undefined => {
  const latestUpdate = first(incident.updates)
  return latestUpdate ? first(latestUpdate.revisions) : undefined
}

export const getSeverityStyling = (
  incident: Incident,
): { fill: string; boxShadow: string } => {
  if (incident.psnId) {
    return {
      fill: "#4DA6FF",
      boxShadow:
        "0px 1px 1px rgba(48, 48, 48, 0.3), 0px 0px 12px rgba(77, 166, 255, 0.3)",
    }
  }
  if (incident.severity === IncidentSeverity.Red) {
    return {
      fill: "#F2243C",
      boxShadow:
        "0px 1px 1px rgba(48, 48, 48, 0.3), 0px 0px 12px rgba(242, 36, 60, 0.3)",
    }
  }

  return {
    fill: "#FFB300",
    boxShadow:
      "0px 1px 1px rgba(48, 48, 48, 0.3), 0px 0px 12px rgba(255, 179, 0, 0.3)",
  }
}

export const initialRevId = (incident: Incident): string | undefined => {
  // Ignore revs created from API
  const nonAPIUpdates = incident.updates.filter(up => {
    const rev = first(up.revisions)
    return rev && !rev.fromAPI
  })
  if (nonAPIUpdates.length > 0) {
    return (last((last(nonAPIUpdates) as Update).revisions) as Revision).id
  }
  return undefined
}

export const isIncidentInsidePolygon = (
  incident: Incident,
  poly: Polygon,
): boolean => {
  const { lat, lng } = incident.location

  return inside(
    point([lat, lng]),
    polygon(poly.polygon.map(ring => ring.map(([lng, lat]) => [lat, lng]))),
  )
}

export const getClosestPolygonFromIncident = (
  incident: Incident,
  polygons: (Polygon & { id: string })[],
  units = "miles",
): { id: string; distance: number } | null => {
  const coords = [incident.location.lat, incident.location.lng]
  let closest: { id: string; distance: number } | null = null

  for (const i in polygons) {
    const poly = polygons[i]

    const p = poly.polygon.map(ring => ring.map(([lng, lat]) => [lat, lng]))
    const dist = min(
      p.map(line => pointToLineDistance(point(coords), lineString(line)), {
        units,
      }),
    )

    if (isIncidentInsidePolygon(incident, poly)) {
      closest = {
        id: poly.id,
        distance: 0,
      }
      break
    } else if (dist && (!closest || closest.distance > dist)) {
      closest = {
        id: poly.id,
        distance: dist,
      }
    }
  }

  return closest
}
