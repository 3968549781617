import React, { useState, useEffect } from "react"
import styles from "./MarketingMap.module.css"
import GoogleMapReact, { Coords } from "google-map-react"
import { Map as ImmutableMap, List } from "immutable"
import usePolygons from "components/Hooks/usePolygons"
import Marker, {
  Props as MarkerProps,
  MarketingMarkerType,
} from "./MarketingMarker"
import mapStyle from "./style.json"
import { StreamLite } from "api/marketing"
import MarkerDetail from "./MarkerDetail/MarkerDetail"
import { getBoundingBoxOfPolygon } from "utils/map"

interface Props {
  center: Coords
  zoom: number
  bounds: number[]
  polygon: ImmutableMap<string, List<Coords>>
  markers: ImmutableMap<string, MarkerProps>
  generic: boolean
}

const MarketingMap: React.FC<Props> = ({
  center,
  zoom,
  bounds,
  polygon,
  markers,
  generic,
}) => {
  const [map, setMap] = useState()
  const [selected, setSelected] = useState()
  const [selectedStream, setSelectedStream] = useState()
  const [detailPosition, setDetailPosition] = useState()
  const [fakedMarker, setFakedMarker] = useState()

  useEffect(() => {
    if (map) {
      const [minX, minY, maxX, maxY] = bounds
      map.map.fitBounds({
        east: maxY,
        north: minX,
        south: maxX,
        west: minY,
      })
    }
  }, [map, bounds])

  useEffect(() => {
    if (generic) {
      const [, minY, maxX] = getBoundingBoxOfPolygon(polygon.first())
      setFakedMarker({
        lat: maxX,
        lng: minY - 0.0002,
      })
    }
  }, [polygon, generic])

  useEffect(() => {
    if (markers && markers.size && !selected) {
      const first = markers
        .valueSeq()
        .filter(m => m.type === MarketingMarkerType.Incident)
        .sortBy(m => m.lat)
        .first() as MarkerProps
      if (first) {
        setSelected(first.id)
      }
    }
  }, [markers, selected])

  usePolygons(map, polygon, {
    strokeColor: "white",
    fillColor: "white",
    fillOpacity: 0.1,
    strokeWeight: window.outerWidth < 600 ? 0.5 : undefined,
  })

  return (
    <div className={styles.container}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        options={{
          disableDefaultUI: true,
          streetViewControl: false,
          styles: mapStyle,
          clickableIcons: false,
          draggableCursor: "default",
          backgroundColor: "#212121",
        }}
        bootstrapURLKeys={
          {
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: "geometry,drawing,places",
          } as any
        }
        onGoogleApiLoaded={(m): void => {
          setMap(m)
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        draggable={false}
      >
        {markers.valueSeq().map(m => {
          return (
            <Marker
              key={m.id}
              onClick={(
                id: string,
                stream?: StreamLite,
                offset?: number,
              ): void => {
                setSelected(id)
                if (stream !== undefined) {
                  setSelectedStream(stream)
                  setDetailPosition(Math.max((offset || 0) - 20, 0))
                }
              }}
              {...m}
              {...(m.id === selected ? { focused: true } : { focused: false })}
            />
          )
        })}
        {generic && (
          <div {...fakedMarker}>
            <img src='/example-safety-network.svg' alt='example-psn' />
          </div>
        )}
      </GoogleMapReact>
      {selected &&
        markers.get(selected) &&
        (markers.get(selected) as MarkerProps).model &&
        selectedStream !== undefined && (
          <MarkerDetail
            className={styles.video}
            stream={selectedStream}
            top={selectedStream ? undefined : detailPosition}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            incident={(markers.get(selected) as MarkerProps).model!}
            forceWidth={window.outerWidth - 100}
            onStreamClose={(): void => setSelectedStream(undefined)}
          />
        )}
    </div>
  )
}

export default React.memo(MarketingMap)
