import React from "react"
import styles from "./Partners.module.css"

const Partners = () => {
  const partners = ["barclays.svg", "nyp.svg", "slack.svg", "vornado.png"]

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <p>
          We partner with leading institutions to keep people safe and informed.
        </p>
      </div>
      <div className={styles.logos}>
        {partners.map(p => {
          return (
            <div className={styles.partner} key={p}>
              <img
                src={`/logo_${p}`}
                alt={p}
                style={{
                  height: p.match("barclays") ? 50 : undefined,
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(Partners)
