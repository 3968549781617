import React from "react"
import history from "utils/history"
import { ErrorType } from "api"

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(e: Error) {
    if (e.message.match(ErrorType.NotAuthenticated)) {
      history.push("/login")
    }
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
