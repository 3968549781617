import React, { useEffect, useState } from "react"
import styles from "./PSNPicker.module.css"
import { User } from "types/user"
import Dropdown from "components/Form/Dropdown"
import { getPSN } from "api/psn"
import { PSNResponse } from "types/psn"
import { zip } from "rxjs"
import Body, { BodyType } from "components/Texts/Body"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import { logout } from "api/user"

interface Props {
  onSelect: (psn: PSNResponse) => void
  onLogout: () => void
  user: User
}

const PSNPicker: React.FC<Props> = ({ onSelect, user, onLogout }) => {
  const [psns, setPSNs] = useState<PSNResponse[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    zip(...Object.keys(user.psns).map(id => getPSN(id))).subscribe(resp => {
      setPSNs(resp.map(({ data }) => data))
    })
  }, [user])

  useEffect(() => {
    if (psns.length && psns.length < 2) {
      onSelect(psns[0])
    }
  }, [onSelect, psns])

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src='/citizen-logo.svg' alt='logo' />
      </div>
      <div className={styles.main}>
        <Body type={BodyType.Regular} className={styles.text}>
          Select a Safety Network
        </Body>
        <div className={styles.input}>
          <Dropdown
            options={psns.map(p => p.name)}
            selectedIndex={selectedIndex}
            onChange={(i): void => setSelectedIndex(i)}
          />
        </div>
        <Button
          className={styles.button}
          type={ButtonType.Primary}
          size={ButtonSize.Large}
          onClick={(): void => onSelect(psns[selectedIndex])}
        >
          Login
        </Button>
        <button
          className={styles.logout}
          onClick={() => {
            logout()
            onLogout()
          }}
        >
          <Body type={BodyType.Regular}>Login with another account</Body>
        </button>
      </div>
    </div>
  )
}

export default PSNPicker
