import React, { useReducer, useEffect } from "react"
import styles from "./Login.module.css"
import GetUser from "./GetUser"
import loginReducer, { ActionType } from "./loginReducer"
import EnterCode from "./EnterCode"
import { getUser, requestCode, updateUser } from "api/user"
import history from "utils/history"
import PasswordScreen from "./PasswordScreen"
import { User, PhoneInfo } from "types/user"

interface Props {
  onLogin: (user: any) => void
  user?: User
}

const Login: React.FC<Props> = ({ onLogin, user }) => {
  const [state, dispatch] = useReducer(loginReducer, { loading: false })
  const [error, setError] = React.useState("")

  useEffect(() => {
    if (user) {
      history.replace("/")
    }
  }, [user])

  let body
  if (!state.phone) {
    body = (
      <GetUser
        onSubmit={(phone: string): void => {
          setError("")
          requestCode(phone).subscribe(
            resp => {
              if (resp && resp.status === 200) {
                dispatch({ type: ActionType.SetPhone, data: phone })
              }
            },
            error => {
              setError("Please provide a valid phone number.")
            },
          )
        }}
      />
    )
  } else if (state.usePassword) {
    body = (
      <PasswordScreen
        phone={state.phone}
        onSubmit={(userId: string): void => {
          dispatch({ type: ActionType.SetUserId, data: userId })
          getUser().subscribe(resp => {
            if (resp) {
              onLogin(resp.data)
            }
          })
        }}
      />
    )
  } else if (!state.userId && !state.usePassword) {
    body = (
      <EnterCode
        onSubmit={(userId: string): void => {
          dispatch({ type: ActionType.SetUserId, data: userId })
          getUser().subscribe(resp => {
            if (resp) {
              onLogin(resp.data)
            }
          })
        }}
        onUsePassword={() => {
          dispatch({ type: ActionType.SetUsePassword, data: true })
        }}
        phone={state.phone || ""}
      />
    )
  } else {
    body = (
      <PasswordScreen
        user={user}
        onSubmit={(userId: string, password?: string): void => {
          updateUser(userId, { password })
        }}
        phone={state.phone}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src='/citizen-logo.svg' alt='logo' />
      </div>
      {body}
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  )
}

export default Login
