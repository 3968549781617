import React from "react"
import styles from "./Footer.module.css"

const Footer = () => {
  return (
    <div className={styles.container}>
      <img src='/citizen-logo.svg' alt='logo' />
    </div>
  )
}

export default React.memo(Footer)
