import React, { useContext, useState, useEffect } from "react"
import classnames from "classnames"
import PSNContext from "components/Contexts/PSNContext"
import styles from "./AppDropdown.module.css"
import history, { shouldReplace } from "utils/history"

export enum AppType {
  PSN = "Activity",
  SafeTrace = "SafeTrace",
}

const AppDropdown: React.FC = () => {
  const [open, setOpen] = useState(false)
  const psn = useContext(PSNContext)
  const current = window.location.pathname.match("safetrace")
    ? AppType.SafeTrace
    : (psn && psn.name) || AppType.PSN
  const options = [
    {
      display: (psn && psn.name) || AppType.PSN,
      path: `/${psn ? psn.id : ""}`,
    },
    {
      display: AppType.SafeTrace,
      path: `/${psn ? psn.id : ""}/safetrace/organization`,
    },
  ]

  useEffect(() => {
    setOpen(false)
  }, [current])

  return (
    <div
      className={classnames(styles.dropdown, {
        [styles.open]: open,
      })}
    >
      <button
        className={classnames(styles.selected)}
        onClick={(): void => {
          setOpen(!open)
        }}
      >
        <h2 className={styles.selectedItem}>{current}</h2>
        <div>
          <img src='/icons/down-arrow.svg' alt='open' />
        </div>
      </button>
      {open && (
        <div className={styles.options}>
          {options.map((option, i) => (
            <button
              className={styles.option}
              key={option.display}
              onClick={(): void => {
                const path = options[i].path
                if (!shouldReplace(path)) {
                  history.push(path)
                }
              }}
            >
              <h2>{option.display}</h2>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default AppDropdown
