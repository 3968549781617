import React, { FunctionComponent } from "react"
import styles from "./SetPolygonZoom.module.css"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import emitter, { EventType } from "emitter"
import MapContext, {
  State as MapContextState,
} from "components/Contexts/MapContext"
import { useRouteMatch } from "react-router-dom"
import { updatePSNPolygon, updatePSNView } from "api/psn"

interface Props {
  onSetZoom: () => void
  onCancel: () => void
}

const SetPolygonZoom: FunctionComponent<Props & MapContextState> = ({
  zoom,
  center,
  onSetZoom,
  onCancel,
}) => {
  const locationMatch = useRouteMatch("/:psn/:location")
  const psnMatch = useRouteMatch("/:psn")

  if (!psnMatch || !zoom || !center) {
    return null
  }

  const location = locationMatch && (locationMatch.params as any).location
  const psn = (psnMatch.params as any).psn

  return (
    <div className={styles.zoomButtons}>
      <Button
        type={ButtonType.SubtleReversed}
        size={ButtonSize.Medium}
        className={styles.cancel}
        onClick={(): void => {
          onCancel()
          emitter.emit(EventType.SetOverlay, undefined)
        }}
      >
        Cancel
      </Button>
      <Button
        type={ButtonType.Primary}
        size={ButtonSize.Medium}
        onClick={(): void => {
          emitter.emit(EventType.SetOverlay, undefined)
          let updater
          if (location) {
            updater = updatePSNPolygon(psn, location, {
              viewLat: center.lat,
              viewLng: center.lng,
              viewZoom: zoom,
            })
          } else {
            updater = updatePSNView(psn, {
              viewLat: center.lat,
              viewLng: center.lng,
              viewZoom: zoom,
            })
          }

          updater.subscribe(() => {
            onSetZoom()
          })
        }}
      >
        Set Zoom
      </Button>
    </div>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <MapContext.Consumer>
      {(map): any => {
        return <SetPolygonZoom {...props} {...map} />
      }}
    </MapContext.Consumer>
  )
}

export default React.memo(Wrapper)
