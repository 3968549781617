import React, { useRef, useState } from "react"
import styles from "./ValuePropInfo.module.css"
import { PublicIncident } from "api/marketing"
import FeedUnit from "./FeedUnit"
import PushNotif from "../PushNotif"
import useScroll from "components/Hooks/useScroll"

interface Props {
  incident?: PublicIncident
  psnName: string
  generic: boolean
}

const ValuePropInfo: React.FC<Props> = ({ incident, psnName, generic }) => {
  const pushContainer = useRef<HTMLDivElement>(null)
  const [showPush, setShowPush] = useState(false)

  useScroll(window, () => {
    if (pushContainer.current) {
      const { top } = pushContainer.current.getBoundingClientRect()
      const show = window.outerHeight / 2 > top
      if (show !== showPush) {
        setShowPush(show)
      }
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.visual}>
          <div
            className={styles.image}
            style={{
              background: `url('/img_realtime_info.png')`,
              backgroundSize: "cover",
            }}
          ></div>
          {incident && <FeedUnit incident={incident} psnName={psnName} />}
        </div>
        <div className={styles.info}>
          <h2>
            Real time intel <span>for your Operations Center</span>
          </h2>
          <p>
            Size up a developing incident within 1-2 minutes of a 911 call.
            Access moderated, on-location user video for unmatched situational
            awareness.
          </p>
        </div>
      </div>
      <div className={styles.panel} ref={pushContainer}>
        <div className={styles.info}>
          <h2>
            Take situational awareness <span>on the go</span>
          </h2>
          <p>
            Wherever you are, we'll keep you up to date with any developing
            incidents so that you can take action.
          </p>
        </div>
        <div className={styles.visual}>
          <div
            className={styles.image}
            style={{
              background: `url('${
                generic ? "/generic" : ""
              }/img_situational_awareness.png')`,
              backgroundSize: "cover",
            }}
          ></div>
          {incident && showPush && (
            <PushNotif
              title={incident.title}
              location={incident.location}
              className={styles.push}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ValuePropInfo)
