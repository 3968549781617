import { List } from "immutable"
import { Coords } from "google-map-react"

export interface PolygonType {
  setMap: (m: any) => {}
}

export interface PolygonOptions {
  strokeColor: string
  fillColor: string
  fillOpacity?: number
  strokeWeight?: number
}

const Polygon = (
  map: any,
  polygon: List<Coords>,
  options?: PolygonOptions,
): PolygonType => {
  const poly = new map.maps.Polygon({
    paths: polygon.toArray(),
    strokeColor: (options && options.strokeColor) || "#4DA6FF",
    strokeOpacity: 1,
    strokeWeight: (options && options.strokeWeight) || 1,
    fillColor: (options && options.fillColor) || "#0072FF",
    fillOpacity: (options && options.fillOpacity) || 0.2,
  })
  poly.setMap(map.map)

  return poly
}

export default Polygon
