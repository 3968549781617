import React, { useEffect, useState, useReducer } from "react"
import GoogleMapReact, { Coords } from "google-map-react"
import { List, Map as ImmutableMap } from "immutable"
import mapStyle from "components/Map/style.json"
import Marker, { Props as MarkerProps } from "components/Map/Markers/Marker"
import { PolygonType } from "components/Map/Polygon"
import mapReducer, { ActionType } from "./mapReducer"
import emitter, { EventType } from "emitter"
import useEmitter from "components/Hooks/useEmitter"
import { LatLngBounds } from "types/map"
import { getPlaceFromLatLng } from "utils/address"
import { polygon, inside, point } from "turf"
import styles from "./BaseMap.module.css"
import MapControls from "components/Map/MapControls"
import MapOverlay, { OverlayOption } from "./MapOverlay"
import { roundLatLng } from "utils/map"
import usePolygons from "components/Hooks/usePolygons"

interface Props {
  defaultCenter?: Coords
  defaultZoom?: number
  apiKey: string
  draggable?: boolean
  allowControls?: boolean
  streetView?: boolean
  defaultMarkers?: ImmutableMap<string, MarkerProps>
  markers?: ImmutableMap<string, MarkerProps>
  defaultPolygons?: ImmutableMap<string, List<Coords>>
  polygons?: ImmutableMap<string, List<Coords>>
  overlay?: { overlay: OverlayOption; id: string }

  onBoundsChange?: (b: LatLngBounds, c: Coords, z: number) => void
  onInit?: () => void
}

const DEFAULT_VIEWPORT = {
  center: { lat: 39.8283, lng: -94.5795 },
  zoom: 5,
  markers: ImmutableMap<string, MarkerProps>(),
  polygons: ImmutableMap<string, List<Coords>>(),
}

const Map: React.FC<Props> = ({
  defaultCenter = DEFAULT_VIEWPORT.center,
  defaultZoom = DEFAULT_VIEWPORT.zoom,
  apiKey,
  draggable = false,
  allowControls,
  streetView,
  defaultMarkers = DEFAULT_VIEWPORT.markers,
  defaultPolygons = DEFAULT_VIEWPORT.polygons,
  markers,
  polygons,
  overlay,

  onBoundsChange,
  onInit,
}) => {
  const [map, setMap] = useState()
  const [dragging, setDragging] = useState(false)
  const [state, dispatch] = useReducer(mapReducer, {
    ...DEFAULT_VIEWPORT,
    defaultCenter,
    defaultZoom,
    markers: defaultMarkers,
    polygons: defaultPolygons,
    draggable,
  })
  const roundedDefaultCenter = roundLatLng(state.defaultCenter, 3)
  const roundedCenter = roundLatLng(state.center, 3)
  const showCenterButton = state.defaultBounds
    ? true
    : roundedCenter.lat !== roundedDefaultCenter.lat ||
      roundedCenter.lng !== roundedDefaultCenter.lng ||
      state.zoom !== state.defaultZoom

  const eventMapping: { [event: string]: Function } = {}
  if (map) {
    eventMapping[EventType.SetCenter] = (
      data: Coords,
      isDefault?: boolean,
    ): void => {
      if (isDefault) {
        dispatch({ type: ActionType.SetDefaultCenter, data })
      }
      map.map.panTo(data)
    }
    eventMapping[EventType.SetZoom] = (
      data: number,
      isDefault?: boolean,
    ): void => {
      if (isDefault) {
        dispatch({ type: ActionType.SetDefaultZoom, data })
      }
      map.map.setZoom(data)
    }
    eventMapping[EventType.SetBounds] = (
      data: number[],
      isDefault: boolean,
    ): void => {
      const [minX, minY, maxX, maxY] = data

      if (isDefault) {
        dispatch({
          type: ActionType.SetDefaultBounds,
          data,
        })
      }

      map.map.fitBounds({ east: maxY, north: minX, south: maxX, west: minY })
    }
    eventMapping[EventType.SetFocusedMarker] = (
      data: string | undefined,
    ): void => {
      dispatch({ type: ActionType.SetFocusedMarker, data })
    }
    eventMapping[EventType.SetDraggable] = (data: boolean): void => {
      dispatch({ type: ActionType.SetDraggable, data })
    }
    eventMapping[EventType.SelectPolygon] = (data: string): void =>
      dispatch({ type: ActionType.SetSelectedPolygon, data })
    eventMapping[EventType.SaveViewport] = (): void =>
      dispatch({ type: ActionType.SaveViewport })
  }

  if (state.defaultZoom && state.defaultCenter) {
    eventMapping[EventType.SetOverlay] = (data: any): void => {
      if (data === OverlayOption.SetPolygonZoom) {
        emitter.emit(EventType.SetCenter, state.defaultCenter)
        emitter.emit(EventType.SetZoom, state.defaultZoom)
      }
    }
  }

  if (!polygons) {
    eventMapping[EventType.ShowPolygon] = (data: PolygonType): void =>
      dispatch({ type: ActionType.ShowPolygon, data })
  }

  if (!markers) {
    eventMapping[EventType.SetMarkers] = (
      data: ImmutableMap<string, MarkerProps>,
    ): void => {
      dispatch({ type: ActionType.SetMarkers, data })
    }
    eventMapping[EventType.AddMarker] = (data: MarkerProps): void => {
      dispatch({ type: ActionType.AddMarker, data })
    }
  }

  useEmitter(eventMapping)

  useEffect(() => {
    dispatch({ type: ActionType.SetPolygons, data: polygons })
  }, [polygons])

  useEffect(() => {
    if (markers) {
      dispatch({ type: ActionType.SetMarkers, data: markers })
    }
  }, [markers])

  useEffect(() => {
    if (map && onInit) {
      onInit()
    }
  }, [map, onInit])

  usePolygons(map, state.polygons)

  return (
    <div className={styles.container}>
      <GoogleMapReact
        options={{
          disableDefaultUI: !allowControls,
          streetViewControl: streetView,
          styles: mapStyle,
        }}
        bootstrapURLKeys={
          {
            key: apiKey,
            libraries: "geometry,drawing,places",
          } as any
        }
        onGoogleApiLoaded={(m): void => {
          m.maps.event.addListener(m.map, "bounds_changed", () => {
            if (onBoundsChange) {
              onBoundsChange(
                m.map.getBounds(),
                { lat: m.map.getCenter().lat(), lng: m.map.getCenter().lng() },
                m.map.getZoom(),
              )
            }
            dispatch({
              type: ActionType.SetCenter,
              data: {
                lat: m.map.getCenter().lat(),
                lng: m.map.getCenter().lng(),
              },
            })
            dispatch({
              type: ActionType.SetZoom,
              data: m.map.getZoom(),
            })
            const { lat: minX, lng: maxY } = m.map.getBounds().getNorthEast()
            const { lat: maxX, lng: minY } = m.map.getBounds().getSouthWest()

            dispatch({
              type: ActionType.SetBounds,
              data: [minX(), minY(), maxX(), maxY()],
            })
          })

          setMap(m)
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        draggable={state.draggable && !dragging}
        yesIWantToUseGoogleMapApiInternals
        onChildMouseDown={(index, marker) => {
          if (marker.id === "pin") {
            setDragging(true)
          }
        }}
        onChildMouseMove={(index, marker, position) => {
          const { lat, lng } = position
          let isInside = false
          if (polygons && state.selectedPolygon) {
            const p = polygons.get(state.selectedPolygon) as List<Coords>
            if (polygon) {
              isInside = inside(
                point([lat, lng]),
                polygon([
                  p
                    .toArray()
                    .map(({ lat, lng }: Coords): number[] => [lat, lng]),
                ]),
              )
            }
          }

          if (dragging && isInside) {
            dispatch({
              type: ActionType.UpdateMarker,
              data: {
                ...state.markers.get("pin"),
                lat,
                lng,
              },
            })
          }
        }}
        onChildMouseUp={async (): Promise<void> => {
          const marker = state.markers.get("pin")
          if (marker) {
            const { lat, lng } = marker
            const resp = await getPlaceFromLatLng({ lat, lng })
            if (resp) {
              emitter.emit(EventType.SetLocationSuggestion, resp)
            }
          }
          setDragging(false)
        }}
      >
        {state.markers.valueSeq().map(m => {
          return (
            <Marker
              key={m.id}
              {...m}
              {...(dragging ? { focused: false } : {})}
            />
          )
        })}
      </GoogleMapReact>
      <MapOverlay
        overlay={overlay}
        onResetToSavedState={(): void => {
          if (state.savedViewport) {
            emitter.emit(EventType.SetCenter, state.savedViewport.center)
            emitter.emit(EventType.SetZoom, state.savedViewport.zoom)
          }
        }}
      />
      {state.draggable && (
        <MapControls
          onZoomIn={(): void => {
            emitter.emit(EventType.SetZoom, map.map.getZoom() + 1)
          }}
          onZoomOut={(): void => {
            emitter.emit(EventType.SetZoom, map.map.getZoom() - 1)
          }}
          onCenter={
            showCenterButton
              ? (): void => {
                  if (state.defaultBounds) {
                    emitter.emit(EventType.SetBounds, state.defaultBounds)
                  } else if (state.defaultCenter && state.defaultZoom) {
                    emitter.emit(EventType.SetCenter, state.defaultCenter)
                    emitter.emit(EventType.SetZoom, state.defaultZoom)
                  }
                }
              : undefined
          }
        />
      )}
    </div>
  )
}

export default Map
