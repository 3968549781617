import { useEffect, useState, useRef } from "react"
import { soundManager } from "soundmanager2"
import { SoundProperties, SMSound } from "types/soundmanager2"
import { noop } from "lodash"
import useEmitter from "./useEmitter"
import { EventType } from "emitter"
import uuid from "uuid"

interface SoundCreation {
  remove: Function
}

export enum PlayStatus {
  Playing = "playing",
  Paused = "paused",
  Stopped = "stopped",
}

const pendingCalls: Function[] = []
const createSound = (options: SoundProperties, cb: Function): SoundCreation => {
  if (soundManager.ok()) {
    cb(soundManager.createSound(options))
    return { remove: noop }
  } else {
    const call = (): void => {
      cb(soundManager.createSound(options))
    }

    pendingCalls.push(call)

    return {
      remove: (): void => {
        pendingCalls.splice(pendingCalls.indexOf(call), 1)
      },
    }
  }
}

interface Props {
  onPlaying: (sound: SMSound) => void
}

const useSound = (options: SoundProperties & Props): string | undefined => {
  const [sound, setSound] = useState<SMSound>()
  const [id, setId] = useState<string>()
  const soundRef = useRef<SMSound>()
  const eventMapping: { [event: string]: Function } = {}

  if (sound && id) {
    eventMapping[EventType.SetSoundStatus] = (
      soundId: string,
      status: PlayStatus,
    ): void => {
      if (soundId !== id && status === PlayStatus.Playing) {
        sound.pause()
      } else if (soundId === id) {
        switch (status) {
          case PlayStatus.Playing:
            if (sound.playState === 0) {
              sound.play()
            }

            if (sound.paused) {
              sound.resume()
            }
            break
          case PlayStatus.Stopped:
            if (sound.playState !== 0) {
              sound.stop()
            }
            break
          case PlayStatus.Paused:
            if (!sound.paused) {
              sound.pause()
            }
            break
          default:
            break
        }
      }
    }
    eventMapping[EventType.SetSoundPosition] = (
      soundId: string,
      position: number,
    ): void => {
      if (soundId === id) {
        sound.setPosition(position)
      }
    }
  }
  useEmitter(eventMapping)

  useEffect(() => {
    setId(uuid())
  }, [])

  useEffect(() => {
    if (!options.url) {
      return
    }

    const { onPlaying } = options
    const creation = createSound(
      {
        ...options,
        whileplaying: () => {
          if (soundRef.current) {
            onPlaying(soundRef.current)
          }
        },
      },
      (s: SMSound) => {
        setSound(s)
        soundRef.current = s
      },
    )

    return (): void => {
      if (creation) {
        creation.remove()
      }

      if (sound) {
        try {
          sound.destruct()
          setSound(undefined)
        } catch (e) {}
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.url])

  return id
}

export default useSound
