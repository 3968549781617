import React, { useReducer, useEffect, useState, useCallback } from "react"
import { List, Map as ImmutableMap, Set } from "immutable"
import styles from "./IncidentFeed.module.css"
import IncidentList from "./IncidentList/IncidentList"
import history, { shouldReplace, constructTo } from "utils/history"
import { Link } from "react-router-dom"
import incidentFeedReducer, {
  ActionType,
  RADIUS_THRESHOLD,
} from "./incidentFeedReducer"
import { Incident, IncidentFilter } from "types/incident"
import { debounce, flatten } from "lodash"
import { timer } from "rxjs"
import { getIncidentIds, getIncidentsById } from "api/incident"
import PSNContext, { PSN } from "components/Contexts/PSNContext"
import emitter, { EventType } from "emitter"
import MapContext, { State as MapState } from "components/Contexts/MapContext"
import { getPSNUserLocations } from "api/psn"
import { UserLocation } from "types/psn"
import {
  getBoundingBoxOfPolygon,
  getCenterOfPolygon,
  convertPolygonToCoords,
  getBoundBoxOfPolygons,
} from "utils/map"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import SearchInput from "components/Form/SearchInput"
import MainMenu, { Section } from "components/MainMenu/MainMenu"
import { MarkerType } from "components/Map/Markers/Marker"
import PolygonMenuPopup, {
  MenuOption as PolygonMenuOption,
} from "./PolygonMenuPopup"
import { OverlayOption } from "components/MapBase/MapOverlay"
import UserContext from "components/Contexts/UserContext"
import { User, FollowingIncident } from "types/user"
import { Coords } from "google-map-react"
import { getServiceAreaCode } from "api/address"
import FilterForm from "components/Filter/FilterForm"
import { getUserFollowingIncidents } from "api/user"

const DEFAULT_FILTER = {
  traceBackHours: "4",
  incidentType: "all",
  incident_lv0: true,
  incident_lv1: true,
  incident_lv2: true,
  location: "all",
  following: false,
  incident_category: "all",
}

const DEFAULT_STATE = {
  incidents: ImmutableMap<string, Incident>(),
  filteredIncidents: { closeby: List<Incident>(), other: List<Incident>() },
  userLocations: List<UserLocation>(),
  polygonCoords: ImmutableMap<string, List<Coords>>(),
  sectionFilter: Set<string>(),
  additionalFilter: DEFAULT_FILTER,
  followingIncidents: List<string>(),
}

interface Props {
  mapInit: boolean
  location?: string
}

const REFRESH_RATE_MS = 5000

const IncidentFeed: React.FC<Props & { psn: PSN } & { map: MapState } & {
  user: User
}> = ({ mapInit, psn, map, location, user }) => {
  const [state, dispatch] = useReducer(incidentFeedReducer, {
    ...DEFAULT_STATE,
    psnId: psn.id,
    additionalFilters: DEFAULT_FILTER,
  })
  const [menuItems, setMenuItems] = useState<Section[]>([])
  const [addressFilter, setAddressFiter] = useState<string>("")
  const [popupTop, setPopupTop] = useState<number | undefined>()
  const [activeOverflowMenu, setActiveOverflowMenu] = useState<
    string | undefined
  >()
  const [serviceAreas, setServiceAreas] = useState<string[]>([])

  useEffect(() => {
    getUserFollowingIncidents("").subscribe(({ data }) => {
      if (data && data.length > 0) {
        const followingIncidentsIds = data.map(
          (incident: FollowingIncident) => incident.incidentID,
        )

        dispatch({
          type: ActionType.SetFollowingIncidents,
          data: { followingIncidents: followingIncidentsIds },
        })
      }
    }, console.error)
  }, [])

  useEffect(() => {
    const getServiceArea = async () => {
      try {
        let lat = 40.682605
        let lng = -73.975354

        const polyCoords = location && state.polygonCoords.get(location)
        if (polyCoords) {
          const center = getCenterOfPolygon(polyCoords)
          lat = center.geometry.coordinates[0] || 40.682605
          lng = center.geometry.coordinates[1] || -73.975354
        }

        const serviceAreaCode = await getServiceAreaCode(lat, lng)
        const { code } = serviceAreaCode
        // If code is empty, we fetch all incidents cause no corresponding service area
        setServiceAreas([code])
      } catch (e) {
        console.log("something wrong fetching service area")
      }
    }

    getServiceArea()
  }, [location, state.polygonCoords])

  const onPopupHide = useCallback(() => {
    setPopupTop(undefined)
    setActiveOverflowMenu(undefined)
  }, [])
  useEffect(() => {
    const incidentSubscription = timer(0, REFRESH_RATE_MS).subscribe(() =>
      getIncidentIds(undefined, psn.id, serviceAreas).subscribe(
        ({ data: { incidentIds } }) => {
          if (incidentIds.length !== 0) {
            getIncidentsById(incidentIds, psn.id).subscribe(resp => {
              const received_incidents = flatten(
                resp.map(({ data }) => data.incidents),
              )
              dispatch({
                type: ActionType.SetIncidents,
                data: { incidents: received_incidents, polygons: psn.polygons },
              })
            })
          } else {
            dispatch({
              type: ActionType.SetIncidents,
              data: { incidents: [], polygons: psn.polygons },
            })
          }
        },
      ),
    )
    const userLocationSubscription = timer(0, 60000).subscribe(() =>
      getPSNUserLocations(psn.id).subscribe(({ data }) => {
        dispatch({ type: ActionType.SetUserLocations, data })
      }),
    )

    return (): void => {
      incidentSubscription.unsubscribe()
      userLocationSubscription.unsubscribe()
    }
  }, [mapInit, psn.id, psn.polygons, location, serviceAreas])

  useEffect(() => {
    let mapping = ImmutableMap<string, List<Coords>>()

    Object.keys(psn.polygons).forEach(polyId => {
      const polygon = psn.polygons[polyId].polygon

      mapping = mapping.set(polyId, convertPolygonToCoords(polygon))
    })

    dispatch({ type: ActionType.SetPolygonCoords, data: mapping })
  }, [psn.polygons])

  useEffect(() => {
    const items = Object.keys(psn.polygons)
      .map(id => {
        const poly = psn.polygons[id]
        return {
          id,
          label: poly.name,
          path: `/${psn.id}/${id}`,
          count: state.incidents.filter(
            i =>
              i.polyId &&
              i.polyId === id &&
              i.distanceFrom &&
              i.distanceFrom <= RADIUS_THRESHOLD,
          ).size,
        }
      })
      .filter(location =>
        location.label
          .toLocaleLowerCase()
          .includes(addressFilter.toLocaleLowerCase()),
      )
    if (!location && items.length === 1) {
      history.replace(`/${psn.id}/${items[0].id}`)
    }

    const all = [
      { id: "all", label: "All Locations", path: `/${psn.id}`, count: 0 },
    ]
    const sections: Section[] = items.length > 1 ? [{ items: all }] : []
    sections.push({
      items,
      heading: items.length > 1 ? "Locations" : undefined,
    })

    setMenuItems(sections)
  }, [psn.id, psn.polygons, location, state.incidents, addressFilter])

  useEffect(() => {
    const poly = psn.polygons && location && psn.polygons[location]
    const polyCoords = poly && location && state.polygonCoords.get(location)

    const getServiceArea = async (lat: number, lng: number) => {
      try {
        const serviceAreaCode = await getServiceAreaCode(lat, lng)
        const { code } = serviceAreaCode
        setServiceAreas([code])
      } catch (e) {
        console.log("something wrong fetching service area")
      }
    }

    const updateMapViewport = (
      getBounds: () => number[],
      lat?: number,
      lng?: number,
      zoom?: number,
    ): void => {
      if (lat && lng && zoom) {
        emitter.emit(EventType.SetCenter, { lat, lng }, true)
        emitter.emit(EventType.SetZoom, zoom, true)
        dispatch({ type: ActionType.SetDefaultZoom, data: zoom })
      } else {
        const bounds = getBounds()
        emitter.emit(EventType.SetBounds, bounds, true)
      }
    }

    if (poly && polyCoords) {
      updateMapViewport(
        () => getBoundingBoxOfPolygon(polyCoords),
        poly.viewLat,
        poly.viewLng,
        poly.viewZoom,
      )

      const center = getCenterOfPolygon(polyCoords)
      emitter.emit(EventType.SelectPolygon, location)

      const userCountMarker = {
        id: "userCount1",
        lat: center.geometry.coordinates[0],
        lng: center.geometry.coordinates[1],
        type: MarkerType.UserCount,
        model: poly,
      }

      dispatch({ type: ActionType.SetUserCountMarker, data: userCountMarker })
    } else {
      updateMapViewport(
        () => getBoundBoxOfPolygons(state.polygonCoords.valueSeq().toArray()),
        psn.viewLat,
        psn.viewLng,
        psn.viewZoom,
      )
      const default_coords_array = state.polygonCoords.valueSeq().toArray()
      if (default_coords_array.length > 0) {
        const default_coord = getCenterOfPolygon(default_coords_array[0])
        getServiceArea(
          default_coord.geometry.coordinates[0],
          default_coord.geometry.coordinates[1],
        )
      }
      dispatch({ type: ActionType.SetUserCountMarker, data: undefined })
    }

    emitter.emit(EventType.SetDraggable, true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInit, location, psn.polygons, state.polygonCoords])

  useEffect(() => {
    dispatch({ type: ActionType.UpdateBounds, data: map.bounds })
  }, [map.bounds])

  useEffect(() => {
    dispatch({ type: ActionType.SetZoom, data: map.zoom })
  }, [map.zoom])

  useEffect(() => {
    dispatch({
      type: ActionType.SetLocationFilter,
      data: location ? { ...psn.polygons[location], id: location } : undefined,
    })
    dispatch({
      type: ActionType.ResetFilters,
      data: undefined,
    })
  }, [location, psn.polygons])

  if (!psn) {
    return null
  }

  const isSuperOperator =
    !!user.psns[psn.id] && user.psns[psn.id].role === "super_operator"

  const onUpdateFollowingIncidents = (followingIncidents: string[]): void => {
    dispatch({
      type: ActionType.SetFollowingIncidents,
      data: { followingIncidents },
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <div className={styles.search}>
          <SearchInput
            onSearch={(value: string): void => {
              setAddressFiter(value)
            }}
          />
        </div>
        <div className={styles.locations}>
          <MainMenu
            sections={menuItems}
            selectedItem={location || "all"}
            title={(psn && psn.name) || "Activity"}
            onOverflowMenuClick={
              isSuperOperator
                ? (e): void => {
                    e.preventDefault()
                    e.stopPropagation()

                    const { currentTarget } = e
                    const { top } = currentTarget.getBoundingClientRect()

                    setPopupTop(top)
                    setActiveOverflowMenu(
                      (currentTarget as HTMLElement).dataset.option,
                    )
                  }
                : undefined
            }
            activeOverflowMenu={activeOverflowMenu}
            safeTraceEnabled={psn.safeTraceEnabled}
          />
        </div>
        {location && (
          <div className={styles.bottom}>
            <Link
              to={constructTo(`/${psn.id}/${location}/incidents/create`)}
              replace={shouldReplace(`/${psn.id}/${location}/incidents/create`)}
            >
              <Button
                type={ButtonType.Primary}
                size={ButtonSize.Large}
                block
                icon='/icons/plus.svg'
              >
                Create New Incident
              </Button>
            </Link>
          </div>
        )}
        <PolygonMenuPopup
          top={popupTop}
          onClick={(option: PolygonMenuOption): void => {
            switch (option) {
              case PolygonMenuOption.SetZoom:
                emitter.emit(EventType.SaveViewport)
                emitter.emit(EventType.SetOverlay, {
                  overlay: OverlayOption.SetPolygonZoom,
                  id: location,
                })
                break
              default:
                break
            }
          }}
          onHide={onPopupHide}
        />
      </div>
      <div className={styles.feed}>
        <div className={styles.search}>
          <div className={styles.flex_row}>
            <SearchInput
              onSearch={debounce(
                (value: string): void =>
                  dispatch({ type: ActionType.SearchIncidents, data: value }),
                200,
              )}
            />
            <FilterForm
              onFilter={debounce(
                (filter: IncidentFilter): void =>
                  dispatch({
                    type: ActionType.SetIncidentFilter,
                    data: filter,
                  }),
                200,
              )}
            />
          </div>
        </div>
        <div className={styles.incidents}>
          {!!state.filteredIncidents.closeby.size && (
            <IncidentList
              incidents={state.filteredIncidents.closeby}
              heading={`within ${RADIUS_THRESHOLD} mi`}
              locationSuffix={state.locationFilter && " away"}
              followingIncidents={state.followingIncidents}
              onUpdateFollowingIncidents={onUpdateFollowingIncidents}
            />
          )}
          {(!!state.filteredIncidents.other.size ||
            state.sectionFilter.has("other")) && (
            <IncidentList
              incidents={state.filteredIncidents.other}
              heading='further away'
              locationSuffix={state.locationFilter && " away"}
              followingIncidents={state.followingIncidents}
              onUpdateFollowingIncidents={onUpdateFollowingIncidents}
              onHideToggle={(hidden: boolean): void =>
                dispatch({
                  type: ActionType.HideIncidentType,
                  data: { section: "other", hidden },
                })
              }
              hidden={state.sectionFilter.has("other")}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const PSNWrapper = (props: Props & { map: MapState } & { user: User }): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <IncidentFeed {...props} psn={psn} />
        }
      }}
    </PSNContext.Consumer>
  )
}

const MapWrapper = (props: Props & { user: User }): any => {
  return (
    <MapContext.Consumer>
      {(map): any => {
        return <PSNWrapper {...props} map={map} />
      }}
    </MapContext.Consumer>
  )
}

const UserWrapper = (props: Props): any => {
  return (
    <UserContext.Consumer>
      {(user): any => {
        if (!user) {
          return null
        }
        return <MapWrapper {...props} user={user} />
      }}
    </UserContext.Consumer>
  )
}

export default UserWrapper
