import React, { FunctionComponent, Fragment } from "react"
import classnames from "classnames"
import styles from "./Button.module.css"
import Body, { BodyType } from "components/Texts/Body"
import Small, { SmallType } from "components/Texts/Small"

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Subtle = "subtle",
  SubtleReversed = "subtleReversed",
  Disabled = "disabled",
  Cancel = "cancel",
  OverflowMenu = "overflowMenu",
}

export enum ButtonSize {
  Medium = "medium",
  Large = "large",
  Small = "small",
}

interface Props {
  type: ButtonType
  icon?: string
  className?: string
  size: ButtonSize
  block?: boolean
  onClick?: (e: React.MouseEvent) => void
  data?: { [prop: string]: string }
  active?: boolean
  customBody?: boolean
}

const Button: FunctionComponent<Props> = ({
  type,
  className,
  children,
  size,
  icon,
  block,
  onClick,
  data,
  active,
  customBody,
}) => {
  let body

  switch (type) {
    case ButtonType.Cancel:
      body = (
        <Fragment>
          <div className={styles.cancelIcon}>
            <img src='/icons/cancel.svg' alt='cancel' />
          </div>
          <div className={styles.tooltip}>
            <Small type={SmallType.Semibold}>Close</Small>
          </div>
        </Fragment>
      )
      break
    case ButtonType.OverflowMenu:
      body = (
        <div className={styles.menuIcon}>
          <img src='/icons/ellipsis.svg' alt='menu' />
        </div>
      )
      break
    default:
      if (customBody) {
        body = children
      } else {
        body =
          size === ButtonSize.Small ? (
            <Small type={SmallType.Semibold}>{children}</Small>
          ) : (
            <Body type={BodyType.Bold}>{children}</Body>
          )
      }
      break
  }

  return (
    <button
      onClick={onClick}
      className={classnames(
        className,
        styles.button,
        styles[type],
        styles[size.toLowerCase()],
        {
          [styles.block]: block,
          [styles.active]: active,
        },
      )}
      disabled={type === ButtonType.Disabled}
      {...data}
    >
      {icon && (
        <div className={styles.icon}>
          <img src={icon} alt='icon' />
        </div>
      )}
      {body}
    </button>
  )
}

export default Button
