import { Incident } from "types/incident"
import { State as IncidentCreationState } from "components/Form/IncidentForm/incidentFormReducer"

enum NotificationGroup {
  Operators = "operators",
  Members = "members",
}

interface NotificationConfig {
  group: NotificationGroup
  level: number
  restrictToPolygons: boolean
  incidentId?: string
}

export const getNotificationConfig = (
  incident: Incident | IncidentCreationState,
): NotificationConfig => {
  const config: NotificationConfig = {
    group: NotificationGroup.Members,
    level:
      (incident as Incident).clearanceLevel ||
      (incident as IncidentCreationState).audience,
    restrictToPolygons: true,
  }

  if ((incident as Incident).id) {
    config.incidentId = (incident as Incident).id
  }

  return config
}
