import React from "react"
import { Update as UpdateType, Clip } from "types/incident"
import { first } from "lodash"
import styles from "./Update.module.css"
import dayjs from "dayjs"
import ClipPlayer from "./ClipPlayer/ClipPlayer"
import classnames from "classnames"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  update: UpdateType
}
const Update: React.FC<Props> = ({ update }) => {
  const rev = first(update.revisions)

  if (!rev || (!rev.text && !rev.location && !rev.title)) {
    return null
  }

  let text = rev.text
  if (!text && rev.title) {
    text = `[Title Updated] ${rev.title}`
  } else if (!text && rev.location) {
    text = `[Location Updated] ${rev.location.location}`
  }

  const verified = rev.source === "psn"
  return (
    <div
      className={classnames(styles.container, {
        [styles.verified]: verified,
      })}
    >
      <div className={styles.separator}>
        <div className={styles.ball} />
        <div className={styles.line} />
      </div>
      <div className={styles.main}>
        <div className={styles.top}>
          <Body type={BodyType.Bold}>
            {dayjs(rev.occurredAt).format("h:mmA")}
          </Body>
          <div className={styles.description}>
            <Body type={BodyType.Regular}>
              via {rev.source === "psn" ? rev.source : "Citizen"} · @
              {rev.authorUsername}
            </Body>
          </div>
        </div>
        <Body type={BodyType.Regular} className={styles.text}>
          {text}
        </Body>
        {(first(update.revisions) || { clips: [] as Clip[] }).clips.map(c => (
          <ClipPlayer
            key={c.id}
            url={`https://${c.wavURL}`}
            durationMs={c.durationMs}
            channelHeader={c.channelHeader}
          />
        ))}
      </div>
    </div>
  )
}

export default Update
