import { PSN } from "components/Contexts/PSNContext"
import { useEffect } from "react"
import history from "utils/history"

const useOrganizationPage = (psn?: PSN) => {
  useEffect(() => {
    if (psn && !psn.gSuiteLogin) {
      history.push(`/${psn.id}/safetrace/import`)
    }
  }, [psn])

  return {}
}

export default useOrganizationPage
