import React, { FunctionComponent, useState } from "react"
import styles from "./MapOverlay.module.css"
import SetPolygonZoom from "./MapOverlays/SetPolygonZoom"
import Popup from "components/Popup/Popup"
import Body, { BodyType } from "components/Texts/Body"
import classnames from "classnames"

export enum OverlayOption {
  SetPolygonZoom = "set polygon zoom",
}

interface Props {
  overlay?: { overlay: OverlayOption; id: string }
  onResetToSavedState: () => void
}

const MapOverlay: FunctionComponent<Props> = ({
  overlay,
  onResetToSavedState,
}) => {
  let body
  let popupBody
  const [popup, setPopup] = useState<OverlayOption | undefined>()

  switch (overlay && overlay.overlay) {
    case OverlayOption.SetPolygonZoom:
      body = (
        <SetPolygonZoom
          onSetZoom={(): void => setPopup(OverlayOption.SetPolygonZoom)}
          onCancel={onResetToSavedState}
        />
      )
      break
    default:
      break
  }

  switch (popup) {
    case OverlayOption.SetPolygonZoom:
      popupBody = (
        <div className={styles.popup}>
          <img src='/icons/check.svg' alt='done' />
          <Body type={BodyType.Bold}>Default Zoom Updated</Body>
        </div>
      )
      break
    default:
      break
  }

  if (!overlay && !popup) {
    return null
  }

  return (
    <div
      className={classnames(styles.container, { [styles.popupShown]: !!popup })}
    >
      {body}
      <Popup
        duration={2500}
        show={!!popup}
        onHide={(): void => setPopup(undefined)}
        id={overlay && overlay.id}
      >
        {popupBody}
      </Popup>
    </div>
  )
}

export default React.memo(MapOverlay)
