import { useEffect, useState } from "react"
import { PSN } from "components/Contexts/PSNContext"
import { getPSN } from "api/psn"
import { flatten } from "lodash"
import { List, Map as ImmutableMap } from "immutable"
import { Coords } from "google-map-react"

const usePSN = (id: string): PSN | undefined => {
  const [psn, setPSN] = useState<PSN>()

  useEffect(() => {
    getPSN(id).subscribe(({ data }) => {
      const getPolygons = (): ImmutableMap<string, List<Coords>> => {
        let polygons = ImmutableMap<string, List<Coords>>()

        Object.keys(data.polygons).forEach(polyId => {
          const poly = flatten(data.polygons[polyId].polygon)
          polygons = polygons.set(
            polyId,
            List(
              poly.map((c: number[]) => ({
                lat: c[1],
                lng: c[0],
              })),
            ),
          )
        })

        return polygons
      }

      setPSN({
        ...data,
        polygonsMapping: getPolygons(),
      })
    })
  }, [id])

  return psn
}

export default usePSN
