import { OptimusAPI, getAuthToken } from "./index"
import { Observable, from, of } from "rxjs"
import { AxiosResponse } from "axios"
import uuid from "uuid"
import jwt from "jsonwebtoken"
import localStorage, { LocalStorageProp } from "localStorage"
import { User, UserToken, FollowingIncident } from "types/user"
import { identify } from "utils/analytics"

export const requestCode = (
  phoneNumber: string,
): Observable<AxiosResponse<void>> => {
  return from(OptimusAPI.post("/v1/auth/request_code", { phoneNumber }))
}

export const validateCode = (
  phoneNumber: string,
  code: string,
): Observable<AxiosResponse<UserToken>> => {
  let deviceId = localStorage.get(LocalStorageProp.DeviceId)
  if (!deviceId) {
    deviceId = uuid()
    localStorage.set(LocalStorageProp.DeviceId, deviceId)
  }

  return from(
    OptimusAPI.post("/v1/auth/validate_code", {
      phoneNumber,
      code,
      wantSuper: true,
      deviceId,
    }),
  )
}

export const getUser = (): Observable<AxiosResponse<User> | null> => {
  const citizenJWT = getAuthToken()
  const decoded = jwt.decode(citizenJWT)
  if (!decoded) {
    return of(null)
  }
  const uid = (decoded as any).uid

  return from(
    OptimusAPI.get(`/v1/user/${uid}`, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params: { with_psns: true },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const logout = (): void => {
  localStorage.unset(LocalStorageProp.Token)
  identify("")
}

export const login = (token: string): void => {
  localStorage.set(LocalStorageProp.Token, token)
}

export const validatePassword = (
  identifier: string,
  password: string,
): Observable<AxiosResponse<UserToken>> => {
  return from(
    OptimusAPI.post("/v2/auth/authenticate", {
      identifier,
      password,
      wantSuper: false,
    }),
  )
}

export const updateUser = (
  userId: string,
  data: Partial<User>,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(`/v1/user/${userId}`, data, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

// return all following incidents if incidentIds is empty
// otherwise return only the incidents that are in the list
export const getUserFollowingIncidents = (
  incidentIds?: string,
): Observable<AxiosResponse<FollowingIncident[]>> => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.get(`/v1/incidents/user_meta/batch`, {
      params: { incident_ids: incidentIds },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export default {
  requestCode,
  validateCode,
  validatePassword,
  getUser,
  logout,
  login,
  updateUser,
}
