import { OptimusAPI, getAuthToken } from "./index"
import { from } from "rxjs"
import dayjs from "dayjs"

export const LIMIT = 10

export enum RoleType {
  SuperOperator = "super_operator",
  Operator = "operator",
  Member = "member",
}

export interface Employee {
  id: string
  name: string
  email: string
  phoneNumber: string
  level: number
}

export const getEmployees = (
  psn: string,
  limit: number = LIMIT,
  offset: number = 0,
  locations?: string[],
) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${psn}/members`, {
      params: {
        limit,
        offset,
        include_pending: true,
        locations: JSON.stringify(locations),
      },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export interface Member {
  id: string
  tracingEnabled: boolean
}

export const getMembers = (
  psn: string,
  limit: number = LIMIT,
  offset: number = 0,
  locations?: string[],
) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${psn}/members/safepass_status`, {
      params: {
        limit,
        offset,
        include_pending: true,
        locations: JSON.stringify(locations),
      },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const uploadMembersCSV = (psn: string, csvFile: any) => {
  const citizenJWT = getAuthToken()
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  formData.append("data", csvFile)

  return from(
    OptimusAPI.post(`/psn/${psn}/users`, formData, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const updateMemberRole = (psn: string, user: string, role: RoleType) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psn}/user/${user}`,
      { role },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export const updateMemberSafePass = (
  psn: string,
  user: string,
  status: boolean,
) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psn}/user/${user}`,
      { safepassApproved: status },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export enum TestingStatus {
  Positive = "positive",
  Negative = "negative",
}

export const setMemberTesting = (
  psn: string,
  phoneNumber: string,
  result: TestingStatus,
  date: Date,
) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psn}/safetrace/test_result`,
      { phoneNumber, result, testDate: dayjs(date).format("YYYY-MM-DD") },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export enum SymptomStatus {
  Same = 4,
  Worsening = 3,
  None = 2,
  Better = 1,
}

export const setMemberSymptoms = (
  psn: string,
  phoneNumber: string,
  symptomStatus: SymptomStatus,
  symptoms: string[],
) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psn}/safetrace/symptoms`,
      {
        phoneNumber,
        symptomStatus,
        symptoms,
      },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}
