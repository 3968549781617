import { Map, List, Set } from "immutable"
import { Incident, IncidentMeta, IncidentFilter } from "types/incident"
import { Props as Marker } from "components/Map/Markers/Marker"
import emitter, { EventType } from "emitter"
import { latestRevision, getClosestPolygonFromIncident } from "utils/incident"
import { LatLngBounds } from "types/map"
import { getMarkerFromIncident, getMarkerFromUserLocation } from "utils/map"
import { UserLocation, Polygon } from "types/psn"
import { Coords } from "google-map-react"
import { FollowingIncident } from "types/user"

export const RADIUS_THRESHOLD = 0.25
export enum AudienceType {
  Staff = "staff",
  Everyone = "everyone",
}

export enum ActionType {
  SetIncidents = "set incidents",
  SearchIncidents = "search incidents",
  UpdateBounds = "update bounds",
  SetUserLocations = "set user locations",
  SetUserCountMarker = "set user count marker",
  SetZoom = "set zoom",
  SetDefaultZoom = "set default zoom",
  SetPolygonCoords = "set polygon coords",
  SetLocationFilter = "set location filter",
  HideIncidentType = "hide incident type",
  ResetFilters = "reset filters",
  SetIncidentFilter = "set incident filter",
  SetFollowingIncidents = "set following incidents",
}

interface FilteredIncidents {
  closeby: List<Incident & IncidentMeta>
  other: List<Incident & IncidentMeta>
}

interface State {
  incidents: Map<string, Incident & IncidentMeta>
  userLocations: List<UserLocation>
  filteredIncidents: FilteredIncidents
  keywordFilter?: string
  bounds?: LatLngBounds
  userCountMarker?: Marker
  psnId: string
  defaultZoom?: number
  zoom?: number
  polygonCoords: Map<string, List<Coords>>
  locationFilter?: Polygon & { id: string }
  sectionFilter: Set<string>
  additionalFilters: IncidentFilter
  followingIncidents: List<string>
}

interface Action {
  type: ActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

interface SearchParams {
  incidents: Map<string, Incident & IncidentMeta>
  bounds?: LatLngBounds
  keywordFilter?: string
  locationFilter?: Polygon & { id: string }
  sectionFilter: Set<string>
}

interface FilterParams {
  incidents: FilteredIncidents
  incidentFilter: IncidentFilter
  followingIncidents: List<string>
}

const searchIncidents = (params: SearchParams): FilteredIncidents => {
  const {
    incidents,
    bounds,
    keywordFilter,
    locationFilter,
    sectionFilter,
  } = params

  let filtered = List<Incident & IncidentMeta>()
  let filteredCloseby = List<Incident & IncidentMeta>()
  const sortList = (
    list: List<Incident & IncidentMeta>,
  ): List<Incident & IncidentMeta> =>
    list.sort((a, b) => {
      const revisionA = latestRevision(a)
      const revisionB = latestRevision(b)

      if (!revisionB) {
        return -1
      } else if (!revisionA) {
        return 1
      } else {
        return (
          new Date(revisionB.updatedAt).getTime() -
          new Date(revisionA.updatedAt).getTime()
        )
      }
    })

  if (!keywordFilter && !bounds) {
    filtered = List(incidents.values())
  } else {
    incidents.valueSeq().forEach(incident => {
      const keywordCheck =
        (keywordFilter && incident.title.toLowerCase().match(keywordFilter)) ||
        !keywordFilter
      const { lat, lng } = incident.location
      const boundsCheck =
        (bounds &&
          bounds.contains(new window.google.maps.LatLng({ lat, lng }))) ||
        !bounds
      const newIncident = { ...incident }
      if (locationFilter) {
        const closest = getClosestPolygonFromIncident(incident, [
          locationFilter,
        ])
        if (closest) {
          newIncident.distanceFrom = closest.distance
          newIncident.polyId = closest.id
          newIncident.polyName = locationFilter.name
        }
      }

      if (keywordCheck && boundsCheck) {
        if (
          newIncident.distanceFrom !== undefined &&
          newIncident.distanceFrom <= RADIUS_THRESHOLD
        ) {
          filteredCloseby = filteredCloseby.push(newIncident)
        } else {
          filtered = filtered.push(newIncident)
        }
      }
    })
  }

  return {
    closeby: sectionFilter.has("closeby")
      ? List<Incident & IncidentMeta>()
      : sortList(filteredCloseby),
    other: sectionFilter.has("other")
      ? List<Incident & IncidentMeta>()
      : sortList(filtered),
  }
}

const filterIncidents = (params: FilterParams): FilteredIncidents => {
  const { incidents, incidentFilter, followingIncidents } = params
  let { closeby: closebyIncidents, other: otherIncidents } = incidents

  const filterIncidentByLevel = (
    originalIncidents: List<Incident & IncidentMeta>,
  ): List<Incident & IncidentMeta> => {
    const filteredIncidents = originalIncidents.filter(
      incident =>
        (incidentFilter.incident_lv0 && incident.level === 0) ||
        (incidentFilter.incident_lv1 && incident.level === 1) ||
        (incidentFilter.incident_lv2 && incident.level === 2),
    )
    return filteredIncidents
  }

  const filterIncidentByHours = (
    originalIncidents: List<Incident & IncidentMeta>,
  ): List<Incident & IncidentMeta> => {
    const filteredIncidents = originalIncidents.filter(incident => {
      const latestRev = latestRevision(incident)
      if (!latestRev) {
        return false
      }

      const traceBackHours = parseInt(incidentFilter.traceBackHours)
      const ms_per_hour = 1000 * 60 * 60
      const time = new Date(latestRev.createdAt).getTime()

      return time > Date.now() - ms_per_hour * traceBackHours
    })

    return filteredIncidents
  }

  const filterIncidentFollowed = (
    originalIncidents: List<Incident & IncidentMeta>,
  ): List<Incident & IncidentMeta> => {
    const filteredIncidents = originalIncidents.filter(
      incident =>
        followingIncidents.size > 0 && followingIncidents.includes(incident.id),
    )

    return filteredIncidents
  }

  const filterIncidentByCategory = (
    originalIncidents: List<Incident & IncidentMeta>,
  ): List<Incident & IncidentMeta> => {
    const incident_category = incidentFilter.incident_category
    if (!incident_category || incident_category === "all") {
      return originalIncidents
    }
    const filteredIncidents = originalIncidents.filter(
      incident =>
        incident.categories.toLowerCase() === incident_category.toLowerCase(),
    )
    return filteredIncidents
  }

  // incident level filter
  let filteredClosebyWithLevel = filterIncidentByLevel(closebyIncidents)
  let filteredOtherWithLevel = filterIncidentByLevel(otherIncidents)

  // time range filter
  let filteredClosebyWithTime = filterIncidentByHours(filteredClosebyWithLevel)
  let filteredOtherWithTime = filterIncidentByHours(filteredOtherWithLevel)

  // category filter
  let filteredClosebyWithCategory = filterIncidentByCategory(
    filteredClosebyWithTime,
  )
  let filteredOtherWithCategory = filterIncidentByCategory(
    filteredOtherWithTime,
  )

  // incidents followed by user
  if (incidentFilter.following && followingIncidents.size > 0) {
    let filteredClosebyWithFollow = filterIncidentFollowed(
      filteredClosebyWithCategory,
    )
    let filteredOtherWithFollow = filterIncidentFollowed(
      filteredOtherWithCategory,
    )

    return {
      closeby: filteredClosebyWithFollow,
      other: filteredOtherWithFollow,
    }
  }

  return {
    closeby: filteredClosebyWithCategory,
    other: filteredOtherWithCategory,
  }
}

interface MarkerParams {
  psn: string
  filteredIncidents: FilteredIncidents
  locations?: List<UserLocation>
  userCountMarker?: Marker
}

const getMarkers = (params: MarkerParams): Map<string, Marker> => {
  const { psn, filteredIncidents, locations, userCountMarker } = params
  let markers = Map<string, Marker>()
  const incidents = filteredIncidents.closeby.concat(filteredIncidents.other)

  incidents.forEach(i => {
    markers = markers.set(i.id, getMarkerFromIncident(i, psn))
  })

  if (locations && locations.size < 750) {
    locations.forEach(l => {
      markers = markers.set(l.userId, getMarkerFromUserLocation(l))
    })
  }

  if (userCountMarker) {
    markers = markers.set(userCountMarker.id, userCountMarker)
  }

  return markers
}

const showMarkers = (
  markers: any,
  defaultZoom?: number,
  zoom?: number,
): any => {
  if (!defaultZoom || !zoom) {
    return markers
  }

  return defaultZoom <= zoom ? markers : undefined
}

const incidentFeedReducer = (state: State, action: Action): State => {
  const newState = { ...state }
  let updateFilteredIncidents = false
  let updateMarkers = false

  switch (action.type) {
    case ActionType.SetIncidents:
      let newIncidents = Map<string, Incident & IncidentMeta>()
      const polys = Object.keys(action.data.polygons).map(id => ({
        ...action.data.polygons[id],
        id,
      }))
      action.data.incidents &&
        action.data.incidents.forEach((incident: Incident) => {
          let incident_category = ""
          try {
            const incident_categories = JSON.parse(atob(incident.categories))
            incident_category = incident_categories[0]
          } catch (e) {
            // prevent empty category sting, can print incident id to check it out
          }
          const closest = getClosestPolygonFromIncident(incident, polys)
          newIncidents = newIncidents.set(incident.id, {
            ...incident,
            categories: incident_category,
            polyId: closest ? closest.id : undefined,
            polyName: closest
              ? action.data.polygons[closest.id].name
              : undefined,
            distanceFrom: closest ? closest.distance : undefined,
          })
        })
      newState.incidents = newIncidents
      updateFilteredIncidents = true
      updateMarkers = true
      break
    case ActionType.SearchIncidents:
      newState.keywordFilter = action.data
        ? action.data.toLowerCase()
        : undefined
      updateFilteredIncidents = true
      updateMarkers = true
      break
    case ActionType.UpdateBounds:
      newState.bounds = action.data
      updateFilteredIncidents = true
      break
    case ActionType.SetUserLocations:
      newState.userLocations = List(action.data)
      updateMarkers = true
      break
    case ActionType.SetUserCountMarker:
      newState.userCountMarker = action.data
      if (action.data && action.data.model.userCount1) {
        emitter.emit(EventType.AddMarker, action.data)
      }
      break
    case ActionType.SetZoom:
      newState.zoom = action.data
      updateMarkers = true
      break
    case ActionType.SetDefaultZoom:
      newState.defaultZoom = action.data
      updateMarkers = true
      break
    case ActionType.SetPolygonCoords:
      newState.polygonCoords = action.data
      break
    case ActionType.SetLocationFilter:
      newState.locationFilter = action.data
      updateFilteredIncidents = true
      break
    case ActionType.HideIncidentType:
      newState.sectionFilter = action.data.hidden
        ? newState.sectionFilter.add(action.data.section)
        : newState.sectionFilter.delete(action.data.section)
      updateFilteredIncidents = true
      updateMarkers = true
      break
    case ActionType.ResetFilters:
      newState.sectionFilter = Set()
      updateFilteredIncidents = true
      updateMarkers = true
      break
    case ActionType.SetIncidentFilter:
      newState.additionalFilters = action.data
      updateFilteredIncidents = true
      updateMarkers = true
      break
    case ActionType.SetFollowingIncidents:
      newState.followingIncidents = List(action.data.followingIncidents)
      updateFilteredIncidents = true
      updateMarkers = true
      break
    default:
      break
  }

  if (updateFilteredIncidents) {
    newState.filteredIncidents = searchIncidents({
      incidents: newState.incidents,
      bounds: newState.bounds,
      keywordFilter: newState.keywordFilter,
      locationFilter: newState.locationFilter,
      sectionFilter: newState.sectionFilter,
    })

    newState.filteredIncidents = filterIncidents({
      incidents: newState.filteredIncidents,
      incidentFilter: newState.additionalFilters,
      followingIncidents: newState.followingIncidents,
    })
  }

  if (updateMarkers) {
    emitter.emit(
      EventType.SetMarkers,
      getMarkers({
        psn: newState.psnId,
        filteredIncidents: newState.filteredIncidents,
        locations: showMarkers(
          newState.userLocations,
          newState.defaultZoom,
          newState.zoom,
        ),
        userCountMarker: showMarkers(
          newState.userCountMarker,
          newState.defaultZoom,
          newState.zoom,
        ),
      }),
    )
  }

  return newState
}

export default incidentFeedReducer
