import React from "react"
import { Stream } from "types/incident"
import styles from "./StreamUpdate.module.css"
import dayjs from "dayjs"
import StreamPlayer from "./StreamPlayer/StreamPlayer"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  stream: Stream
}

const StreamUpdate: React.FC<Props> = ({ stream }) => {
  const live = !stream.hlsDone && !stream.broadcastDone
  const url = live ? stream.hlsLiveUrl : stream.hlsVodUrl

  return (
    <div className={styles.container}>
      <div className={styles.separator}>
        <div className={styles.ball} />
        <div className={styles.line} />
      </div>
      <div className={styles.main}>
        <div className={styles.top}>
          <Body type={BodyType.Bold}>
            {dayjs(stream.createdAt).format("h:mmA")}
          </Body>
          <div className={styles.description}>
            <Body type={BodyType.Regular}>{` · @${stream.username}`}</Body>
          </div>
        </div>
        <StreamPlayer
          className={styles.player}
          url={url}
          live={live}
          id={stream.id}
          verified={stream.confirmed}
        />
      </div>
    </div>
  )
}

export default StreamUpdate
