import { uploadMembersCSV } from "api/member"
import { useState } from "react"
import history from "utils/history"

const useCSVUpload = (psn?: string) => {
  const [file, setFile] = useState<File>()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState()

  const onChange = ({ target: { files } }: any): void => {
    if (psn) {
      setError(undefined)
      setFile(files[0])
    }
  }

  const onUpload = () => {
    if (psn && file) {
      setUploading(true)
      uploadMembersCSV(psn, file).subscribe(
        () => {
          setUploading(false)
          history.push(`/${psn}/safetrace/organization`)
        },
        e => {
          setUploading(false)
          setError(e)
        },
      )
    }
  }

  return {
    onChange,
    onUpload,
    file,
    uploading,
    error,
  }
}

export default useCSVUpload
