import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import Container from "components/Common/Container"
import { sendAuthCode } from "api/gsuite"
import history from "utils/history"

const CallbackPage: React.FC<RouteComponentProps> = ({ location }) => {
  const query = new URLSearchParams(location.search)
  const code = query.get("code")
  const state = query.get("state")

  useEffect(() => {
    if (code && state) {
      sendAuthCode(code, state).subscribe(() =>
        history.push(`/${state}/safetrace/gsuite/sync`),
      )
    }
  }, [code, state])
  return <Container>Loading...</Container>
}

export default CallbackPage
