import React, { useContext } from "react"
import PSNContext from "components/Contexts/PSNContext"
import useDashboardPage from "./DashboardPage.hook"
import Container from "components/Common/Container"
import DashboardSection from "./DashboardSection"

const DashboardPage = () => {
  const psn = useContext(PSNContext)
  const { sections, aggregateStats } = useDashboardPage(psn && psn.id)

  if (!aggregateStats) {
    return null
  }

  return (
    <Container
      style={{ display: "block", textAlign: "left" }}
      left={3}
      right={5}
      fullWidth
      fullHeight
    >
      {sections.map(section => (
        <DashboardSection
          key={section.id}
          id={section.id}
          label={section.label}
          aggregateStats={aggregateStats}
        />
      ))}
    </Container>
  )
}

export default DashboardPage
