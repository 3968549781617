import { OptimusAPI, getAuthToken } from "./index"
import { Observable, from } from "rxjs"
import { AxiosResponse } from "axios"
import { PSNResponse, UserLocation, Polygon } from "types/psn"
import dayjs from "dayjs"

export const getPSN = (id: string): Observable<AxiosResponse<PSNResponse>> => {
  return from(OptimusAPI.get(`/psn/${id}`))
}

export const getPSNUserLocations = (
  id: string,
  since: Date = dayjs()
    .subtract(4, "hour")
    .toDate(),
): Observable<AxiosResponse<UserLocation[]>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${id}/user_locations`, {
      params: { since },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const updatePSNPolygon = (
  psn: string,
  polygon: string,
  properties: Partial<Polygon>,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(`/psn/${psn}/polygon/${polygon}`, properties, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

interface PSNView {
  viewLat: number
  viewLng: number
  viewZoom: number
}

export const updatePSNView = (
  psn: string,
  properties: Partial<PSNView>,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.patch(`/psn/${psn}/view`, properties, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export default {
  getPSN,
  getPSNUserLocations,
  updatePSNPolygon,
  updatePSNView,
}
