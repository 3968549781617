import React, { useState, useEffect } from "react"
import {
  Switch,
  Route,
  matchPath,
  withRouter,
  RouteComponentProps,
} from "react-router-dom"
import styles from "./PanelBase.module.css"
import IncidentCreation from "components/IncidentCreation/IncidentCreation"
import IncidentFeed from "components/IncidentFeed/IncidentFeed"
import IncidentDetail from "components/IncidentDetail/IncidentDetail"
import IncidentUpdate from "components/IncidentUpdate/IncidentUpdate"
import { first } from "lodash"
import FadeIn from "components/Animations/FadeIn"

const widthMapping: { [route: string]: number | string } = {
  "/:psn/incidents/create": "50vw",
  "/:psn/incidents/:id": "50vw",
  "/:psn": "50vw",
  "/:psn/:location": "50vw",
}
const ANIMATION_DELAY = 0
const ANIMATION_DURATION = 250

interface Props {
  mapInit: boolean
  overlay?: boolean
}

const PanelBase: React.FC<RouteComponentProps & Props> = ({
  mapInit,
  location: { pathname },
  overlay,
}) => {
  const [width, setWidth] = useState<number | string>(0)

  useEffect(() => {
    const match =
      first(
        Object.keys(widthMapping).filter(path => matchPath(pathname, { path })),
      ) || "/"
    setWidth(widthMapping[match])

    return (): void => {}
  }, [pathname])

  return (
    <div className={styles.panel} style={{ width }}>
      <Switch>
        <Route
          exact
          path='/:psn/:location/incidents/create'
          render={({
            match: {
              params: { location },
            },
          }): any => (
            <FadeIn
              duration={ANIMATION_DURATION}
              delay={ANIMATION_DELAY}
              className={styles.container}
              key='creation'
              width='50vw'
            >
              <IncidentCreation mapInit={mapInit} location={location} />
            </FadeIn>
          )}
        />
        <Route
          exact
          path='/:psn/incidents/:id/edit'
          render={({
            match: {
              params: { id },
            },
          }): any => (
            <FadeIn
              duration={ANIMATION_DURATION}
              delay={ANIMATION_DELAY}
              className={styles.container}
              key='creation'
              width='50vw'
            >
              <IncidentUpdate mapInit={mapInit} id={id} />
            </FadeIn>
          )}
        />
        <Route
          path='/:psn/incidents/:id'
          exact
          render={({
            match: {
              params: { id },
            },
          }): any => {
            return (
              <FadeIn
                duration={ANIMATION_DURATION}
                delay={ANIMATION_DELAY}
                className={styles.container}
                key='detail'
                width='50vw'
              >
                <IncidentDetail id={id} />
              </FadeIn>
            )
          }}
        />
        <Route
          exact
          path={["/:psn", "/:psn/:location"]}
          render={({
            match: {
              params: { location },
            },
          }): any => (
            <FadeIn
              duration={ANIMATION_DURATION}
              delay={ANIMATION_DELAY}
              className={styles.container}
              key='feed'
              width='50vw'
            >
              <IncidentFeed mapInit={mapInit} location={location} />
            </FadeIn>
          )}
        />
      </Switch>
      {overlay && <div className={styles.overlay} />}
    </div>
  )
}

export default withRouter(PanelBase)
