import React from "react"
import ReactDOM from "react-dom"
import usePortal from "components/Hooks/usePortal"
import classnames from "classnames"
import styles from "./Modal.module.css"

interface Props {
  show?: boolean
  className?: string
  root?: string
}

const Modal: React.FC<Props> = ({
  show = true,
  className = "",
  children,
  root = "modal-root",
}) => {
  const div = usePortal(root)

  if (!show) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={classnames(styles.container, className)}>
      {/* <div className={styles.overlay} /> */}
      <div className={styles.modal}>{children}</div>
    </div>,
    div,
  )
}

export default Modal
