import React from "react"
import classnames from "classnames"
import Container from "components/Common/Container"
import styles from "./Pagination.module.css"
import Small, { SmallType } from "components/Texts/Small"
import Spacing from "components/Common/Spacing"
import { range } from "lodash"

interface Props {
  currentPage: number
  totalPages: number
  onClick: (page: number) => void
}

const MAX_SHOWN = 7

const Pagination: React.FC<Props> = ({ currentPage, totalPages, onClick }) => {
  let pages: Array<string | number> = []
  if (totalPages > MAX_SHOWN) {
    if (currentPage < Math.ceil(MAX_SHOWN / 2)) {
      pages = range(1, MAX_SHOWN - 1).concat([-1, totalPages])
    } else if (currentPage > totalPages - Math.floor(MAX_SHOWN / 2)) {
      pages = [1, -1].concat(range(totalPages - MAX_SHOWN + 3, totalPages + 1))
    } else {
      pages = [1, -1]
        .concat(range(currentPage - 1, currentPage + 2))
        .concat([-1, totalPages])
    }
  } else {
    pages = range(1, totalPages + 1)
  }

  return (
    <Container>
      {pages.map((p, i) => {
        return (
          <Spacing left={1} right={1} key={i}>
            <button
              className={classnames(styles.button, {
                [styles.active]: p === currentPage,
              })}
              onClick={() => onClick(p as number)}
              disabled={typeof p !== "number"}
            >
              <Small type={SmallType.Bold}>{p === -1 ? "..." : p}</Small>
            </button>
          </Spacing>
        )
      })}
    </Container>
  )
}

export default Pagination
