import React, { useEffect, useRef, useState } from "react"
import useSound, { PlayStatus } from "components/Hooks/useSound"
import emitter, { EventType } from "emitter"
import styles from "./ClipPlayer.module.css"

interface Props {
  url: string
  defaultStatus?: PlayStatus
  channelHeader: string
  durationMs: number
}

const ClipPlayer: React.FC<Props> = ({
  url,
  defaultStatus = PlayStatus.Stopped,
  channelHeader,
  durationMs,
}) => {
  const [status, setStatus] = useState(defaultStatus)
  const [position, setPosition] = useState(0)
  const soundId = useSound({
    url,
    onPlaying: sound => {
      setPosition(Math.ceil(sound.position))
    },
    onfinish: () => {
      setStatus(PlayStatus.Stopped)
    },
    onpause: () => {
      setStatus(PlayStatus.Paused)
    },
    onplay: () => {
      setStatus(PlayStatus.Playing)
    },
  })
  const playerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (soundId) {
      emitter.emit(EventType.SetSoundStatus, soundId, status)
    }
  }, [status, soundId])

  return (
    <div
      className={styles.container}
      ref={playerRef}
      onClick={(e): void => {
        e.preventDefault()
        e.stopPropagation()
        const clientX = e.clientX
        const target = playerRef.current
        if (target) {
          const rect = target.getBoundingClientRect()
          const offsetX = clientX - rect.left
          const position = (offsetX / target.scrollWidth) * durationMs

          if (soundId) {
            emitter.emit(EventType.SetSoundStatus, soundId, PlayStatus.Playing)
            emitter.emit(EventType.SetSoundPosition, soundId, position)
            setStatus(PlayStatus.Playing)
          }
        }
      }}
    >
      <div
        className={styles.position}
        style={{ width: `${(position / durationMs) * 100}%` }}
      />
      <div className={styles.overlay}>
        <button
          className={styles.button}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            if (status === PlayStatus.Playing) {
              setStatus(PlayStatus.Paused)
            } else {
              setStatus(PlayStatus.Playing)
            }
          }}
        >
          {status !== PlayStatus.Playing && (
            <div className='fading-icon'>
              <i className='fas fa-play' />
            </div>
          )}
          {status === PlayStatus.Playing && (
            <div className='fading-icon'>
              <i className='fas fa-pause' />
            </div>
          )}
        </button>
        <div className={styles.channel}>{channelHeader}</div>
        <div className={styles.time}>{durationMs / 1000}s</div>
      </div>
    </div>
  )
}

export default React.memo(ClipPlayer)
