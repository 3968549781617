import React from "react"
import styles from "./Incident.module.css"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { shouldReplace, constructTo } from "utils/history"
import { Link } from "react-router-dom"
import PSNContext, { PSN } from "components/Contexts/PSNContext"
import emitter, { EventType } from "emitter"
import Body, { BodyType } from "components/Texts/Body"
import LevelBadge from "components/Badges/LevelBadge"
import { capitalize, round } from "lodash"
import { followIncident, unfollowIncident } from "api/incident"

dayjs.extend(relativeTime)

interface Props {
  createdAt?: string
  severityColor: string
  title: string
  location: string
  id: string
  psnId?: string
  clearanceLevel: number
  polyName?: string
  distanceFrom?: number
  locationSuffix?: string
  following?: boolean
  toggleFollowingIncidents: (incidentId: string) => void
}

const getLocationString = (
  distanceFrom?: number,
  polyName?: string,
  locationSuffix?: string,
): string => {
  let str = ""

  if (distanceFrom) {
    str += `${round(distanceFrom, 2)} mi`
  }

  if (distanceFrom === 0) {
    str += ` ${polyName}`
  } else {
    str += locationSuffix ? ` ${locationSuffix}` : ` from ${polyName}`
  }

  return str
}

const Incident: React.FC<Props & { psn: PSN }> = ({
  psn,
  createdAt,
  severityColor,
  title,
  location,
  id,
  psnId,
  clearanceLevel,
  polyName,
  distanceFrom,
  locationSuffix,
  following,
  toggleFollowingIncidents,
}) => {
  const url = `/${psn.id}/incidents/${id}`
  let level

  if (psnId) {
    level = psn.levels.find(l => l.level === clearanceLevel)
  }

  return (
    <Link to={constructTo(url)} replace={shouldReplace(url)}>
      <button
        className={styles.incident}
        onMouseEnter={(): void => {
          emitter.emit(EventType.SetFocusedMarker, id)
        }}
        onMouseLeave={(): void => {
          emitter.emit(EventType.SetFocusedMarker, undefined)
        }}
      >
        <div className={styles.top}>
          <Body
            type={BodyType.Bold}
            className={styles.time}
            style={{ color: severityColor }}
          >
            {createdAt && capitalize(dayjs(createdAt).fromNow())}
          </Body>
          {level ? (
            <LevelBadge {...level} />
          ) : (
            <Body type={BodyType.Regular} className={styles.polygon}>
              {getLocationString(distanceFrom, polyName, locationSuffix)}
            </Body>
          )}
        </div>
        <h2>{title}</h2>
        <Body className={styles.location} type={BodyType.Regular}>
          {location}
          {following ? (
            <span
              className={styles.star}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                unfollowIncident(id)
                toggleFollowingIncidents(id)
              }}
            >
              <span role='img' aria-label='yellow star'>
                ⭐
              </span>
            </span>
          ) : (
            <span
              className={styles.star}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                followIncident(id)
                toggleFollowingIncidents(id)
              }}
            >
              <span role='img' aria-label='star'>
                ☆
              </span>
            </span>
          )}
        </Body>
      </button>
    </Link>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <Incident {...props} psn={psn} />
        }
      }}
    </PSNContext.Consumer>
  )
}

export default React.memo(Wrapper)
