import React from "react"
import styles from "./IncidentList.module.css"
import { Incident as IncidentType, IncidentMeta } from "types/incident"
import { List } from "immutable"
import Incident from "./Incident"
import { latestRevision, getSeverityStyling } from "utils/incident"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"

interface Props {
  incidents: List<IncidentType & IncidentMeta>
  heading?: string
  locationSuffix?: string
  onHideToggle?: (hidden: boolean) => void
  hidden?: boolean
  followingIncidents?: List<string>
  onUpdateFollowingIncidents: (followIncidents: string[]) => void
}

const IncidentList: React.FC<Props> = ({
  incidents,
  heading,
  locationSuffix,
  followingIncidents,
  onUpdateFollowingIncidents,
  onHideToggle,
  hidden,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h5 className={styles.heading}>{heading}</h5>
        {onHideToggle && (
          <Button
            type={ButtonType.Subtle}
            size={ButtonSize.Small}
            onClick={(): void => {
              const shouldHide = !hidden
              onHideToggle(shouldHide)
            }}
          >
            {hidden ? "Show" : "Hide"}
          </Button>
        )}
      </div>
      {incidents.map(incident => {
        const latestRev = latestRevision(incident)
        const style = getSeverityStyling(incident)

        return (
          <Incident
            createdAt={latestRev ? latestRev.createdAt : undefined}
            clearanceLevel={incident.clearanceLevel}
            severityColor={style.fill}
            title={incident.title}
            location={incident.location.location}
            key={incident.id}
            id={incident.id}
            psnId={incident.psnId}
            polyName={incident.polyName}
            distanceFrom={incident.distanceFrom}
            locationSuffix={locationSuffix}
            following={
              followingIncidents &&
              followingIncidents.size > 0 &&
              followingIncidents.includes(incident.id)
            }
            toggleFollowingIncidents={incident_id => {
              if (
                followingIncidents &&
                followingIncidents.size > 0 &&
                followingIncidents.includes(incident_id)
              ) {
                onUpdateFollowingIncidents(
                  followingIncidents.filter(id => id !== incident_id).toArray(),
                )
              } else if (followingIncidents && followingIncidents.size > 0) {
                onUpdateFollowingIncidents(
                  followingIncidents.push(incident_id).toArray(),
                )
              } else {
                onUpdateFollowingIncidents([incident_id])
              }
            }}
          />
        )
      })}
    </div>
  )
}

export default IncidentList
