import React, { useState, useEffect } from "react"
import { numberWithCommas } from "utils/numbers"

interface Props {
  anchor: number
  timing: number // in ms
  className?: string
}

const Odometer: React.FC<Props> = ({ anchor, timing, className }) => {
  const [current, setCurrent] = useState(anchor)

  useEffect(() => {
    const interval = setInterval(() => {
      const reset = Math.random() > 0.5
      if (reset) {
        const add = Math.random() > 0.5
        const numPeople = Math.floor(Math.random() * (anchor * 0.001))

        setCurrent(Math.max(0, anchor + (add ? 1 : -1) * numPeople))
      }
    }, timing)
    // const timeout = setTimeout(() => setCurrent(anchor), timing)

    return (): void => {
      // clearTimeout(timeout)
      clearInterval(interval)
    }
    // eslint-disable-n-line react-hooks/exhaustive-deps
  }, [timing, anchor])

  return <span className={className}>{numberWithCommas(current)}</span>
}

export default Odometer
