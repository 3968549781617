import React, { useState, useEffect } from "react"
import styles from "./MarkerDetail.module.css"
import { StreamLite, PublicIncident } from "api/marketing"
import StreamPlayer from "components/IncidentDetail/Updates/StreamPlayer/StreamPlayer"
import classnames from "classnames"
import MarketingFeedUnit from "./MarketingFeedUnit"
import VolumeButton from "./VolumeButton"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import { track } from "utils/analytics"
import { getLowerQualityStream } from "utils/stream"

interface Props {
  stream?: StreamLite | null
  className?: string
  incident: PublicIncident
  forceWidth?: number
  onStreamClose?: () => void
  top?: number
}

const MarkerDetail: React.FC<Props> = ({
  stream,
  className,
  incident,
  forceWidth,
  onStreamClose,
  top,
}) => {
  const [muted, setMuted] = useState(true)
  const ASPECT_RATIO = 720 / 1280
  const videoHeight = forceWidth ? forceWidth * (1 / ASPECT_RATIO) : 284
  const videoWidth = forceWidth || videoHeight * ASPECT_RATIO

  useEffect(() => {
    if (stream) {
      track("Marketing", "Show", "Incident Stream", stream.stream_id)
    } else {
      track("Marketing", "Show", "Incident Card", incident.incident_id)
    }
  }, [incident.incident_id, stream])

  return (
    <div
      className={classnames(styles.container, className, {
        [styles.descriptionOnly]: !stream,
      })}
      style={{
        width: videoWidth,
        top,
      }}
    >
      {stream && (
        <div
          className={classnames(styles.video, styles.videoContainer)}
          style={{
            height: videoHeight,
          }}
        >
          <img src='/icons/spinner.svg' alt='loading' />
          <StreamPlayer
            className={styles.video}
            url={getLowerQualityStream(stream.hls_url)}
            id={stream.stream_id}
            live={false}
            verified={false}
            playing={true}
            style={{
              height: videoHeight,
            }}
            muted={muted}
            controls={false}
          />
          <div className={styles.controls}>
            <VolumeButton
              onClick={(): void => {
                track(
                  "Marketing",
                  "Click",
                  muted ? "Stream Unmute" : "Stream Mute",
                  stream.stream_id,
                )
                setMuted(!muted)
              }}
              muted={muted}
            />
          </div>
        </div>
      )}
      <MarketingFeedUnit incident={incident} />
      {onStreamClose && (
        <Button
          className={styles.close}
          type={ButtonType.Cancel}
          size={ButtonSize.Medium}
          onClick={(): void => {
            track(
              "Marketing",
              "Click",
              "Incident Card Close",
              incident.incident_id,
            )
            onStreamClose()
          }}
        />
      )}
    </div>
  )
}

export default MarkerDetail
