import React, { useContext } from "react"
import Container from "components/Common/Container"
import Spacing from "components/Common/Spacing"
import Body, { BodyType } from "components/Texts/Body"
import useGSuiteAuthPage from "./GSuiteAuthPage.hook"
import PSNContext from "components/Contexts/PSNContext"

interface Props {
  defaultStep: number
}

const GSuiteAuthPage: React.FC<Props> = ({ defaultStep }) => {
  const psn = useContext(PSNContext)
  const { heading, Component, onNext, onPrev } = useGSuiteAuthPage(
    defaultStep,
    psn && psn.id,
  )

  return (
    <Container fill column>
      <Container fill column leftJustify style={{ maxWidth: 400 }}>
        <Spacing bottom={2}>
          <Body type={BodyType.Bold}>{heading}</Body>
        </Spacing>
        {Component && <Component onNext={onNext} onPrev={onPrev} />}
      </Container>
    </Container>
  )
}

export default GSuiteAuthPage
