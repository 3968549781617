import React, { useState, useEffect, useRef, Fragment } from "react"
import PSNContext from "components/Contexts/PSNContext"
import { Helmet } from "react-helmet"
import usePSN from "components/Hooks/usePSN"
import styles from "./Marketing.module.css"
import LeadForm from "./LeadForm/LeadForm"
import MarketingMap from "./MarketingMap/MarketingMap"
import {
  getBoundingBoxOfPolygon,
  getCenterOfPolygon,
  getMarkerFromPublicIncident,
  getLocationsWithinPolygon,
} from "utils/map"
import { Map as ImmutableMap } from "immutable"
import classnames from "classnames"
import { Coords } from "google-map-react"
import { getPublicIncidents, PublicIncident } from "api/marketing"
import {
  Props as MarkerProps,
  MarketingMarkerType,
} from "./MarketingMap/MarketingMarker"
import { uniqBy } from "lodash"
import useScroll from "components/Hooks/useScroll"
import { getUserCounts } from "utils/psn"
import NavBar from "./NavBar/NavBar"
import ValuePropInfo from "./ValuePropInfo/ValuePropInfo"
import Tutorial from "./Tutorial/Tutorial"
import Partners from "./Partners/Partners"
import Footer from "./Footer/Footer"
import StickyBottomBanner from "./StickyBottomBanner/StickyBottomBanner"
import Odometer from "./Odometer"
import uuid from "uuid"
import history from "utils/history"
import { track, isDev } from "utils/analytics"

interface Props {
  psnId: string
  hash: string
  generic?: boolean
}

interface Viewport {
  bounds: number[]
  center: Coords
}

const addMarginToBounds = (bounds: number[]): number[] => {
  const MARGIN = 0.0005
  const [minX, minY, maxX, maxY] = bounds

  return [minX - MARGIN, minY - MARGIN, maxX + MARGIN, maxY + MARGIN]
}

const Marketing: React.FC<Props> = ({ psnId, hash, generic = false }) => {
  const container = useRef(null)
  const psn = usePSN(psnId)
  const [incidents, setIncidents] = useState<PublicIncident[]>([])
  const [markers, setMarkers] = useState<ImmutableMap<string, MarkerProps>>(
    ImmutableMap(),
  )
  const [dots, setDots] = useState<ImmutableMap<string, MarkerProps>>(
    ImmutableMap(),
  )
  const [viewport, setViewport] = useState<Viewport | undefined>()
  const [leadFormShown, setLeadFormShown] = useState(false)
  const [showMarkers, setShowMarkers] = useState(false)
  const [leadFormPosition, setLeadFormPosition] = useState(0)

  const polygonId = psn && Object.keys(psn.polygons)[0]
  const polygon = psn && polygonId && psn.polygons[polygonId]
  const coords = psn && polygonId && psn.polygonsMapping.get(polygonId)
  const userCount = psn && getUserCounts(psn.polygons).userCount30

  useEffect(() => {
    if (psn && polygonId && coords && userCount !== undefined) {
      const bounds = addMarginToBounds(getBoundingBoxOfPolygon(coords))
      const center = getCenterOfPolygon(coords).geometry.coordinates
      const updateIncidents = (data: PublicIncident[]): void => {
        setIncidents(data || [])
        let markers = ImmutableMap<string, MarkerProps>()
        uniqBy(data, i => `${i.latitude}+${i.longitude}`)
          .slice(0, 3)
          .forEach(i => {
            markers = markers.set(i.incident_id, getMarkerFromPublicIncident(i))
          })
        setMarkers(markers)
      }

      setViewport({
        bounds,
        center: { lat: center[0], lng: center[1] },
      })

      let d = ImmutableMap<string, MarkerProps>()
      const locs = getLocationsWithinPolygon(Math.min(userCount, 750), coords)
      locs.forEach(l => {
        const id = uuid()
        d = d.set(id, {
          id,
          type: MarketingMarkerType.Location,
          fill: "#ffffff",
          lat: l.lat,
          lng: l.lng,
          focused: false,
        })
      })
      setDots(d)

      getPublicIncidents(bounds).subscribe(({ data }) => {
        if (!data || data.length < 3) {
          getPublicIncidents(bounds, { video: false }).subscribe(({ data }) => {
            updateIncidents(data)
          })
        } else {
          updateIncidents(data)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psn])

  useEffect(() => {
    const regenerateDots = (): void => {
      const reset = Math.random() > 0.5
      if (coords && userCount && reset) {
        const add = Math.random() > 0.5
        const numPeople = Math.floor(Math.random() * 20)
        let d = dots
        if (add) {
          const locs = getLocationsWithinPolygon(
            Math.min(numPeople, 750),
            coords,
          )
          locs.forEach(l => {
            const id = uuid()
            d = d.set(id, {
              id,
              type: MarketingMarkerType.Location,
              fill: "#ffffff",
              lat: l.lat,
              lng: l.lng,
              focused: false,
            })
          })
        } else {
          for (let i = 0; i < numPeople; i += 1) {
            const value = d.first() as MarkerProps
            if (value) {
              d = d.remove(value.id)
            }
          }
        }

        setDots(d)
      }
    }

    const interval = setInterval(regenerateDots, 1000)

    return (): void => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psn, dots])

  useScroll(window, () => {
    const show = window.scrollY > window.outerHeight * 0.2
    if (showMarkers !== show) {
      setShowMarkers(show)
    }
  })

  useEffect(() => {
    // wait for page to load before scrolling
    const timeout = setTimeout(() => {
      if (hash) {
        const el = document.getElementById(hash.slice(1))
        if (el) {
          el.scrollIntoView({ behavior: "smooth" })
        }
      }
    }, 1000)

    return (): void => {
      clearTimeout(timeout)
    }
  }, [hash])

  useEffect(() => {
    if (psn) {
      track("Marketing", "PageView", psn.id, userCount)
    }
  }, [psn, userCount])

  if (
    !psn ||
    !polygonId ||
    !polygon ||
    !coords ||
    !viewport ||
    userCount === undefined
  ) {
    return null
  }

  return (
    <PSNContext.Provider value={psn}>
      {psn && (
        <Helmet>
          <meta name='title' content='Claim Your Private Safety Network' />
          <meta
            name='description'
            content='Reach Citizen users in your area right now.'
          />
          <title>Claim Your PSN</title>
          {!isDev() && <script src='/scripts/fullstory.js' async />}
          <link rel='stylesheet' href='/styles/odometer-theme-minimal.css' />
        </Helmet>
      )}
      <div className={styles.container} ref={container}>
        {!leadFormShown && (
          <NavBar
            overrideCta={generic ? "Join Now" : undefined}
            onClaim={(): void => {
              track(
                "Marketing",
                "Click",
                "NavBar",
                window.scrollY / window.outerHeight,
              )
              if (leadFormPosition) {
                window.scrollTo({
                  top: leadFormPosition,
                  left: 0,
                  behavior: "smooth",
                })
              } else {
                history.push("#form")
              }
            }}
          />
        )}
        <div
          className={styles.map}
          style={{ height: `${window.outerHeight * 1.75}px` }}
        >
          <MarketingMap
            center={viewport.center}
            zoom={13}
            bounds={viewport.bounds}
            polygon={ImmutableMap({ [polygonId]: coords })}
            markers={showMarkers ? dots.merge(markers) : dots}
            generic={generic}
          />
          {!showMarkers && (
            <div className={classnames(styles.heading, styles.overlay)}>
              <h1>
                {generic ? (
                  "Contact Citizen users in your area"
                ) : (
                  <Fragment>
                    Reach{" "}
                    <Odometer
                      anchor={userCount}
                      timing={500}
                      className={styles.odometer}
                    />{" "}
                    Citizen users in your area right now
                  </Fragment>
                )}
              </h1>
              <p>
                {generic ? (
                  "Join the network to keep users in your area safe"
                ) : (
                  <Fragment>
                    {generic ? "Join " : "Claim "} <b>{psn.name}</b> for
                    real-time awareness
                  </Fragment>
                )}
              </p>
              <button
                className={styles.claim}
                onClick={(): void => {
                  track("Marketing", "Click", "Main CTA")
                  if (leadFormPosition) {
                    window.scrollTo({
                      top: leadFormPosition,
                      left: 0,
                      behavior: "smooth",
                    })
                  } else {
                    history.push("#form")
                  }
                }}
              >
                {generic ? "Join Now" : "Claim Now"}
              </button>
              <div className={styles.number}>Call us at (646) 397-3766</div>
            </div>
          )}
        </div>
        <ValuePropInfo
          incident={incidents[0]}
          psnName={psn.name}
          generic={generic}
        />
        <Tutorial generic={generic} />
        <Partners />
        <LeadForm
          id='form'
          generic={generic}
          onShow={(): void => setLeadFormShown(true)}
          onHide={(): void => setLeadFormShown(false)}
          onRender={(scrollY: number): void => setLeadFormPosition(scrollY)}
        />
        <Footer />
        {!showMarkers && (
          <StickyBottomBanner
            onClick={(): void => {
              window.scrollTo({
                top: window.outerHeight * 0.3,
                left: 0,
                behavior: "smooth",
              })
            }}
          />
        )}
      </div>
    </PSNContext.Provider>
  )
}

export default Marketing
