import React, { useContext } from "react"
import PSNContext from "components/Contexts/PSNContext"
import useSymptomTrackingModal, { Symptom } from "./SymptomTrackingModal.hook"
import Modal from "components/Modals/Modal"
import styles from "./SymptomTrackingModal.module.css"
import Spacing from "components/Common/Spacing"
import Container from "components/Common/Container"
import { chunk, isEmpty } from "lodash"
import SafeTraceTraceRadioButton from "components/Form/SafeTrace/SafeTraceRadioButton"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import Small, { SmallType } from "components/Texts/Small"

interface Props {
  data?: any
  onClose: (refetch?: boolean) => void
}

const SymptomTrackingModal: React.FC<Props> = ({ data, onClose: close }) => {
  const psn = useContext(PSNContext)
  const {
    options,
    symptoms,
    onSelect,
    onSubmit,
    onClose,
    text,
    loading,
  } = useSymptomTrackingModal(close, data, psn && psn.id)

  return (
    <Modal show={!!data}>
      <Container
        className={styles.container}
        top={12}
        bottom={12}
        left={8}
        right={8}
        column
        leftJustify
      >
        <Spacing bottom={8}>
          <h2 className={styles.heading}>
            Has this member had any of the following symptoms in the past 3
            days?
          </h2>
        </Spacing>
        <Container column fullWidth>
          {chunk(options, 2).map((row, i) => (
            <Container fullWidth bottom={2} key={i}>
              {row.map((option: Symptom, index: number) => (
                <Spacing
                  right={index === 0 ? 2 : 0}
                  key={option.value}
                  fullWidth
                >
                  <SafeTraceTraceRadioButton
                    displayName={option.displayName}
                    on={symptoms[option.value]}
                    onClick={() => {
                      onSelect(option.value)
                    }}
                  />
                </Spacing>
              ))}
            </Container>
          ))}
        </Container>
        <Container fullWidth top={8}>
          <Container fill leftJustify>
            {data && (
              <Small type={SmallType.Regular} className={styles.info}>
                Symptomatic status currently set as '{text}'
              </Small>
            )}
          </Container>
          <Button
            type={!isEmpty(symptoms) ? ButtonType.Primary : ButtonType.Disabled}
            size={ButtonSize.Large}
            className={styles.submit}
            onClick={onSubmit}
          >
            SUBMIT{loading ? "TING..." : ""}
          </Button>
        </Container>
        <Button
          onClick={() => onClose()}
          type={ButtonType.Cancel}
          size={ButtonSize.Small}
          className={styles.close}
        />
      </Container>
    </Modal>
  )
}

export default SymptomTrackingModal
