import React, { useState, useEffect } from "react"
import Autosuggest from "react-autosuggest"
import MapContext, {
  State as MapContextState,
} from "components/Contexts/MapContext"
import styles from "./LocationInput.module.css"
import { EventType } from "emitter"
import useEmitter from "components/Hooks/useEmitter"
import fonts from "styles/fonts.module.css"
import classnames from "classnames"

interface Props {
  defaultValue?: string
  onSelect: (geo: any) => void
  onChange: (value: string) => void
  placeholder?: string
  lockDisplayTo?: string
}

interface GoogleServices {
  autocomplete: any
  sessionToken: any
  placesService: any
  geocoder: any
}

interface Suggestion {
  description: string
  id: string
  place_id: string
}

const LocationInput: React.FC<Props & MapContextState> = ({
  defaultValue = "",
  bounds,
  onSelect,
  onChange,
  placeholder = "search for location",
  lockDisplayTo,
}) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [value, setValue] = useState(defaultValue)
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion>()
  const [googleServices, setGoogleServices] = useState<GoogleServices>({
    autocomplete: undefined,
    sessionToken: undefined,
    placesService: undefined,
    geocoder: undefined,
  })
  const [init, setInit] = useState(false)
  const getPlaces = () => {
    if (window.google && window.google.maps) {
      return window.google.maps.places
    }
  }
  const eventMapping: { [event: string]: Function } = {}
  eventMapping[EventType.SetLocationSuggestion] = (data: any) => {
    const suggestion = { ...data, description: data.formatted_address }
    setSelectedSuggestion(suggestion)
    onSelect(suggestion)
  }

  useEmitter(eventMapping)

  useEffect(() => {
    let interval: NodeJS.Timer | number

    if (!init) {
      interval = setInterval(() => {
        if (getPlaces()) {
          setInit(true)
          const autocomplete = new (getPlaces() as any).AutocompleteService()
          const sessionToken = new (getPlaces() as any).AutocompleteSessionToken()
          const placesService = new (getPlaces() as any).PlacesService(
            document.createElement("div"),
          )
          const geocoder = new window.google.maps.Geocoder()

          setGoogleServices({
            autocomplete,
            sessionToken,
            placesService,
            geocoder,
          })
        }
      }, 500)
    }

    return (): void => {
      if (interval) {
        clearInterval(interval as NodeJS.Timer)
      }
    }
  }, [init])

  return (
    <div>
      <Autosuggest
        theme={styles}
        suggestions={suggestions}
        onSuggestionsFetchRequested={async (): Promise<void> => {
          if (value) {
            const suggests = new Promise<Suggestion[]>(resolve =>
              googleServices.autocomplete.getPlacePredictions(
                {
                  input: value.trim(),
                  sessionToken: googleServices.sessionToken,
                  bounds,
                  componentRestrictions: {
                    country: "us",
                  },
                },
                resolve,
              ),
            )
            const sugs = await suggests

            setSuggestions(sugs || [])
          }
        }}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionSelected={async (e, { suggestion }) => {
          const req = {
            placeId: suggestion.place_id,
            sessionToken: googleServices.sessionToken,
          }
          const { results, status } = await new Promise(resolve =>
            googleServices.placesService.getDetails(
              req,
              (results: any, status: any) => resolve({ results, status }),
            ),
          )
          if (status !== (getPlaces() as any).PlacesServiceStatus.OK) {
            return null
          }
          const gmaps = results
          const location = gmaps.geometry.location
          googleServices.sessionToken = new (getPlaces() as any).AutocompleteSessionToken()
          const geo = {
            ...(suggestion as any),
            gmaps,
            location: { lat: location.lat(), lng: location.lng() },
          }
          onSelect(geo)
        }}
        getSuggestionValue={(sug: any) => sug}
        renderSuggestion={(sug: Suggestion) => <div>{sug.description}</div>}
        renderInputComponent={(props: any) => {
          return (
            <div
              className={classnames(styles.input, fonts.body, fonts.regular)}
            >
              <input {...props} />
              {!!lockDisplayTo && (
                <div>
                  <img src='/icons/lock.svg' alt='locked' />
                </div>
              )}
            </div>
          )
        }}
        inputProps={{
          className: lockDisplayTo
            ? undefined
            : classnames(styles.input, fonts.body, fonts.regular),
          value:
            lockDisplayTo ||
            (selectedSuggestion ? selectedSuggestion.description : value),
          onChange: (e, { newValue }: any): void => {
            if (typeof newValue !== "string") {
              setSelectedSuggestion(newValue)
            } else {
              setValue(newValue)
              setSelectedSuggestion(undefined)
              if (onChange) {
                onChange(newValue)
              }
            }
          },
          disabled: !!lockDisplayTo,
          placeholder,
          onKeyDown: async e => {
            if (e.key === "Enter") {
              const req = {
                address: value,
                bounds,
              }

              const { results, status } = await new Promise(resolve => {
                if (googleServices.geocoder) {
                  ;(googleServices.geocoder as any).geocode(req, function(
                    results: any,
                    status: any,
                  ) {
                    resolve({ results, status })
                  })
                } else {
                  resolve({ results: {}, status: {} })
                }
              })

              if (status !== window.google.maps.GeocoderStatus.OK) {
                return null
              }
              const gmaps = results[0]
              const location = gmaps.geometry.location
              const geo = {
                gmaps,
                location: {
                  lat: location.lat(),
                  lng: location.lng(),
                },
              }
              setValue(gmaps.formatted_address)
              onSelect(geo)
            }
          },
        }}
        focusInputOnSuggestionClick={false}
      />
    </div>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <MapContext.Consumer>
      {(mapContext): any => <LocationInput {...props} {...mapContext} />}
    </MapContext.Consumer>
  )
}

export default Wrapper
