import React, { useContext } from "react"
import Modal from "components/Modals/Modal"
import Container from "components/Common/Container"
import styles from "./TestingModal.module.css"
import Spacing from "components/Common/Spacing"
import Calendar from "react-calendar"
import dayjs from "dayjs"
import "./Calendar.css"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import SafeTraceTraceRadioButton from "components/Form/SafeTrace/SafeTraceRadioButton"
import useTestingModal from "./TestingModal.hook"
import PSNContext from "components/Contexts/PSNContext"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  data?: any
  onClose: (refetch?: boolean) => void
}

const TestingModal: React.FC<Props> = ({ data, onClose }) => {
  const psn = useContext(PSNContext)
  const {
    options,
    setDate,
    date,
    selected,
    setSelected,
    onSubmit,
    loading,
  } = useTestingModal(onClose, data, psn && psn.id)

  return (
    <Modal show={!!data} key={data && data.id}>
      <Container
        className={styles.container}
        top={12}
        bottom={12}
        left={8}
        right={8}
        column
        leftJustify
      >
        <Spacing bottom={8}>
          <h2 className={styles.heading}>
            Please select a date and result type to submit a test result.
          </h2>
        </Spacing>
        <Container fill topAlign column>
          <Container fill bottom={8}>
            {options.map((option: string, i: number) => (
              <Spacing key={option} left={i === 0 ? 0 : 2} fullWidth>
                <SafeTraceTraceRadioButton
                  displayName={option}
                  on={option === selected}
                  onClick={setSelected}
                />
              </Spacing>
            ))}
          </Container>
          <Container className={styles.wrapper} left={15} right={15} fullWidth>
            <Calendar
              className={styles.calendar}
              maxDate={new Date()}
              minDate={dayjs()
                .subtract(14, "day")
                .toDate()}
	      onChange={(d: any) => setDate(d as Date)}
              value={date}
            />
          </Container>
        </Container>
        <Container fullWidth top={8}>
          <Container fill leftJustify>
            <Body type={BodyType.Bold} className={styles.info}>
              Please confirm accuracy. Meaningful contacts will be notified if
              positive test results are entered.
            </Body>
          </Container>
          <Button
            type={selected ? ButtonType.Primary : ButtonType.Disabled}
            size={ButtonSize.Large}
            className={styles.submit}
            onClick={onSubmit}
          >
            SUBMIT{loading ? "TING..." : ""}
          </Button>
        </Container>
        <Button
          onClick={() => onClose()}
          type={ButtonType.Cancel}
          size={ButtonSize.Small}
          className={styles.close}
        />
      </Container>
    </Modal>
  )
}

export default TestingModal
