const _stringify = (o: object | string): string =>
  typeof o === "string" ? o : JSON.stringify(o)

export const isDev = (): boolean =>
  window.location.hostname.startsWith("localhost")

// Every call is surround with try / catch to avoid erro'ing when user is using ad blocker

interface AnalyticEvent {
  eventAction: string
  eventCategory: string
  eventLabel?: string
  eventValue?: string | number
}

export const track = (
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: string | number,
): void => {
  if (!eventCategory || !eventAction) {
    // Need at least those 2
    return
  }

  const obj: AnalyticEvent = { eventCategory, eventAction }

  if (eventLabel !== undefined) {
    obj.eventLabel = eventLabel
  }

  if (eventValue !== undefined) {
    if (typeof eventValue === "number") {
      obj.eventValue = eventValue
    } else {
      obj.eventLabel = `${obj.eventLabel} - ${_stringify(eventValue)}`
    }
  }

  if (isDev()) {
    console.log(
      `Event Sent: \nCategory: ${obj.eventCategory}\nAction: ${obj.eventAction}\nLabel: ${obj.eventLabel}\nValue: ${obj.eventValue}`,
    )
    return
  }

  const win = window as any
  try {
    win.ga("send", "event", obj)
  } catch (e) {}
  try {
    win.analytics.track("event", obj)
  } catch (e) {}
}

interface UserMeta {
  name: string
  email: string
}

export const identify = (userId: string, userMeta?: UserMeta): void => {
  const win = window as any

  if (!userId) {
    // logout (seems like we can't unidentify from segment; it will re-inject the last user id before the logout)
    try {
      win.ga("set", "dimension1", "")
    } catch (e) {}

    return
  }

  try {
    win.analytics.identify(userId, userMeta)
  } catch (e) {}
  try {
    win.ga("set", "dimension1", userId)
  } catch (e) {}
}

export default {
  track,
  identify,
}
