import React, { FunctionComponent } from "react"
import classnames from "classnames"
import styles from "./Legal.module.css"

export enum LegalType {
  Regular = "regular",
  Bold = "bold",
}

interface Props {
  type: LegalType
  className?: string
  color?: string
}

const Legal: FunctionComponent<Props> = ({
  type,
  children,
  className,
  color,
}) => {
  return (
    <span
      className={classnames(
        styles.legal,
        styles[type.toLowerCase()],
        className,
      )}
      style={{ color }}
    >
      {children}
    </span>
  )
}

export default Legal
