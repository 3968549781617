import React from "react"
import classnames from "classnames"
import styles from "./Container.module.css"

interface ContainerProps {
  flex?: boolean
  column?: boolean
  center?: boolean
  className?: string
  children?: any
  fill?: boolean

  leftJustify?: boolean
  rightJustify?: boolean

  topAlign?: boolean
  bottomAlign?: boolean

  fullWidth?: boolean
  fullHeight?: boolean

  top?: number
  right?: number
  bottom?: number
  left?: number

  style?: any

  onClick?: (e?: any) => void
}

export const spacing = (num: number) => 4 * num

const Container = ({
  flex = true,
  column = false,
  center = true,
  fill = false,
  fullWidth = false,
  fullHeight = false,
  className,
  children,

  leftJustify = false,
  rightJustify = false,
  topAlign = false,
  bottomAlign = false,

  top = 0,
  right = 0,
  bottom = 0,
  left = 0,

  style,
  onClick,
}: ContainerProps) => {
  let styling = {}

  if (leftJustify) {
    styling = {
      ...styling,
      [column ? "alignItems" : "justifyContent"]: "flex-start",
    }
  } else if (rightJustify) {
    styling = {
      ...styling,
      [column ? "alignItems" : "justifyContent"]: "flex-end",
    }
  }

  if (topAlign) {
    styling = {
      ...styling,
      [!column ? "alignItems" : "justifyContent"]: "flex-start",
    }
  } else if (bottomAlign) {
    styling = {
      ...styling,
      [!column ? "alignItems" : "justifyContent"]: "flex-end",
    }
  }

  return (
    <div
      className={classnames(
        {
          [styles.flex]: flex,
          [styles.column]: column,
          [styles.center]: center,
          [styles.fill]: fill,
          [styles.fullWidth]: fullWidth,
          [styles.fullHeight]: fullHeight,
          [styles.button]: onClick,
        },
        className,
      )}
      style={{
        paddingTop: spacing(top),
        paddingRight: spacing(right),
        paddingBottom: spacing(bottom),
        paddingLeft: spacing(left),

        ...styling,
        ...style,
      }}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      {children}
    </div>
  )
}

export default Container
