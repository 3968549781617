import axios from "axios"
import config from "config"
import localStorage, { LocalStorageProp } from "localStorage"
import UserAPI from "./user"
import PSNAPI from "./psn"
import NotificationAPI from "./notification"
import IncidentAPI from "./incident"
import MarketingAPI from "./marketing"

export const OptimusAPI = axios.create({ baseURL: config.data })

export enum ErrorType {
  NotAuthenticated = "Not authenticated",
  InvalidToken = "invalid token",
}

export const getAuthToken = (): string => {
  const citizenJWT = localStorage.get(LocalStorageProp.Token)
  if (!citizenJWT) {
    // state.emit("logout")
    throw new Error(ErrorType.NotAuthenticated)
  }
  return citizenJWT
}

export default {
  ...IncidentAPI,
  ...MarketingAPI,
  ...NotificationAPI,
  ...PSNAPI,
  ...UserAPI,
}
