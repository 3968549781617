import React, { useState } from "react"
import styles from "./EnterCode.module.css"
import NumberInput from "components/Form/NumberInput"
import { Map } from "immutable"
import classnames from "classnames"
import { validateCode, login } from "api/user"
import emitter, { EventType } from "emitter"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  onSubmit: (userId: string) => void
  onUsePassword: () => void
  phone: string
}

const EnterCode: React.FC<Props> = ({ onSubmit, onUsePassword, phone }) => {
  const [digits, setDigits] = useState(Map())
  const [loading, setLoading] = useState(false)
  const submit = (): void => {
    const code = digits
      .valueSeq()
      .join("")
      .trim()
    setLoading(true)
    validateCode(phone, code).subscribe(({ data }) => {
      login(data.userToken)
      setLoading(false)
      onSubmit(data.uid)
    })
  }
  const disabled =
    digits
      .valueSeq()
      .join("")
      .trim().length !== 4

  return (
    <div className={styles.container}>
      <Body type={BodyType.Regular} className={styles.text}>
        Enter the 4-digit code
      </Body>
      <div className={styles.inputContainer}>
        {Array(4)
          .fill("")
          .map((v, i) => {
            return (
              <NumberInput
                key={i}
                id={`EnterCode-${i}`}
                onChange={(val): void => {
                  setDigits(digits.set(i, val))
                  if (i < 3) {
                    emitter.emit(EventType.FocusInput, `EnterCode-${i + 1}`)
                  }
                }}
                maxLength={1}
                className={styles.input}
                tabIndex={i + 1}
                inputClassName={classnames({
                  [styles.active]: digits.get(i),
                })}
                onEnter={disabled ? undefined : submit}
              />
            )
          })}
      </div>
      <Button
        className={styles.button}
        type={disabled ? ButtonType.Disabled : ButtonType.Primary}
        size={ButtonSize.Large}
        onClick={submit}
      >
        {loading ? "Fetching..." : "Next"}
      </Button>
      <Button
        className={styles.usePassword}
        onClick={onUsePassword}
        size={ButtonSize.Small}
        type={ButtonType.Subtle}
      >
        Use a password instead?
      </Button>
    </div>
  )
}

export default EnterCode
