import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import styles from "./IncidentDetail.module.css"
import history, { constructTo } from "utils/history"
import {
  getIncidentsById,
  getIncidentStreams,
  getIncidentNotifications,
  submitUpdateApproval,
  getIncidentContentImages,
} from "api/incident"
import { previewNotification } from "api/notification"
import { first, groupBy, values, round } from "lodash"
import TextInput from "components/Form/TextInput"
import Checkbox from "components/Form/Checkbox"
import PSNContext, { PSN } from "components/Contexts/PSNContext"
import classnames from "classnames"
import Updates from "./Updates/Updates"
import FadeIn from "components/Animations/FadeIn"
import uuid from "uuid"
import {
  initialRevId,
  isIncidentInsidePolygon,
  getClosestPolygonFromIncident,
} from "utils/incident"
import useBlurListener from "components/Hooks/useBlurListener"
import { Props as Marker } from "components/Map/Markers/Marker"
import { Map } from "immutable"
import emitter, { EventType } from "emitter"
import { getMarkerFromIncident } from "utils/map"
import { timer } from "rxjs"
import { Stream, Notification, IncidentContentImage } from "types/incident"
import ConfirmationModal, {
  Props as ModalProps,
} from "components/Modals/ConfirmationModal"
import { ClearanceLevel } from "types/psn"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import LevelBadge from "components/Badges/LevelBadge"
import Body, { BodyType } from "components/Texts/Body"
import { Link, useLocation } from "react-router-dom"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import Approval from "./Approval/Approval"

interface Props {
  id: string
}

const ANIMATION_DURATION = 200
const ANIMATION_DELAY = 200
const IncidentDetail: React.FC<Props & { psn: PSN }> = ({ id, psn }) => {
  const [incident, setIncident] = useState()
  const [focused, setFocused] = useState(false)
  const [update, setUpdate] = useState()
  const [sendNotif, setSendNotif] = useState()
  const [numNotified, setNumNotified] = useState(0)
  const [canUpdate, setCanUpdate] = useState(false)
  const [streams, setStreams] = useState<Stream[]>([])
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [images, setImages] = useState<IncidentContentImage[]>([])
  const [modalProps, setModalProps] = useState<ModalProps>()
  const updateEl = useRef(null)
  const getIncident = useCallback(() => {
    getIncidentsById([id]).subscribe(([{ data }]) => {
      const i = first(data.incidents)
      if (i) {
        const polys = Object.keys(psn.polygons).map(id => ({
          ...psn.polygons[id],
          id,
        }))
        const closest = getClosestPolygonFromIncident(i, polys)
        setIncident({
          ...i,
          polyId: closest ? closest.id : undefined,
          polyName: closest ? psn.polygons[closest.id].name : undefined,
          distanceFrom: closest ? closest.distance : undefined,
        })
      }
    })
  }, [id, psn.polygons])
  const getStreams = useCallback(() => {
    getIncidentStreams(id, false).subscribe(({ data }) => {
      setStreams(data)
    })
  }, [id])
  const getNotifications = useCallback(() => {
    getIncidentNotifications(id).subscribe(
      ({ data: { notifications: notifs } }) => {
        const groupedNotifs = groupBy(
          notifs.filter(n => !!n.user_radius || !!n.geography_type),
          n => n.created_at,
        )

        setNotifications(
          values(groupedNotifs).map(n => {
            const sorted = n.sort((a, b) => b.users_targeted - a.users_targeted)

            return first(sorted)
          }) as Notification[],
        )
      },
    )
  }, [id])

  const getImages = useCallback(() => {
    getIncidentContentImages(id).subscribe(({ data }) => {
      setImages(data)
    }, console.error)
  }, [id])

  let level: ClearanceLevel | undefined
  const location = useLocation()

  useEffect(() => {
    const subscription = timer(0, 5000).subscribe(() => {
      getIncident()
      getStreams()
      getNotifications()
      getImages()
    })

    return (): void => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [getIncident, getNotifications, getStreams, id])

  useEffect(() => {
    if (incident) {
      let markers = Map<string, Marker>()
      markers = markers.set(
        incident.id,
        getMarkerFromIncident(incident, psn.id),
      )
      emitter.emit(EventType.SetMarkers, markers)
      emitter.emit(EventType.SetFocusedMarker, undefined)
      emitter.emit(EventType.SetCenter, {
        lat: incident.location.lat,
        lng: incident.location.lng,
      })
      emitter.emit(EventType.SetDraggable, false)
      previewNotification(psn.id, incident).subscribe(({ data }) => {
        setNumNotified(data)
      })
    }
  }, [incident, psn.id])

  useEffect(() => {
    const num =
      incident &&
      values(psn.polygons).filter(poly =>
        isIncidentInsidePolygon(incident, poly),
      ).length
    setCanUpdate(!!num)
  }, [incident, psn.polygons])

  useBlurListener(updateEl.current, () => setFocused(false))

  if (!incident) {
    return null
  }

  if (incident.clearanceLevel) {
    level = psn.levels.find(l => l.level === incident.clearanceLevel)
  }

  const socialUpdateText =
    incident.updates &&
    incident.updates[0] &&
    incident.updates[0].revisions &&
    incident.updates[0].revisions[0] &&
    incident.updates[0].revisions[0].text

  return (
    <Fragment>
      <div className={styles.top}>
        <Button
          type={ButtonType.Cancel}
          size={ButtonSize.Medium}
          onClick={(): void => {
            const s = location.state as any
            if (s && s.from && s.from.match(window.location.host)) {
              history.goBack()
            } else {
              history.push(`/${psn.id}`)
            }
          }}
        />
        <div className={styles.heading}>
          <h1>{incident.title}</h1>
          {!!incident.psnId && (
            <Link to={constructTo(`/${psn.id}/incidents/${incident.id}/edit`)}>
              <Button type={ButtonType.Subtle} size={ButtonSize.Small}>
                Edit
              </Button>
            </Link>
          )}
          <FacebookShareButton
            url={`https://citizen.com/${incident.id}`}
            quote={socialUpdateText || "Connect and stay safe."}
            hashtag={"#Citizen"}
          >
            <FacebookIcon size={28} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://citizen.com/${incident.id}`}
            title={socialUpdateText || "Connect and stay safe."}
            hashtags={["Citizen"]}
          >
            <TwitterIcon size={28} round />
          </TwitterShareButton>
        </div>
        <Body className={styles.location} type={BodyType.Regular}>
          {incident.location.location}
          {!!incident.polyName &&
            ` ·${
              incident.distanceFrom
                ? ` ${round(incident.distanceFrom, 2)} mi from`
                : ""
            } ${incident.polyName}`}
        </Body>
        {level && <LevelBadge className={styles.badge} {...level} />}
      </div>
      <div className={styles.container}>
        <Updates
          id={incident.id}
          updates={incident.updates}
          streams={streams}
          notifications={notifications}
          images={images}
        />
        <Approval incident={incident} />
      </div>

      <div className={styles.bottom} ref={updateEl}>
        <TextInput
          placeholder='Give an update...'
          onFocus={(): void => setFocused(true)}
          onChange={(value): void => setUpdate(value)}
          value={update}
        />
        {focused && (
          <FadeIn duration={ANIMATION_DURATION} delay={ANIMATION_DELAY}>
            <div
              className={classnames(styles.action, {
                [styles.open]: focused,
              })}
              style={{
                animationDuration: `${ANIMATION_DURATION}ms`,
              }}
            >
              <Checkbox
                className={styles.checkbox}
                description={`Send a notification to ${numNotified} ${
                  level ? psn.name : ""
                } ${level ? level.plural.toLowerCase() : "Citizen Users"}.`}
                onChange={(checked: boolean): void => setSendNotif(checked)}
              />
              <Button
                type={ButtonType.Primary}
                size={ButtonSize.Medium}
                onClick={(): void => {
                  if (update) {
                    setModalProps({
                      description: sendNotif
                        ? `Confirming will update incident details and notify ${numNotified} ${
                            level ? level.plural : "people"
                          } at ${psn.name}.`
                        : `Confirming will update incident details at ${psn.name}.`,
                      onConfirm: () => {
                        submitUpdateApproval({
                          text: update,
                          title: incident.title,
                          location: incident.location,
                          id: uuid(),
                          incidentId: incident.id,
                          occurredAt: new Date(),
                          updateSequence: 0,
                          rootRevId: initialRevId(incident),
                          psnId: psn.id,
                          source: incident.psnId ? undefined : "psn",
                        })

                        setUpdate("")
                        getIncident()
                        setModalProps(undefined)
                      },
                      onCancel: () => setModalProps(undefined),
                      title: "Confirm Add Verified Update",
                    })
                  }
                }}
              >
                Send
              </Button>
            </div>
          </FadeIn>
        )}
      </div>

      {modalProps && <ConfirmationModal {...modalProps} />}
    </Fragment>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <IncidentDetail {...props} psn={psn} />
        }
      }}
    </PSNContext.Consumer>
  )
}

export default Wrapper
