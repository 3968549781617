import React from "react"
import classnames from "classnames"
import Container from "components/Common/Container"
import Body, { BodyType } from "components/Texts/Body"
import styles from "./GSuiteLogin.module.css"
import Spacing from "components/Common/Spacing"
import GoogleLoginButton from "./GoogleLoginButton"

interface Props {
  className?: string
}

const GSuiteLogin: React.FC<Props> = ({ className }) => {
  return (
    <Container
      column
      className={classnames(styles.container, className)}
      top={15}
      bottom={15}
      right={20}
      topAlign
    >
      <Spacing bottom={10}>
        <img src='/google-logo.svg' alt='logo' className={styles.image} />
      </Spacing>
      <h3>Add members with Google</h3>
      <Body type={BodyType.Regular} className={styles.body}>
        Sign in with Google to add members of your organization from your
        contacts
      </Body>
      <Spacing top={16}>
        <GoogleLoginButton />
      </Spacing>
    </Container>
  )
}

export default GSuiteLogin
