import React, { FunctionComponent, useRef } from "react"
import usePopup from "components/Hooks/usePopup"
import styles from "./PolygonMenuPopup.module.css"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  top?: number
  onClick: (option: MenuOption) => void
  onHide: () => void
}

export enum MenuOption {
  SetZoom = "Set Default Zoom",
}

const PolygonMenuPopup: FunctionComponent<Props> = ({
  onHide,
  top,
  onClick,
}) => {
  const popupRef = useRef(null)
  const options = [MenuOption.SetZoom]
  usePopup(popupRef, onHide, top !== undefined)

  if (top === undefined) {
    return null
  }

  return (
    <div className={styles.container} ref={popupRef} style={{ top }}>
      {options.map(option => {
        return (
          <button
            key={option}
            className={styles.option}
            onClick={(): void => onClick(option)}
          >
            <Body type={BodyType.Regular}>{option}</Body>
          </button>
        )
      })}
    </div>
  )
}

export default PolygonMenuPopup
