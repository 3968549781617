import { from } from "rxjs"
import { OptimusAPI, getAuthToken } from "api"

export const getLoginUrl = (psn: string) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${psn}/gsuite/login`, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const sendAuthCode = (authCode: string, stateToken: string) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${stateToken}/gsuite/auth_code`,
      {
        authCode,
      },
      {
        headers: { "x-access-token": citizenJWT },
      },
    ),
  )
}

export const syncGSuite = (psn: string) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psn}/gsuite/sync`,
      {},
      {
        headers: { "x-access-token": citizenJWT },
      },
    ),
  )
}
