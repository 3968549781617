import { values, first } from "lodash"
import { Polygon, UserCounts } from "types/psn"
import { Coords } from "google-map-react"
import { polygon, inside, point } from "turf"
import { Incident } from "types/incident"

export const getUserCounts = (polygons: {
  [id: string]: Polygon
}): UserCounts => {
  const polys = values(polygons)
  let userCount1 = 0
  let userCount7 = 0
  let userCount30 = 0
  polys.forEach(p => {
    userCount1 += p.userCount1
    userCount7 += p.userCount7
    userCount30 += p.userCount30
  })

  return {
    userCount1,
    userCount7,
    userCount30,
  }
}

export const getPolygonFromCoords = (
  { lat, lng }: Coords,
  p: { [id: string]: Polygon },
): string | undefined => {
  const matches = Object.keys(p).filter(pId => {
    const poly = p[pId].polygon

    return inside(
      point([lat, lng]),
      polygon(poly.map(ring => ring.map(([lng, lat]) => [lat, lng]))),
    )
  })

  return first(matches)
}

export const getPolygonIncidentCount = (
  incidents: Incident[],
  poly: Polygon,
): number => {
  const matched = incidents.filter(i => {
    return inside(
      point([i.location.lat, i.location.lng]),
      polygon(poly.polygon.map(ring => ring.map(([lng, lat]) => [lat, lng]))),
    )
  })

  return matched.length
}
