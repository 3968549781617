import React, { Fragment, useState } from "react"
import Container from "components/Common/Container"
import Body, { BodyType } from "components/Texts/Body"
import styles from "./Steps.module.css"
import Spacing from "components/Common/Spacing"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"

interface Props {
  onNext: () => void
  onPrev: () => void
}

const Step3: React.FC<Props> = ({ onNext, onPrev }) => {
  const [syncing, setSyncing] = useState(false)

  return (
    <Fragment>
      <Container column className={styles.body} leftJustify>
        <Body type={BodyType.Regular}>
          We will sync all of your Google contacts into SafeTrace Enterprise.
          They will be synced into the default product experience in the Citizen
          mobile app and count towards your license.
        </Body>
        <Spacing top={2}>
          <Body type={BodyType.Regular}>
            Please regularly sync contacts in order to keep your employee list
            up to date. We will be adding ongoing sync soon
          </Body>
        </Spacing>
      </Container>
      <Container rightJustify top={10} className={styles.body} fullWidth>
        <Spacing right={4}>
          <Button
            type={ButtonType.Subtle}
            size={ButtonSize.Medium}
            onClick={onPrev}
          >
            Back
          </Button>
        </Spacing>
        <Button
          type={ButtonType.Primary}
          size={ButtonSize.Medium}
          onClick={() => {
            setSyncing(true)
            onNext()
          }}
        >
          {syncing ? "Syncing..." : "Sync users"}
        </Button>
      </Container>
    </Fragment>
  )
}

export default Step3
