import React, { Fragment, useEffect, useState, useContext } from "react"
import Container from "components/Common/Container"
import Body, { BodyType } from "components/Texts/Body"
import styles from "./Steps.module.css"
import Spacing from "components/Common/Spacing"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import { getLoginUrl } from "api/gsuite"
import PSNContext from "components/Contexts/PSNContext"

const Step0 = () => {
  const [url, setUrl] = useState<string>()
  const psn = useContext(PSNContext)

  useEffect(() => {
    if (psn) {
      getLoginUrl(psn.id).subscribe(({ data }) => {
        setUrl(data.url)
      })
    }
  }, [psn])

  return (
    <Fragment>
      <Container column className={styles.body} leftJustify>
        <Body type={BodyType.Regular}>
          You will be asked by Google to confirm you allow Citizen SafeTrace to
          import your users.
        </Body>
      </Container>
      <Container rightJustify top={10} className={styles.body} fullWidth>
        <Button
          type={ButtonType.Primary}
          size={ButtonSize.Medium}
          onClick={() => {
            if (url) {
              window.location.href = url
            }
          }}
        >
          Connect to Google
        </Button>
      </Container>
    </Fragment>
  )
}

export default Step0
