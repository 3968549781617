export enum IncidentSeverity {
  Red = "red",
  Yellow = "yellow",
}

export interface Incident {
  id: string
  title: string
  location: Location
  level: number
  boosted: boolean
  deleted: boolean
  flagged: boolean
  featuredStreamImage: string
  featuredStreamImageCropY: number
  preferredStreamImage?: string
  preferredStreamId?: string
  shareImageLive?: string
  shareImageText: string
  recapStreamId: string
  updates: Update[]
  liveStreamers: LiveStreamer[] | null
  stats: IncidentStats
  notifRadiusM: number
  severity: IncidentSeverity
  psnId: string
  clearanceLevel: number
  categories: string
}

export interface IncidentFilter {
  traceBackHours: string
  incidentType: string
  incident_lv0: boolean
  incident_lv1: boolean
  incident_lv2: boolean
  location: string
  following: boolean
  incident_category: string
}

export interface Location {
  address: string
  location: string
  neighborhood: string
  lat: number
  lng: number
  police?: string
  cityCode?: string
}

export interface Update {
  id: string
  revisions: Revision[]
}

export interface Revision {
  id: string
  updateId: string
  updateSequence: number
  incidentId: string
  title?: string
  text?: string
  location?: Location
  level?: number
  authorId: string
  authorUsername: string
  authorName: string
  authorThumbnailURL: string
  authorMod: number
  visibility: number
  occurredAt: string
  source: string
  createdAt: string
  updatedAt: string
  clips: Clip[]
  acknowledgedAt: string | null
  fromAPI?: boolean
}

export interface Clip {
  id: string
  wavURL: string
  url: string
  time: string
  channelHeader: string
  spectrogram: string
  durationMs: number
  flaggers: string[]
}

export interface IncidentStats {
  angers: number
  chats: number
  hearts: number
  streamViews: number
  streamsConfirmed: number
  thanks: number
  usersNotifiedUnique: number
  views: number
  whoas: number
}

export interface LiveStreamer {
  cs: number
  ts: number
  username: string
  videoStreamId: string
  hlsDone: boolean
  userId: string
  doneTs: number
  confirmed: boolean
  verifiedLive: boolean
  views: number
  imageCount: number | null
  autoImageUrl: string | null
  autoImageLabels: string | null
}

interface StreamStats {
  whoas: number
  views: number
  downloads: number
}

export interface Stream {
  blocked: false
  blockedReason: string | null
  boostOrder: number
  broadcastDone: boolean
  confirmed: boolean
  createdAt: string
  durationSeconds: number
  hlsDone: boolean
  hlsDoneReason: string
  hlsDoneTiming: { [reason: string]: number }
  hlsLiveImageCount: number
  hlsLiveImageUrl: string
  hlsLiveThumbUrl: string
  hlsLiveUrl: string
  hlsLiveWaterMarkedCroppedUrl: string
  hlsLiveWaterMarkedUrl: string
  hlsMp4Done: boolean
  hlsReady: boolean
  hlsVodDone: boolean
  hlsVodMp4Url: string
  hlsVodMp4WaterMarkedUrl: string
  hlsVodUrl: string
  hlsVodWaterMarkedCroppedUrl: string
  hlsVodWaterMarkedUrl: string
  id: string
  incidentId: string
  mediaPackageUrl: string
  originalDurationSeconds: 2.705
  originalHlsVodUrl: string
  private: boolean
  removedFromIncident: boolean
  rtmpUrl: string
  rtmpWaterMarkedUrl: string
  screenshotFileName: string | null
  stats: StreamStats
  status: string
  trimDuration: number | null
  trimStart: number | null
  updatedAt: string
  userAvatarThumbURL: string
  userId: string
  username: string
  verifiedLive: boolean
  wowzaUrl: string
}

interface Transcriber {
  transcriber_id: string
  email: string
  username: string
  name: string
}

export interface Notification {
  created_at: number
  ctr: number
  dry_run: boolean
  engaged: boolean
  geography_code: string
  geography_name: string
  geography_type: string
  id: string
  incident_id: string
  message: string
  notification_id: string
  push_type: string
  renotify: boolean | null
  sound: string
  targeting_type: string
  title: string
  transcriber: Transcriber
  trigger_type: string
  user_label: string
  user_radius: number | null
  users_received: number
  users_targeted: number
  latitude: string
  longitude: string
  magic_sauce_radius: number | null
  min_radius: number | null
  safety_radius: number | null
}

export interface IncidentMeta {
  polyId?: string
  polyName?: string
  distanceFrom?: number
}

export interface IncidentTag {
  id: number
  name: string
  display_name: string
  type: string
  user_id: string | null
  sensitive: boolean
}

export interface IncidentReview {
  id: string
  title?: string | null
  text?: string
  level?: number
  location?: string | null
  address?: string | null
  neighborhood?: string | null
  cityCode?: string | null
  occurredAt: Date
  psnId?: string
  clearanceLevel?: number
  incidentId?: string
  source?: string
  status?: string
  lat?: number
  lng?: number
}

export interface IncidentUpdateRequest {
  id: string
  title?: string | null
  text?: string
  level?: number
  location?: Location | null
  occurredAt: Date
  psnId?: string
  clearanceLevel?: number
  updateSequence?: number
  rootRevId?: string
  incidentId?: string
  source?: string
  notifRadius?: number
  imageUrl?: string
}

export interface slackPayload {
  text: string
  title: string
  location: Location
  incident_id: string
}

export interface IncidentContentImage {
  id: string
  imageUrl: string
  thumbUrl: string
  verticalThumbURL: string
  createdAt: string
  updatedAt: string
}
