import React from "react"
import styles from "./VolumeButton.module.css"

interface Props {
  onClick: () => void
  muted: boolean
}

const VolumeButton: React.FC<Props> = ({ onClick, muted }) => {
  return (
    <div className={styles.mute} onClick={onClick}>
      <img src={`/icons/volume-${!muted ? "on" : "off"}.svg`} alt='mute' />
    </div>
  )
}

export default VolumeButton
