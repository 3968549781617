import React from "react"
import {
  Update as UpdateType,
  Stream,
  Notification,
  IncidentContentImage,
} from "types/incident"
import styles from "./Updates.module.css"
import Update from "./Update"
import { first, sortBy } from "lodash"
import StreamUpdate from "./StreamUpdate"
import NotificationUpdate from "./NotificationUpdate"

interface Props {
  id: string
  updates: UpdateType[]
  streams: Stream[]
  notifications: Notification[]
  images?: IncidentContentImage[]
}

enum Type {
  Clip = "clip",
  Stream = "stream",
  Notification = "notification",
  Image = "image",
}

interface UpdateRow {
  time: Date
  model: UpdateType | Stream | Notification | IncidentContentImage
  type: Type
}

const Updates: React.FC<Props> = ({
  id,
  updates,
  streams,
  notifications,
  images,
}) => {
  const allUpdates: UpdateRow[] = []

  updates.forEach(up => {
    const rev = first(up.revisions)

    if (rev) {
      allUpdates.push({
        time: new Date(rev.occurredAt),
        model: up,
        type: Type.Clip,
      })
    }
  })

  streams.forEach(stream => {
    allUpdates.push({
      time: new Date(stream.createdAt),
      model: stream,
      type: Type.Stream,
    })
  })

  notifications.forEach(notif => {
    allUpdates.push({
      time: new Date(notif.created_at * 1000),
      model: notif,
      type: Type.Notification,
    })
  })

  images &&
    images.forEach(image => {
      allUpdates.push({
        time: new Date(image.createdAt),
        model: image,
        type: Type.Image,
      })
    })

  return (
    <div className={styles.container}>
      {sortBy(allUpdates, [
        (up: UpdateRow) => -up.time,
        (up: UpdateRow) => up.model.id,
      ]).map((up: UpdateRow) => {
        const update = up.model
        switch (up.type) {
          case Type.Clip:
            return <Update update={update as UpdateType} key={update.id} />
          case Type.Stream:
            return <StreamUpdate stream={update as Stream} key={update.id} />
          case Type.Notification:
            return (
              <NotificationUpdate
                notification={update as Notification}
                key={update.id}
              />
            )
          case Type.Image:
            const image = update as IncidentContentImage
            return <img width='250px' src={image.imageUrl} />
          default:
            return null
        }
      })}
    </div>
  )
}

export default Updates
