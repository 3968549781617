import { useEffect, useRef } from "react"
import { List, Map as ImmutableMap } from "immutable"
import Polygon, { PolygonType, PolygonOptions } from "components/Map/Polygon"
import { Coords } from "google-map-react"

const usePolygons = (
  map: any,
  polygons: ImmutableMap<string, List<Coords>>,
  options?: PolygonOptions,
): void => {
  const polygonsDrawn = useRef(ImmutableMap<string, PolygonType>())

  useEffect(() => {
    const clearPolygons = (polys: ImmutableMap<string, PolygonType>): void => {
      polys.valueSeq().forEach((p: PolygonType) => p.setMap(null))
    }
    const generatePolygonMap = (
      polys: ImmutableMap<string, List<Coords>>,
    ): ImmutableMap<string, PolygonType> => {
      let m = ImmutableMap<string, PolygonType>()

      polys.forEach((coords, key) => {
        m = m.set(key, Polygon(map, coords, options))
      })

      return m
    }

    if (map) {
      clearPolygons(polygonsDrawn.current)
      polygonsDrawn.current = generatePolygonMap(polygons)
    }

    return (): void => {
      if (polygonsDrawn.current) {
        clearPolygons(polygonsDrawn.current)
      }
    }
  }, [map, options, polygons])
}

export default usePolygons
