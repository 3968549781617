import React, { useState, useEffect, FunctionComponent } from "react"
import styles from "./Dropdown.module.css"
import classnames from "classnames"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  options: string[]
  selectedIndex: number
  onChange?: (index: number) => void
  className?: string
  children?: any
  renderOption?: (option: string, index: number) => any
}

const Dropdown: FunctionComponent<Props> = ({
  selectedIndex,
  options,
  onChange,
  className,
  renderOption,
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [selectedIndex])

  return (
    <div
      className={classnames(styles.dropdown, className, {
        [styles.open]: open,
      })}
    >
      <button
        className={styles.selected}
        onClick={(): void => {
          setOpen(!open)
        }}
      >
        <Body className={styles.selectedItem} type={BodyType.Regular}>
          {options[selectedIndex]}
        </Body>
        <div>
          <img src='/icons/down-arrow.svg' alt='open' />
        </div>
      </button>
      {open && (
        <div className={styles.options}>
          {options.map((option, i) =>
            renderOption ? (
              renderOption(option, i)
            ) : (
              <button
                className={styles.option}
                key={option}
                onClick={onChange ? (): void => onChange(i) : undefined}
              >
                <Body type={BodyType.Regular}>{option}</Body>
              </button>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export default Dropdown
