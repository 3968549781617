import React, { useState, useEffect } from "react"
import { Member, getMembers, LIMIT, updateMemberSafePass } from "api/member"
import { Header } from "components/Table/Table"
import dayjs from "dayjs"
import { timer, Subscription, of } from "rxjs"
import { getSymptomCountText } from "./SymptomTrackingModal/SymptomTrackingModal.hook"
import { getSafetraceLocations } from "api/safetrace"
import Dropdown from "components/Form/Dropdown"
import styles from "./MembersPage.module.css"
import { concatMap } from "rxjs/operators"

const ALL_LOCATIONS = "All locations"

enum SafePassStatus {
  Approved = "approved",
  Disabled = "disabled",
}

const useMembersPage = (psn?: string, isAdmin: boolean = false) => {
  const getRowData = (data: any) => ({
    ...data,
    tracing: data.tracingEnabled ? "enabled" : "disabled",
  })
  const [members, setMembers] = useState<Member[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [testingModal, setTestingModal] = useState<any>()
  const [symptomTrackingModal, setSymptomTrackingModal] = useState<any>()
  const [locations, setLocations] = useState<string[]>([ALL_LOCATIONS])
  const [locationFilter, setLocationFilter] = useState<number>(0)

  const fetchMembers = (
    psn: string,
    limit?: number,
    o = 0,
    onFetched?: () => void,
    locations?: string[],
  ): void => {
    getMembers(psn, limit, o, locations).subscribe(({ data }) => {
      setMembers(data.statuses.map(getRowData))
      setOffset(o)
      setTotal(data.totalRowCount)
      onFetched && onFetched()
    })
  }

  useEffect(() => {
    if (psn) {
      getSafetraceLocations(psn).subscribe(({ data }) => {
        setLocations([ALL_LOCATIONS].concat(data.locations))
      })
    }
  }, [psn])

  useEffect(() => {
    let subscription: Subscription
    if (psn) {
      subscription = timer(0, 60000).subscribe(() =>
        fetchMembers(
          psn,
          undefined,
          offset,
          undefined,
          locations[locationFilter] === ALL_LOCATIONS
            ? undefined
            : [locations[locationFilter]],
        ),
      )
    }

    return () => {
      if (subscription) {
        try {
          subscription.unsubscribe()
        } catch (e) {}
      }
    }
  }, [psn, offset, locationFilter, locations])

  useEffect(() => {
    if (psn) {
      fetchMembers(
        psn,
        undefined,
        offset,
        undefined,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }, [locationFilter, psn])

  const headers: Header[] = [
    {
      display: "Member",
      property: isAdmin ? "name" : "id",
      width: 200,
    },
    {
      display: "Citizen User",
      property: "pending",
      width: 100,
      getDisplay: data => {
        if (data.pending) {
          return "No"
        } else {
          return "Yes"
        }
      },
      getStyling: data => (data ? { color: "#FF2640" } : undefined),
    },
    {
      display: "Tracing Status",
      property: "tracing",
      width: 150,
      getStyling: data =>
        data === "disabled" ? { color: "#FF2640" } : undefined,
    },
    {
      display: "Potential Covid-19 Exposure",
      property: "traceStatus.exposure_status",
      width: 150,
      getDisplay: data => {
        if (data.traceStatus.exposure_status) {
          return "exposed"
        } else {
          return "none"
        }
      },
    },
    {
      display: "Meaningful Contact",
      property: "traceStatus.weekly_aggregate.meaningful",
      width: 150,
      getDisplay: data => {
        return data.traceStatus.weekly_aggregate.meaningful || "none"
      },
    },
    {
      display: "Symptomatic",
      property: "symptomCount",
      getDisplay: data => {
        return getSymptomCountText(data.symptomCount)
      },
      onClick: (data: any) => {
        setSymptomTrackingModal(data)
      },
      width: 150,
    },
    {
      display: "Testing Status",
      property: "testStatus",
      getDisplay: data => {
        if (
          !data.testStatus ||
          !data.testStatus.result ||
          dayjs(data.testStatus.testDate).isBefore(dayjs().subtract(14, "day"))
        ) {
          return "no test"
        } else {
          return `${data.testStatus.result}, ${dayjs(
            data.testStatus.testDate,
          ).format("MM/DD/YY")}`
        }
      },
      onClick: (data: any) => {
        setTestingModal(data)
      },
      getStyling: data =>
        data &&
        data.result === "positive" &&
        dayjs(data.testDate).isAfter(dayjs().subtract(14, "day"))
          ? { color: "#FF2640" }
          : undefined,
      width: 150,
    },
    {
      display: "Safepass Status",
      property: "safepassApproved",
      getDisplay: data =>
        data.safepassApproved
          ? SafePassStatus.Approved
          : SafePassStatus.Disabled,
      // renderItem: isAdmin
      //   ? // eslint-disable-next-line react/display-name
      //     data => {
      //       const options = [SafePassStatus.Approved, SafePassStatus.Disabled]

      //       if (data.pending) {
      //         return null
      //       }

      //       return (
      //         <Dropdown
      //           options={options}
      //           selectedIndex={options.indexOf(
      //             data.safepassApproved
      //               ? SafePassStatus.Approved
      //               : SafePassStatus.Disabled,
      //           )}
      //           className={styles.safePassDropdown}
      //           onChange={index => {
      //             if (psn) {
      //               updateMemberSafePass(
      //                 psn,
      //                 data.id,
      //                 options[index] === SafePassStatus.Approved,
      //               )
      //                 .pipe(
      //                   concatMap(() => {
      //                     fetchMembers(
      //                       psn,
      //                       undefined,
      //                       offset,
      //                       undefined,
      //                       locations[locationFilter] === ALL_LOCATIONS
      //                         ? undefined
      //                         : [locations[locationFilter]],
      //                     )
      //                     return of([])
      //                   }),
      //                 )
      //                 .subscribe()
      //             }
      //           }}
      //         />
      //       )
      //     }
      //   : undefined,
      width: 200,
    },
  ]

  const onNext = (): void => {
    if (psn) {
      fetchMembers(
        psn,
        undefined,
        offset + LIMIT,
        undefined,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const onPrev = (): void => {
    if (psn) {
      fetchMembers(
        psn,
        undefined,
        offset - LIMIT,
        undefined,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const onModalClose = (setter: (val: any) => void) => {
    return (refetch?: boolean) => {
      if (refetch && psn) {
        fetchMembers(psn, undefined, offset, () => setter(undefined))
      } else {
        setter(undefined)
      }
    }
  }

  const onPageClick = (page: number): void => {
    if (psn) {
      fetchMembers(
        psn,
        undefined,
        LIMIT * (page - 1),
        undefined,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const onLocationSelect = (index: number) => {
    setLocationFilter(index)
  }

  return {
    members,
    headers,
    onNext: offset + LIMIT < total ? onNext : undefined,
    onPrev: offset ? onPrev : undefined,
    pages: Math.ceil(total / LIMIT),
    currentPage: Math.ceil((offset + 1) / LIMIT),
    testingModal,
    onTestingModalClose: onModalClose(setTestingModal),
    symptomTrackingModal,
    onSymptomTrackingModalClose: onModalClose(setSymptomTrackingModal),
    onPageClick,
    locations,
    onLocationSelect,
    locationFilter,
  }
}

export default useMembersPage
