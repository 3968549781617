import React, { FunctionComponent } from "react"
import styles from "./Body.module.css"
import classnames from "classnames"

export enum BodyType {
  Regular = "regular",
  Bold = "bold",
}

interface Props {
  type: BodyType
  className?: string
  style?: any
}

const Body: FunctionComponent<Props> = ({
  type,
  children,
  className,
  style,
}) => {
  return (
    <p
      style={style}
      className={classnames(styles.body, styles[type.toLowerCase()], className)}
    >
      {children}
    </p>
  )
}

export default Body
