import React, { useState } from "react"
import styles from "./GetUser.module.css"
import { isMobilePhone } from "validator"
import NumberInput from "components/Form/NumberInput"
import { PhoneInfo } from "types/user"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  onSubmit: (phone: string) => void
}

const GetUser: React.FC<Props> = ({ onSubmit }) => {
  const [phone, setPhone] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const submit = (): void => {
    if (!isMobilePhone(phone)) {
      setError("Please enter a valid phone number.")
      return
    }

    onSubmit(phone)
  }

  return (
    <div className={styles.container}>
      <Body type={BodyType.Regular} className={styles.text}>
        Enter your phone number to begin
      </Body>
      <NumberInput
        placeholder='(XXX) XXX-XXXX'
        onChange={(val: string): void => {
          if (error) {
            setError(error)
          }
          setPhone(val)
        }}
        className={styles.input}
        error={error}
        onEnter={submit}
      />
      <Button
        className={styles.button}
        type={!phone ? ButtonType.Disabled : ButtonType.Primary}
        size={ButtonSize.Large}
        onClick={submit}
      >
        {loading ? "Fetching..." : "Next"}
      </Button>
    </div>
  )
}

export default GetUser
