import React, { useEffect, useState } from "react"
import styles from "./SearchInput.module.css"
import TextInput from "./TextInput"
import classnames from "classnames"
import inputs from "styles/inputs.module.css"

interface Props {
  onSearch: (value: string) => void
  placeholder?: string
}

const SearchInput: React.FC<Props> = ({ placeholder, onSearch }) => {
  const [value, setValue] = useState("")
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    onSearch(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div
      className={classnames(styles.container, {
        [inputs.focused]: focused,
        [styles.focused]: focused,
      })}
    >
      <div className={styles.icon}>
        <img src='/icons/search.svg' alt='search' />
      </div>
      <TextInput
        className={styles.inputContainer}
        inputClassName={classnames(styles.input)}
        placeholder={placeholder || "Search..."}
        onChange={(val): void => setValue(val)}
        onFocus={(): void => setFocused(true)}
        onBlur={(): void => setFocused(false)}
        allowClear
      />
    </div>
  )
}

export default React.memo(SearchInput)
