export default {
  data: process.env.REACT_APP_OPTIMUS_HOST,
  device: process.env.REACT_APP_DEVICE_HOST,
  lighthouse: process.env.REACT_APP_LIGHTHOUSE_HOST,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  production:
    process.env.NODE_ENV === "production" &&
    window.location.hostname === "protect.citizen.com",
  profileBaseURL: "https://storage.googleapis.com/citizen-profile-pictures/",
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
}
