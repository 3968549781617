import React, { FunctionComponent } from "react"
import classnames from "classnames"
import styles from "./Marker.module.css"
import { Incident } from "types/incident"
import emitter, { EventType } from "emitter"
import { isEqual } from "lodash"
import history, { shouldReplace } from "utils/history"
import { Polygon } from "types/psn"
import Body, { BodyType } from "components/Texts/Body"

export interface Props {
  fill?: string
  lat: number
  lng: number
  type: MarkerType
  id: string
  style?: any
  focused: boolean
  model?: Incident | Polygon
  link?: string
}

export enum MarkerType {
  Incident = "incident",
  User = "user",
  Pin = "pin",
  UserCount = "user count",
}

const Marker: FunctionComponent<Props> = ({
  fill,
  type,
  style = {},
  focused,
  model,
  link,
}) => {
  const markerStyle: any = { backgroundColor: fill }

  if (type === MarkerType.User) {
    style.background = fill
  }

  switch (type) {
    case MarkerType.UserCount:
      return (
        <div className={styles.wrapper}>
          <div className={classnames(styles.container, styles.userCount)}>
            <div className={styles.userCountLeft}>
              <img src='/icons/user-count-left.svg' alt='notch' />
            </div>
            <Body type={BodyType.Bold}>
              {(model as Polygon).userCount1} people
            </Body>
            <div className={styles.userCountRight}>
              <img src='/icons/user-count-right.svg' alt='notch' />
            </div>
          </div>
        </div>
      )
    case MarkerType.Incident:
      return (
        <button
          className={classnames(styles.container, styles.incident)}
          onMouseEnter={(): void => {
            if (model) {
              emitter.emit(EventType.SetFocusedMarker, (model as Incident).id)
            }
          }}
          onMouseLeave={(): void => {
            if (model) {
              emitter.emit(EventType.SetFocusedMarker, undefined)
            }
          }}
          // style={style}
          onClick={(): void => {
            if (link && !shouldReplace(link)) {
              history.push(link)
            }
          }}
        >
          {focused && (
            <div className={styles.tooltip}>{(model as Incident).title}</div>
          )}
          <div
            className={classnames(styles.marker, styles.incident)}
            style={style}
          >
            <div style={markerStyle} />
          </div>
        </button>
      )
    default:
      return (
        <button
          className={classnames(styles.container, {
            [styles.user]: type === MarkerType.User,
            [styles.pin]: type === MarkerType.Pin,
          })}
          onMouseEnter={(): void => {
            if (model) {
              emitter.emit(EventType.SetFocusedMarker, (model as Incident).id)
            }
          }}
          onMouseLeave={(): void => {
            if (model) {
              emitter.emit(EventType.SetFocusedMarker, undefined)
            }
          }}
          style={style}
          onClick={(): void => {
            if (link && !shouldReplace(link)) {
              history.push(link)
            }
          }}
        >
          {type !== MarkerType.User && focused && (
            <div className={styles.tooltip}>
              {MarkerType.Pin === type
                ? "Drag to location"
                : model
                ? (model as Incident).title
                : ""}
            </div>
          )}
          <div
            className={classnames(styles.marker, {
              [styles.user]: type === MarkerType.User,
              [styles.pin]: type === MarkerType.Pin,
            })}
          >
            {type !== MarkerType.User && <div style={markerStyle} />}
          </div>
        </button>
      )
  }
}

const areEqual = (prevProps: any, nextProps: any): boolean => {
  const compare: string[] = ["fill", "type", "focused", "lat", "lng", "id"]

  if (nextProps.id === "pin") {
    return isEqual(prevProps, nextProps)
  }

  return compare.filter(p => prevProps[p] !== nextProps[p]).length === 0
}

export default React.memo(Marker, areEqual)
