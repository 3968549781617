import React, { Fragment, useEffect, useState, useCallback } from "react"
import styles from "./IncidentUpdate.module.css"
import emitter, { EventType } from "emitter"
import history from "utils/history"
import { flatten, first } from "lodash"
import PSNContext, { PSN } from "components/Contexts/PSNContext"
import { submitUpdateRev, getIncidentsById } from "api/incident"
import { ClearanceLevel } from "types/psn"
import uuid from "uuid"
import { getBoundingBoxOfPolygon } from "utils/map"
import ConfirmationModal, {
  Props as ModalProps,
} from "components/Modals/ConfirmationModal"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import IncidentForm, {
  IncidentFormInputType,
} from "components/Form/IncidentForm/IncidentForm"
import { State as IncidentFormState } from "components/Form/IncidentForm/incidentFormReducer"
import { List } from "immutable"
import { getPolygonFromCoords } from "utils/psn"
import { Incident } from "types/incident"
import { initialRevId } from "utils/incident"

interface Props {
  mapInit: boolean
  id: string
}

const DEFAULT_INCIDENT = {
  title: "",
  notificationToAllPolygons: false,
}

const convertIncidentToIncidentFormState = (
  incident: Incident,
): IncidentFormState => {
  return {
    title: incident.title,
    location: incident.location,
    audience: incident.clearanceLevel,
    notificationToAllPolygons: false,
  }
}

const IncidentUpdate: React.FC<Props & { psn: PSN }> = ({
  mapInit,
  psn,
  id,
}) => {
  const levels: ClearanceLevel[] = [
    {
      level: 0,
      name: "Citizen User",
      plural: "All Citizen Users",
    },
  ]
    .concat(psn.levels)
    .reverse()
  const [incident, setIncident] = useState()
  const [location, setLocation] = useState()
  const [updated, setUpdated] = useState<IncidentFormState>({
    ...DEFAULT_INCIDENT,
    audience: (first(levels) as ClearanceLevel).level,
  })
  const [modalProps, setModalProps] = useState<ModalProps>()
  const getIncident = useCallback(() => {
    getIncidentsById([id]).subscribe(([{ data }]) => {
      setIncident(first(data.incidents))
    })
  }, [id])

  useEffect(() => {
    getIncident()
  }, [getIncident])

  useEffect(() => {
    if (incident) {
      const polyId = getPolygonFromCoords(incident.location, psn.polygons)

      if (polyId) {
        const poly = psn.polygons[polyId]
        const list = List(
          flatten(poly.polygon).map(([lng, lat]) => ({
            lat,
            lng,
          })),
        )
        const bounds = getBoundingBoxOfPolygon(list)
        emitter.emit(EventType.SetBounds, bounds)
        emitter.emit(EventType.SetDraggable, false)
        emitter.emit(EventType.SelectPolygon, polyId)
      }

      setLocation({
        lat: incident.location.lat,
        lng: incident.location.lng,
      })
    }
  }, [incident, mapInit, psn.polygons])

  return (
    <Fragment>
      <div className={styles.top}>
        <Button
          type={ButtonType.Cancel}
          size={ButtonSize.Medium}
          onClick={(): void => {
            setModalProps({
              onConfirm: () => {
                history.push(`/${psn.id}/incidents/${id}`)
              },
              onCancel: () => setModalProps(undefined),
              title: "Confirm",
              description: "Close and lose changes?",
            })
          }}
          className={styles.close}
        />
        <h1>Edit Incident</h1>
      </div>
      {incident && (
        <div className={styles.container}>
          <IncidentForm
            location={location}
            psn={psn}
            mapInit={mapInit}
            onChange={(i): void => setUpdated(i)}
            numNotified={0}
            levels={levels}
            defaultIncident={convertIncidentToIncidentFormState(incident)}
            limitInputs={[
              IncidentFormInputType.Location,
              IncidentFormInputType.Title,
              IncidentFormInputType.Visibility,
              IncidentFormInputType.Update,
              IncidentFormInputType.NotificationToAllPolygons,
            ]}
            className={styles.creationForm}
          />
          <div className={styles.form}>
            <div className={styles.labels}></div>
            <div className={styles.input}>
              <Button
                type={
                  incident.title === updated.title &&
                  updated.location &&
                  incident.location.lat === updated.location.lat &&
                  incident.location.lng === updated.location.lng
                    ? ButtonType.Disabled
                    : ButtonType.Primary
                }
                block
                size={ButtonSize.Large}
                onClick={(): void => {
                  const { title = "", location } = updated
                  setModalProps({
                    onConfirm: () => {
                      submitUpdateRev({
                        id: uuid(),
                        title: incident.title === title ? null : title,
                        location:
                          location &&
                          incident.location.lat === location.lat &&
                          incident.location.lng === location.lng
                            ? null
                            : location,
                        updateSequence: 0,
                        incidentId: incident.id,
                        rootRevId: initialRevId(incident),
                        occurredAt: new Date(),
                        psnId: psn.id,
                        source: incident.psnId ? undefined : "psn",
                      })
                      setModalProps(undefined)
                      history.push(`/${psn.id}/incidents/${incident.id}`)
                    },
                    onCancel: () => setModalProps(undefined),
                    title: "Confirm Update Incident",
                    description: `Confirming will update location and/or title of incident.`,
                  })
                }}
              >
                Update Incident
              </Button>
            </div>
          </div>
        </div>
      )}
      {modalProps && <ConfirmationModal {...modalProps} />}
    </Fragment>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <IncidentUpdate {...props} psn={psn} />
        }
      }}
    </PSNContext.Consumer>
  )
}

export default Wrapper
