import React, { useState } from "react"
import Step0 from "./Steps/Step0"
import Step3 from "./Steps/Step3"
import { syncGSuite } from "api/gsuite"
import history from "utils/history"

const useGSuiteAuthPage = (defaultStep: number = 0, psn?: string) => {
  const [step, setStep] = useState(defaultStep)

  const getHeading = () => {
    switch (step) {
      case 0:
        return "Add members with Google"
      // case 1:
      //   return "Confirm organization"
      // case 2:
      //   return "Which users do you want to sync?"
      case 1:
        return "Ready to go?"
    }
  }

  const getComponent = (): React.FC<any> | undefined => {
    switch (step) {
      case 0:
        return Step0
      // case 1:
      //   return Step1
      // case 2:
      //   return Step2
      case 1:
        return Step3
    }
  }

  const onNext = () => {
    if (step === 1 && psn) {
      syncGSuite(psn).subscribe(() => {
        history.push(`/${psn}/safetrace/organization`)
      })
    }
    setStep(Math.min(step + 1, 1))
  }

  const onPrev = () => {
    setStep(Math.max(0, step - 1))
  }

  return {
    step,
    setStep,
    heading: getHeading(),
    Component: getComponent(),
    onNext,
    onPrev,
  }
}

export default useGSuiteAuthPage
