import React from "react"
import styles from "./StickyBottomBanner.module.css"

interface Props {
  onClick: () => void
  overrideText?: string
}

const StickyBottomBanner: React.FC<Props> = ({ onClick, overrideText }) => {
  return (
    <button className={styles.banner} onClick={onClick}>
      <img src='/icons/scroll-down.svg' alt='down' />
      <span>
        {overrideText ||
          "Scroll to see recent incidents you might have missed."}
      </span>
    </button>
  )
}

export default StickyBottomBanner
