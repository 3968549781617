import React, { useState, useEffect } from "react"
import styles from "./FilterForm.module.css"
import { IncidentFilter } from "types/incident"
import { getIncidentTags } from "api/incident"

interface Props {
  onFilter: (filterData: IncidentFilter) => void
}

const defaultState = {
  traceBackHours: "4",
  incidentType: "all",
  incident_lv0: true,
  incident_lv1: true,
  incident_lv2: true,
  location: "all",
  following: false,
  incident_category: "all",
}

const FilterForm: React.FC<Props> = ({ onFilter }) => {
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState<IncidentFilter>(defaultState)

  const [tags, setTags] = useState<string[]>(["all"])
  useEffect(() => {
    getIncidentTags().subscribe(({ data: { results: incident_tags } }) => {
      const newTags = incident_tags.map((tag: any) => tag.display_name)
      setTags(["all", ...newTags])
    }, console.error)
  }, [])

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.name
    const target = e.target
    const value = target.value
    setFormData({ ...formData, [name]: value })
  }

  const resetAllFilters = () => {
    setFormData(defaultState)
    sendFilterData()
  }

  const sendFilterData = () => {
    onFilter(formData)
  }

  return (
    <div>
      <button
        className={
          showFilter ? styles.filter_button_activate : styles.filter_button
        }
        onClick={e => {
          e.preventDefault()
          setShowFilter(!showFilter)
        }}
      >
        Filter
      </button>
      <div className={showFilter ? styles.popover : styles.popover_hidden}>
        <form className={showFilter ? styles.form_group : styles.hidden}>
          <fieldset className={styles.flex_col}>
            <label>
              Incident Level 0
              <input
                name='incident_lv0'
                type='checkbox'
                checked={formData.incident_lv0}
                onChange={handleFormChange}
              />
            </label>

            <label>
              Incident Level 1
              <input
                name='incident_lv1'
                type='checkbox'
                checked={formData.incident_lv1}
                onChange={handleFormChange}
              />
            </label>

            <label>
              Incident Level 2
              <input
                name='incident_lv2'
                type='checkbox'
                checked={formData.incident_lv2}
                onChange={handleFormChange}
              />
            </label>
          </fieldset>

          <fieldset>
            <label>
              Trace Back Hours:
              <select
                value={formData.traceBackHours}
                onChange={handleFormSelect}
                name='traceBackHours'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='4'>4</option>
                <option value='8'>8</option>
                <option value='16'>16</option>
              </select>
            </label>
          </fieldset>
          <fieldset>
            <label>
              Incident Type:
              <select
                onChange={handleFormSelect}
                value={formData.incident_category}
                name='incident_category'
              >
                {tags.map(tag => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
            </label>
          </fieldset>
          <fieldset>
            <label>
              Following
              <input
                name='following'
                type='checkbox'
                checked={formData.following}
                onChange={handleFormChange}
              />
            </label>
          </fieldset>
        </form>
        <div className={styles.form_container}>
          <button
            className={styles.filter_button}
            onClick={e => {
              e.preventDefault()
              resetAllFilters()
            }}
          >
            Reset all filters
          </button>
          <button
            className={styles.filter_button}
            onClick={e => {
              e.preventDefault()
              sendFilterData()
              setShowFilter(false)
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterForm
