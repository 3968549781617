import React from "react"

interface Props {
  fill?: string
  down?: boolean
}

const Arrow: React.FC<Props> = ({ fill, down }) => {
  return (
    <svg
      width='10'
      height='11'
      viewBox='0 0 10 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      transform={down ? "rotate(180)" : undefined}
    >
      <path
        d='M1 5L5 1L9 5'
        stroke={fill || "#D95A00"}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <line
        x1='5'
        y1='1.75'
        x2='5'
        y2='10.25'
        stroke={fill || "#D95A00"}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default Arrow
