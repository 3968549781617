import React, { Fragment, useEffect, useState } from "react"
import styles from "./IncidentCreation.module.css"
import emitter, { EventType } from "emitter"
import history from "utils/history"
import { flatten, first } from "lodash"
import PSNContext, { PSN } from "components/Contexts/PSNContext"
import { previewNotification } from "api/notification"
import { submitUpdatePreApproval, UploadImage } from "api/incident"
import { ClearanceLevel } from "types/psn"
import uuid from "uuid"
import { getBoundingBoxOfPolygon } from "utils/map"
import ConfirmationModal, {
  Props as ModalProps,
} from "components/Modals/ConfirmationModal"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import IncidentForm from "components/Form/IncidentForm/IncidentForm"
import { State as IncidentFormState } from "components/Form/IncidentForm/incidentFormReducer"
import { List } from "immutable"
import { getClosestPolygonFromIncident } from "utils/incident"

interface Props {
  mapInit: boolean
  location: string
}

const DEFAULT_INCIDENT = {
  title: "",
  notificationToAllPolygons: false,
}

const DISABLE_PUBLIC = true
const IncidentCreation: React.FC<Props & { psn: PSN }> = ({
  mapInit,
  psn,
  location,
}) => {
  const levels: ClearanceLevel[] = DISABLE_PUBLIC
    ? psn.levels.reverse()
    : [
        {
          level: 0,
          name: "Citizen User",
          plural: "All Citizen Users",
        },
      ]
        .concat(psn.levels)
        .reverse()
  const [incident, setIncident] = useState<IncidentFormState>({
    ...DEFAULT_INCIDENT,
    audience: ((first(levels) as ClearanceLevel) || {}).level,
  })
  const [numNotified, setNumNotified] = useState(0)
  const [modalProps, setModalProps] = useState<ModalProps>()
  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageName, setImageName] = useState<string>("")

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]
    if (!file) return
    setImageName(file.name)

    UploadImage(file).subscribe(({ data: { url } }) => {
      setImageUrl(url)
    })
  }

  useEffect(() => {
    const poly = psn.polygons[location]

    const list = List(
      flatten(poly.polygon).map(([lng, lat]) => ({
        lat,
        lng,
      })),
    )
    const bounds = getBoundingBoxOfPolygon(list)
    emitter.emit(EventType.SetBounds, bounds, true)
    emitter.emit(EventType.SetDraggable, true)
    emitter.emit(EventType.SelectPolygon, location)
  }, [mapInit, psn.polygons, location])

  useEffect(() => {
    previewNotification(psn.id, incident).subscribe(({ data }) => {
      setNumNotified(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident.audience, psn.id])

  const selectedAudienceIndex = levels.findIndex(
    ({ level }) => level === incident.audience,
  )

  return (
    <Fragment>
      <div className={styles.top}>
        <Button
          type={ButtonType.Cancel}
          size={ButtonSize.Medium}
          onClick={(): void => {
            setModalProps({
              onConfirm: () => {
                history.push(`/${psn.id}`)
              },
              onCancel: () => setModalProps(undefined),
              title: "Confirm",
              description: "Close and lose changes?",
            })
          }}
          className={styles.close}
        />
        <h1>Create Incident</h1>
      </div>
      <div className={styles.container}>
        <IncidentForm
          location={location}
          psn={psn}
          mapInit={mapInit}
          onChange={(i): void => setIncident(i)}
          numNotified={numNotified}
          levels={levels}
          defaultIncident={incident}
          className={styles.creationForm}
        />
        <div className={styles.form}>
          <div className={styles.labels}></div>
          <div className={styles.input}>
            <div>
              <Button type={ButtonType.Primary} size={ButtonSize.Medium} block>
                <label htmlFor='file-input'>
                  {imageUrl
                    ? imageName.length > 25
                      ? `...${imageName.slice(-25)}`
                      : imageName
                    : "Upload Image"}
                </label>
              </Button>
              <input
                id='file-input'
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.labels}></div>
          <div className={styles.input}>
            <Button
              type={
                !incident.location || !incident.title || !incident.update
                  ? ButtonType.Disabled
                  : ButtonType.Primary
              }
              block
              size={ButtonSize.Large}
              onClick={(): void => {
                const {
                  title = "",
                  location: incidentLocation,
                  update = "",
                  audience,
                  notificationToAllPolygons,
                  currentPolygonNotification,
                  notifRadius,
                } = incident
                if (location) {
                  setModalProps({
                    onConfirm: () => {
                      submitUpdatePreApproval({
                        title,
                        location: incidentLocation,
                        text: update,
                        level: 0,
                        id: uuid(),
                        occurredAt: new Date(),
                        psnId: psn.id,
                        clearanceLevel: audience,
                        notifRadius,
                        imageUrl,
                      })
                      setModalProps(undefined)
                      history.push(`/${psn.id}/${location}`)
                    },
                    onCancel: () => setModalProps(undefined),
                    title: "Confirm Create New Incident",
                    description: notificationToAllPolygons
                      ? `Confirming will create new incident and notify ${numNotified}
                      ${
                        levels && selectedAudienceIndex !== -1
                          ? levels[selectedAudienceIndex].plural.toLowerCase()
                          : "Citizen Users"
                      }
                       at ${psn.name}.`
                      : `Confirming will create new incident at ${psn.name}.`,
                  })
                }
              }}
            >
              Create Incident
            </Button>
          </div>
        </div>
      </div>
      {modalProps && <ConfirmationModal {...modalProps} />}
    </Fragment>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <IncidentCreation {...props} psn={psn} />
        }
      }}
    </PSNContext.Consumer>
  )
}

export default Wrapper
