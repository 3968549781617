export enum LocalStorageProp {
  DeviceId = "signal:deviceId",
  Token = "signal:token:citizen",
}

const set = (p: LocalStorageProp, data: string): void => {
  window.localStorage[p] = data
}

const get = (p: LocalStorageProp): string => {
  return window.localStorage[p]
}

const unset = (p: LocalStorageProp): void => {
  delete window.localStorage[p]
}

export default {
  set,
  get,
  unset,
}
