import React, { useReducer, useEffect, useState } from "react"
import reducer, { ActionType } from "./symptomReducer"
import { setMemberSymptoms, SymptomStatus } from "api/member"
import { plurals } from "utils/numbers"

export interface Symptom {
  displayName: string
  value: string
}

const OPTIONS = [
  { displayName: "Fever", value: "fever" },
  { displayName: "Cough", value: "cough" },
  { displayName: "Shortness of Breath", value: "shortnessOfBreath" },
  { displayName: "Chills", value: "chills" },
  {
    displayName: "Repeated Shaking with Chills",
    value: "repeatedShakingWithChills",
  },
  { displayName: "Muscle Pain", value: "musclePain" },
  { displayName: "Headache", value: "headache" },
  { displayName: "Sore Throat", value: "soreThroat" },
  { displayName: "New Loss of smell / taste", value: "lossOfSmellTaste" },
  { displayName: "None of the above", value: "none" },
]

export const getSymptomCountText = (symptomCount: number) => {
  if (symptomCount === -1) {
    return "incomplete"
  } else if (symptomCount < 1) {
    return "none"
  } else {
    return `${symptomCount} ${plurals(symptomCount, "symptom")}`
  }
}

const useSymptomTrackingModal = (
  close: (refetch?: boolean) => void,
  data?: any,
  psn?: string,
) => {
  const [symptoms, dispatch] = useReducer(reducer, {})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch({ type: ActionType.ResetState })
    setLoading(false)
  }, [data])

  const onSelect = (value: string) => {
    if (value === "none" || symptoms.none) {
      dispatch({ type: ActionType.ResetState })
    }

    dispatch({ type: ActionType.SetProperty, data: { property: value } })
  }

  const onClose = (refetch?: boolean) => {
    close(refetch)
  }

  const onSubmit = () => {
    if (psn && data) {
      setLoading(true)
      setMemberSymptoms(
        psn,
        data.phone,
        symptoms.none ? SymptomStatus.None : SymptomStatus.Worsening,
        Object.keys(symptoms),
      ).subscribe(() => onClose(true))
    }
  }

  return {
    options: OPTIONS,
    symptoms,
    onSelect,
    onSubmit,
    onClose,
    loading,
    text: data && getSymptomCountText(data.symptomCount),
  }
}

export default useSymptomTrackingModal
