import { EventEmitter, EventSubscription } from "fbemitter"

export enum EventType {
  ShowPolygon = "show polygon",
  SetMarkers = "set markers",
  SetCenter = "set center",
  SetZoom = "set zoom",
  SetBounds = "set bounds",
  AddMarker = "add marker",
  SetLocationSuggestion = "set location suggestion",
  FocusInput = "focus input",
  ClearInput = "clear input",
  SetFocusedMarker = "set focused marker",
  SetDraggable = "set draggable",
  SetSoundStatus = "set sound status",
  SetSoundPosition = "set sound position",
  SelectPolygon = "select polygon",
  SetOverlay = "set overlay",
  SaveViewport = "save viewport",
}

const emitter = new EventEmitter()

export default {
  addListener: (event: EventType, listener: Function): EventSubscription =>
    emitter.addListener(event, listener),
  once: (event: EventType, listener: Function): EventSubscription =>
    emitter.once(event, listener),
  removeAllListeners: (event: EventType): void =>
    emitter.removeAllListeners(event),
  listeners: (event: EventType): Function[] => emitter.listeners(event),
  emit: (event: EventType, ...args: any): void => emitter.emit(event, ...args),
}
