import React, { useContext } from "react"
import Container from "components/Common/Container"
import useOrganizationPage from "./OrganizationPage.hook"
import PSNContext from "components/Contexts/PSNContext"
import OrganizationTable from "./OrganizationTable"

const OrganizationPage: React.FC = () => {
  const psn = useContext(PSNContext)
  useOrganizationPage(psn)

  return (
    <Container fill center={false}>
      <OrganizationTable />
    </Container>
  )
}

export default OrganizationPage
