import React, { useState, useEffect } from "react"
import {
  Employee,
  getEmployees,
  LIMIT,
  RoleType,
  updateMemberRole,
} from "api/member"
import { Header } from "components/Table/Table"
import Dropdown from "components/Form/Dropdown"
import styles from "./OrganizationTable.module.css"
import { of } from "rxjs"
import { concatMap } from "rxjs/operators"
import history from "utils/history"
import { getSafetraceLocations } from "api/safetrace"

const getRoleDisplay = (role: RoleType) => {
  switch (role) {
    case RoleType.SuperOperator:
      return "Admin"
    case RoleType.Operator:
      return "Operator"
    default:
      return "Member"
  }
}

const ALL_LOCATIONS = "All locations"

const useOrganizationTable = (psn?: string, isAdmin: boolean = false) => {
  const [employees, setEmployees] = useState<Employee[]>([])
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [locations, setLocations] = useState<string[]>([ALL_LOCATIONS])
  const [locationFilter, setLocationFilter] = useState<number>(0)

  const fetchEmployees = (
    psn: string,
    limit?: number,
    o = 0,
    locations?: string[],
  ): void => {
    getEmployees(psn, limit, o, locations).subscribe(({ data }) => {
      setEmployees(data.members)
      setOffset(o)
      setTotal(data.totalRowCount)
    })
  }

  useEffect(() => {
    if (psn) {
      fetchEmployees(psn)
      getSafetraceLocations(psn).subscribe(({ data }) => {
        setLocations([ALL_LOCATIONS].concat(data.locations))
      })
    }
  }, [psn])

  useEffect(() => {
    if (psn) {
      fetchEmployees(
        psn,
        undefined,
        offset,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }, [locationFilter, psn])

  const headers: Header[] = [
    { display: "Name", property: "name", width: 200 },
    { display: "Phone Number", property: "phoneNumber", width: 150 },
    { display: "Email", property: "email", width: 200 },
    { display: "Location", property: "location", width: 150 },
    {
      display: "Permissions",
      property: "permissions",
      width: 150,
      getDisplay: data => {
        return getRoleDisplay(data.role)
      },
      renderItem: isAdmin
        ? // eslint-disable-next-line react/display-name
          data => {
            const options = [
              RoleType.SuperOperator,
              RoleType.Operator,
              RoleType.Member,
            ]

            if (data.pending) {
              return null
            }

            return (
              <Dropdown
                options={options.map(getRoleDisplay)}
                selectedIndex={options.indexOf(data.role)}
                className={styles.dropdown}
                onChange={index => {
                  if (psn) {
                    updateMemberRole(psn, data.userId, options[index])
                      .pipe(
                        concatMap(() => {
                          fetchEmployees(psn, undefined, offset)
                          return of([])
                        }),
                      )
                      .subscribe()
                  }
                }}
              />
            )
          }
        : undefined,
    },
    {
      display: "Citizen User",
      property: "pending",
      width: 100,
      getDisplay: data => {
        if (data.pending) {
          return "No"
        } else {
          return "Yes"
        }
      },
      getStyling: data => (data ? { color: "#FF2640" } : undefined),
    },
  ]

  const onNext = () => {
    if (psn) {
      fetchEmployees(
        psn,
        undefined,
        offset + LIMIT,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const onPrev = () => {
    if (psn) {
      fetchEmployees(
        psn,
        undefined,
        offset - LIMIT,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const redirectToImport = () => {
    if (psn) {
      history.push(`/${psn}/safetrace/import`)
    }
  }

  const onPageClick = (page: number) => {
    if (psn) {
      fetchEmployees(
        psn,
        undefined,
        (page - 1) * LIMIT,
        locations[locationFilter] === ALL_LOCATIONS
          ? undefined
          : [locations[locationFilter]],
      )
    }
  }

  const onLocationSelect = (index: number) => {
    setLocationFilter(index)
  }

  return {
    employees,
    headers,
    onNext: offset + LIMIT < total ? onNext : undefined,
    onPrev: offset ? onPrev : undefined,
    pages: Math.ceil(total / LIMIT),
    currentPage: Math.ceil((offset + 1) / LIMIT),
    redirectToImport,
    onPageClick,
    locations,
    onLocationSelect,
    locationFilter,
  }
}

export default useOrganizationTable
