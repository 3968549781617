import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { Notification } from "types/incident"
import styles from "./NotificationUpdate.module.css"
import NotificationMap from "./NotificationMap/NotificationMap"
import { Coords } from "google-map-react"
import { getNotificationPolygon } from "api/notification"
import { getCenterOfPolygon } from "utils/map"
import { List } from "immutable"
import { flatten, capitalize } from "lodash"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  notification: Notification
}

const mToMi = (m: number): number => {
  return Math.round((m / 1609.344) * 100) / 100
}

const NotificationUpdate: React.FC<Props> = ({ notification }) => {
  const [center, setCenter] = useState<Coords>()
  const [polygon, setPolygon] = useState<List<Coords>>()

  useEffect(() => {
    if (notification.geography_name && notification.geography_type) {
      getNotificationPolygon(
        notification.geography_type,
        notification.geography_name,
        notification.geography_code,
      ).subscribe(({ data: { geometry: { coordinates } } }) => {
        const coords = List(
          flatten(coordinates).map((arr: number[]) => {
            return {
              lat: arr[1],
              lng: arr[0],
            }
          }),
        )
        setPolygon(coords)
        const c = getCenterOfPolygon(coords).geometry.coordinates
        setCenter({ lat: c[0], lng: c[1] })
      })
    } else if (notification.latitude && notification.longitude) {
      setCenter({
        lat: parseFloat(notification.latitude),
        lng: parseFloat(notification.longitude),
      })
    }
  }, [
    notification.geography_code,
    notification.geography_name,
    notification.geography_type,
    notification.latitude,
    notification.longitude,
  ])

  return (
    <div className={styles.container}>
      <div className={styles.separator}>
        <div className={styles.ball} />
        <div className={styles.line} />
      </div>
      <div className={styles.main}>
        <div className={styles.top}>
          <Body type={BodyType.Bold}>
            {dayjs(notification.created_at * 1000).format("h:mmA")}
          </Body>
          <div className={styles.description}>
            {notification.transcriber.username && (
              <Body type={BodyType.Regular}>
                {` · @${notification.transcriber.username}`}
              </Body>
            )}
          </div>
        </div>
        <div className={styles.notification}>
          <div className={styles.copy}>
            <Body type={BodyType.Bold}>{notification.title}</Body>
            <Body type={BodyType.Regular}>{notification.message}</Body>
          </div>
          {(center || (center && polygon)) && (
            <NotificationMap
              center={center}
              userRadius={notification.user_radius}
              polygon={polygon}
            />
          )}
        </div>
        <div className={styles.details}>
          <Body type={BodyType.Regular}>
            {notification.targeting_type}
            {notification.geography_name &&
              ` · ${capitalize(notification.geography_name)}`}
            {notification.user_radius &&
              ` · ${mToMi(notification.user_radius)}mi`}{" "}
            · {notification.users_received} people notified
          </Body>
        </div>
      </div>
    </div>
  )
}

export default NotificationUpdate
