import { OptimusAPI, getAuthToken } from "./index"
import { Observable, from, zip } from "rxjs"
import { AxiosResponse } from "axios"
import dayjs from "dayjs"
import {
  Incident,
  Stream,
  Notification,
  IncidentTag,
  IncidentReview,
  IncidentUpdateRequest,
  slackPayload,
  IncidentContentImage,
} from "types/incident"

export const getIncidentIds = (
  since: Date = dayjs()
    .subtract(16, "hour")
    .toDate(),
  psnId?: string,
  serviceAreas?: string[],
): Observable<AxiosResponse<{ incidentIds: string[] }>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      "/incidents2/query",
      { since, psnId, serviceAreas },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export const getIncidentsById = (
  ids: string[],
  psnId?: string,
): Observable<AxiosResponse<{ incidents: Incident[] }>[]> => {
  const citizenJWT = getAuthToken()
  const BATCH_SIZE = 50

  const calls = new Array(Math.ceil(ids.length / BATCH_SIZE))
    .fill(undefined)
    .map((val, i) => {
      const end = (i + 1) * BATCH_SIZE
      const start = i * BATCH_SIZE

      return ids.slice(start, Math.min(ids.length, end))
    })

  return zip(
    ...calls.map(incidentIds =>
      from(
        OptimusAPI.get("/incidents2/batch", {
          params: { incidentIds: incidentIds.join(","), psnId },
          headers: { "x-access-token": citizenJWT },
        }),
      ),
    ),
  )
}

export const GetIncidentReviews = (): Observable<AxiosResponse<
  IncidentReview[]
>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get("/incident_reviews", {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const submitUpdatePreApproval = (data: IncidentUpdateRequest) => {
  const citizenJWT = getAuthToken()

  // flatten data
  const flattenedData = {
    id: data.id,
    title: data.title,
    text: data.text,
    level: data.level,
    address: data.location && data.location.address,
    location: data.location && data.location.location,
    neighborhood: data.location && data.location.neighborhood,
    lat: data.location && data.location.lat,
    lng: data.location && data.location.lng,
    occurredAt: data.occurredAt,
    cityCode: data.location && data.location.cityCode,
    psnId: data.psnId,
    clearanceLevel: data.clearanceLevel,
    notifRadius: data.notifRadius,
    imageUrl: data.imageUrl,
  }

  return from(
    OptimusAPI.post("/incident_reviews", flattenedData, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const reviewIncident = (review_id: string, status: string) => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.post(
      "/incident_reviews/" + review_id,
      { status },
      {
        headers: { "x-access-token": citizenJWT },
      },
    ),
  )
}

export const submitUpdateRev = (
  data: IncidentUpdateRequest,
): Observable<AxiosResponse<{ incident: Incident }>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post("/incidents2/create_rev", data, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const submitUpdateApproval = (data: IncidentUpdateRequest) => {
  const citizenJWT = getAuthToken()

  // flatten data
  const flattenedData = {
    id: data.id,
    incidentId: data.incidentId,
    title: data.title,
    text: data.text,

    address: data.location && data.location.address,
    location: data.location && data.location.location,
    neighborhood: data.location && data.location.neighborhood,
    lat: data.location && data.location.lat,
    lng: data.location && data.location.lng,
    occurredAt: data.occurredAt,
    cityCode: data.location && data.location.cityCode,

    updateSequence: data.updateSequence,
    rootRevId: data.rootRevId,
    source: data.source,
  }

  return from(
    OptimusAPI.post("/incident_update_reviews", flattenedData, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const getIncidentStreams = (
  incidentId: string,
  rwdb: boolean,
): Observable<AxiosResponse<Stream[]>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/v1/incidents/${incidentId}/video_streams`, {
      params: { rwdb },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const getIncidentNotifications = (
  incidentId: string,
): Observable<AxiosResponse<{ notifications: Notification[] }>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get("/signal/incident_notifications", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params: { incident_id: incidentId },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const followIncident = (
  incidentId: string,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.post(`/v1/incidents/${incidentId}/follow`, null, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const unfollowIncident = (
  incidentId: string,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.delete(`/v1/incidents/${incidentId}/follow`, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const getIncidentTags = (): Observable<AxiosResponse<{
  results: IncidentTag[]
}>> => {
  return from(
    OptimusAPI.get("signal/tags", {
      params: { type: "ml" },
    }),
  )
}

export const sendSlackMsg = (
  payload: slackPayload,
): Observable<AxiosResponse<void>> => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.post(`/psn/incident_suggestion`, payload, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const UploadImage = (
  image: File,
): Observable<AxiosResponse<{ url: string }>> => {
  const citizenJWT = getAuthToken()
  const formData = new FormData()
  formData.append("file", image)
  return from(
    OptimusAPI.post("/incident_reviews/image_upload", formData, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const getIncidentContentImages = (
  incidentId: string,
): Observable<AxiosResponse<IncidentContentImage[]>> => {
  const citizenJWT = getAuthToken()
  return from(
    OptimusAPI.get(`/v1/incidents/${incidentId}/content/images`, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export default {
  getIncidentIds,
  getIncidentsById,
  submitUpdateRev,
  getIncidentStreams,
  getIncidentNotifications,
  followIncident,
  unfollowIncident,
  getIncidentTags,
}
