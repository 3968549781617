import React from "react"
import { Map, List } from "immutable"
import { Coords } from "google-map-react"
import { PSNResponse } from "types/psn"

export interface PSN extends PSNResponse {
  polygonsMapping: Map<string, List<Coords>>
}

const PSNContext = React.createContext<PSN | undefined>(undefined)

export default PSNContext
