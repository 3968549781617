import { useEffect } from "react"

const useResize = (
  element: Element | null | Window,
  onSize: (e: any) => void,
): void => {
  useEffect(() => {
    if (element) {
      element.addEventListener("resize", onSize)
    }

    return (): void => {
      if (element) {
        element.removeEventListener("resize", onSize)
      }
    }
  }, [element, onSize])
}

export default useResize
