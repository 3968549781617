import React, { useRef, useState } from "react"
import styles from "./Tutorial.module.css"
import classnames from "classnames"
import PushNotif from "../PushNotif"
import useScroll from "components/Hooks/useScroll"
import useResize from "components/Hooks/useResize"

interface Props {
  generic: boolean
}

const Tutorial: React.FC<Props> = ({ generic }) => {
  const [showPush, setShowPush] = useState(false)
  const [stickyHeading, setStickyHeading] = useState(false)
  const [anchorBottom, setAnchorBottom] = useState(false)
  const push = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const rightDiv = useRef<HTMLDivElement>(null)
  const MOBILE_ASPECT_RATIO = 900 / 1788
  const mobileWidth =
    window.outerWidth > 850
      ? window.outerWidth * 0.33
      : Math.min(window.outerWidth - 32, 400)
  const mobileHeight = mobileWidth * (1 / MOBILE_ASPECT_RATIO)

  useScroll(window, () => {
    if (push.current && wrapper.current) {
      const show =
        window.outerHeight / 2 > push.current.getBoundingClientRect().top
      if (show !== showPush) {
        setShowPush(show)
      }
    }

    if (window.outerWidth > 850) {
      if (rightDiv.current) {
        const { top, height } = rightDiv.current.getBoundingClientRect()
        const anchor = top < height * -0.5
        const stick = top < 0 && top > height * -0.5
        if (stick !== stickyHeading) {
          setStickyHeading(stick)
        }

        if (anchor !== anchorBottom) {
          setAnchorBottom(anchor)
        }
      }
    }
  })

  useResize(window, () => {
    if (window.outerWidth <= 850) {
      setStickyHeading(false)
      setAnchorBottom(false)
    }
  })

  return (
    <div className={styles.container} ref={wrapper}>
      <h2>
        Keep people safe and informed <span>in real time</span>
      </h2>
      <div className={classnames(styles.panel, styles.limitWidth)}>
        <div className={classnames(styles.visual, styles.fill)}>
          <img
            className={styles.web}
            src={`${generic ? "/generic" : ""}/incident_feed.png`}
            alt='feed'
          />
        </div>
      </div>
      <div
        className={classnames(styles.panel, styles.sticky, {
          [styles.fixed]: stickyHeading,
          [styles.anchor]: anchorBottom,
        })}
      >
        <div className={styles.info}>
          <p>
            Instantly reach all Citizen users on site to deliver critical
            awareness that will keep them safe.
          </p>
        </div>
        <div className={styles.right} ref={rightDiv}>
          <div className={styles.visual}>
            <img
              className={styles.web}
              src={`${generic ? "/generic" : ""}/incident_detail.png`}
              alt='detail'
            />
          </div>
          <div className={classnames(styles.visual, styles.mobile)} ref={push}>
            <div className={styles.extension}>
              <div className={styles.ball}>
                <div />
              </div>
              <div className={styles.dots}></div>
            </div>
            <img
              src={`${generic ? "/generic" : ""}/mobile_map_view.png`}
              alt='map'
              style={{ width: mobileWidth, height: mobileHeight }}
            />
            {showPush && (
              <PushNotif
                title={
                  generic
                    ? "Madison Square Garden Alert"
                    : "Grand Central Safety Network"
                }
                location={
                  generic
                    ? "Officials have deemed the area safe and all clear"
                    : "Officials have deemed the area safe. The Northwest Passage is now open."
                }
                className={styles.push}
              />
            )}
          </div>
        </div>
      </div>
      {!generic && (
        <div className={classnames(styles.panel, styles.mobileReverse)}>
          <div className={classnames(styles.visual, styles.mobile)}>
            <img
              src='/mobile_incident_detail.png'
              alt='mobile-detail'
              style={{ width: mobileWidth, height: mobileHeight }}
            />
          </div>
          <div className={styles.info}>
            <p>
              Users can respond with live video and comments to increase overall
              situational awareness, aiding your response.
            </p>
          </div>
        </div>
      )}
      <div className={styles.overlay} />
    </div>
  )
}

export default Tutorial
