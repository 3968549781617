import { OptimusAPI } from "./index"
import { Observable, from } from "rxjs"
import { AxiosResponse } from "axios"
import { Update } from "types/incident"
import dayjs from "dayjs"

interface LeadInfo {
  name: string
  title?: string
  email: string
  number: string
}

export const submitLeadInfo = (
  info: LeadInfo,
): Observable<AxiosResponse<void>> => {
  return from(OptimusAPI.post("/psn/lead", info))
}

export interface PublicIncident {
  incident_id: string
  title: string
  city_code: string
  level: number
  neighborhood: string
  address: string
  location: string
  longitude: number
  latitude: number
  severity: string
  created_at: string
}

interface GetPublicIncidentBody {
  limit: number
  video: boolean
  time_range: string[]
  longitude_range: number[]
  latitude_range: number[]
}

export const getPublicIncidents = (
  bounds: number[],
  overrides?: Partial<GetPublicIncidentBody>
): Observable<AxiosResponse<PublicIncident[]>> => {
  const [minX, minY, maxX, maxY] = bounds

  return from(
    OptimusAPI.post("/api/incidents/search", {
      limit: 5,
      video: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      time_range: [
        dayjs()
          .subtract(1, "month")
          .format(),
        dayjs().format(),
      ],
      // eslint-disable-next-line @typescript-eslint/camelcase
      longitude_range: [minY, maxY],
      // eslint-disable-next-line @typescript-eslint/camelcase
      latitude_range: [minX, maxX],
      ...overrides,
    }),
  )
}

interface IncidentResponse {
  incident: PublicIncident
  updates: Update[]
  streams: StreamLite[]
}

export interface StreamLite {
  stream_id: string
  is_live: boolean
  hls_url: string
  created_at: string
  mp4_watermarked_url: string
  thumbnail: string
  num_downloads: number
}

export const getPublicIncident = (
  id: string,
): Observable<AxiosResponse<IncidentResponse>> => {
  return from(OptimusAPI.get(`/api/incident/${id}`))
}

export default {
  submitLeadInfo,
  getPublicIncidents,
}
