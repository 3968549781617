import React, { useEffect, useState } from "react"
import { getSafetraceAggregateStats, getSafetraceStats } from "api/safetrace"
import { DashboardSectionItem } from "./DashboardSection.hook"
import dayjs from "dayjs"

const useDashboardPage = (psn?: string) => {
  const [aggregateStats, setAggregateStats] = useState<any>()
  const sections = [
    {
      id: DashboardSectionItem.Participation,
      label: DashboardSectionItem.Participation,
      property: "participation",
    },
    {
      id: DashboardSectionItem.SafeTrace,
      label: DashboardSectionItem.SafeTrace,
      property: "tracing",
    },
    {
      id: DashboardSectionItem.Symptoms,
      label: DashboardSectionItem.Symptoms,
      property: "symptomTracking",
    },
    {
      id: DashboardSectionItem.Testing,
      label: DashboardSectionItem.Testing,
      property: "testing",
    },
  ]

  useEffect(() => {
    if (psn) {
      const today = dayjs().format("YYYY-MM-DD")
      getSafetraceAggregateStats(
        psn,
        dayjs()
          .subtract(6, "day")
          .format("YYYY-MM-DD"),
        today,
      ).subscribe(({ data }) => {
        getSafetraceStats(psn).subscribe(todayData => {
          setAggregateStats({ ...data, [today]: todayData.data })
        })
      })
    }
  }, [psn])

  return {
    sections,
    aggregateStats,
  }
}

export default useDashboardPage
