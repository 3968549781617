import React, { useEffect } from "react"
import styles from "./PushNotif.module.css"
import classnames from "classnames"
import { track } from "utils/analytics"

interface Props {
  title: string
  location: string
  className?: string
}

const PushNotif: React.FC<Props> = ({ title, location, className }) => {
  useEffect(() => {
    track("Marketing", "Show", "Push Notification", title)
  }, [title])

  return (
    <div className={classnames(styles.push, className)}>
      <div className={styles.topLine}>
        <div className={styles.logo}>
          <img src='/app-icon.png' alt='logo' />
          <span>CITIZEN</span>
        </div>
        <div className={styles.date}>now</div>
      </div>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.address}>{location}</div>
    </div>
  )
}

export default PushNotif
