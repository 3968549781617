import React, { Fragment, useState } from "react"
import { List, Map as ImmutableMap } from "immutable"
import { Coords } from "google-map-react"
import Map from "./BaseMap"
import PanelBase from "components/PanelBase/PanelBase"
import MapContext, {
  State as MapContextState,
} from "components/Contexts/MapContext"
import PSNContext from "components/Contexts/PSNContext"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import { EventType } from "emitter"
import useEmitter from "components/Hooks/useEmitter"
import { OverlayOption } from "./MapOverlay"
import usePSN from "components/Hooks/usePSN"

const MapBase: React.FC<RouteComponentProps> = ({ match }) => {
  const [mapContext, setMapContext] = useState<MapContextState>({
    center: {
      lng: -73.975354,
      lat: 40.682605,
    },
  })
  const [overlay, setOverlay] = useState<
    { overlay: OverlayOption; id: string } | undefined
  >()
  const [mapInit, setMapInit] = useState(false)
  const isDev = window.location.hostname.startsWith("localhost")
  const eventMapping: { [event: string]: Function } = {}
  eventMapping[EventType.SetOverlay] = (data: any): void => {
    setOverlay(data ? { overlay: data.overlay, id: data.id } : undefined)
  }

  useEmitter(eventMapping)

  const psn = usePSN((match.params as { psn: string }).psn)

  return (
    <Fragment>
      <MapContext.Provider value={mapContext}>
        <PSNContext.Provider value={psn}>
          {psn && (
            <Helmet>
              <title>{psn.name} PSN</title>
              {!isDev && <script src='/scripts/fullstory.js' async />}
            </Helmet>
          )}
          <PanelBase mapInit={mapInit} overlay={!!overlay} />
          <Map
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
            defaultCenter={{ lng: -73.975354, lat: 40.682605 }}
            defaultZoom={18}
            polygons={
              psn ? psn.polygonsMapping : ImmutableMap<string, List<Coords>>()
            }
            onInit={(): void => {
              setMapInit(true)
            }}
            onBoundsChange={(bounds, center, zoom): void => {
              setMapContext({
                ...mapContext,
                bounds,
                center,
                zoom,
              })
            }}
            draggable
            overlay={overlay}
          />
        </PSNContext.Provider>
      </MapContext.Provider>
    </Fragment>
  )
}

export default withRouter(MapBase)
