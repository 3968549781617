export const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const plurals = (n: number, singular: string, plural?: string) => {
  if (n !== 1) {
    return plural || `${singular}s`
  }

  return singular
}
