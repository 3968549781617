import React, { useContext } from "react"
import PSNContext from "components/Contexts/PSNContext"
import useOrganizationTable from "./OrganizationTable.hook"
import Table from "components/Table/Table"
import Container from "components/Common/Container"
import styles from "./OrganizationTable.module.css"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import Spacing from "components/Common/Spacing"
import UserContext from "components/Contexts/UserContext"
import usePSNUser from "components/Hooks/usePSNUser"
import { sumBy } from "lodash"
import Dropdown from "components/Form/Dropdown"

const OrganizationTable = () => {
  const psn = useContext(PSNContext)
  const user = useContext(UserContext)
  const { isAdmin } = usePSNUser(psn && psn.id, user)
  const {
    employees,
    headers,
    onNext,
    pages,
    currentPage,
    onPrev,
    redirectToImport,
    onPageClick,
    locations,
    onLocationSelect,
    locationFilter,
  } = useOrganizationTable(psn && psn.id, isAdmin)
  const minWidth = sumBy(headers, h => h.width || 0)

  if (!psn) {
    return null
  }

  return (
    <Container fill column style={{ minWidth, overflowX: "auto" }}>
      <Container
        fullWidth
        className={styles.heading}
        leftJustify
        right={8}
        left={8}
      >
        <Container className={styles.locations}>
          <Dropdown
            options={locations}
            selectedIndex={locationFilter}
            onChange={onLocationSelect}
          />
        </Container>
        <Container fill rightJustify>
          <Spacing right={4}>
            <Button
              type={ButtonType.Subtle}
              size={ButtonSize.Medium}
              onClick={redirectToImport}
            >
              <h5>Update CSV</h5>
            </Button>
          </Spacing>
          <Button
            type={ButtonType.Primary}
            size={ButtonSize.Medium}
            onClick={redirectToImport}
          >
            <h5>Sync to google</h5>
          </Button>
        </Container>
      </Container>
      <Table
        headers={headers}
        rows={employees}
        identifier='userId'
        onNext={onNext}
        onPrev={onPrev}
        pages={pages}
        currentPage={currentPage}
        onPageClick={onPageClick}
      />
    </Container>
  )
}

export default OrganizationTable
