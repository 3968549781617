import React from "react"
import OrganizationPage from "views/SafeTrace/OrganizationPage"
import { Switch, Route } from "react-router-dom"
import MembersPage from "views/SafeTrace/MembersPage"
import GSuiteAuthPage from "views/SafeTrace/GSuiteAuthPage"
import ImportOrganizationPage from "views/SafeTrace/ImportOrganizationPage"
import DashboardPage from "views/SafeTrace/DashboardPage"

const routes = (
  <Switch>
    <Route
      exact
      path='/:psn/safetrace/organization'
      component={OrganizationPage}
    />
    <Route exact path='/:psn/safetrace/members' component={MembersPage} />
    <Route exact path='/:psn/safetrace/gsuite' component={GSuiteAuthPage} />
    <Route
      exact
      path='/:psn/safetrace/gsuite/sync'
      render={() => <GSuiteAuthPage defaultStep={1} />}
    />
    <Route
      exact
      path='/:psn/safetrace/import'
      component={ImportOrganizationPage}
    />
    <Route exact path='/:psn/safetrace/dashboard' component={DashboardPage} />
  </Switch>
)

export default routes
