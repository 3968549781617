import React from "react"
import classnames from "classnames"
import styles from "./PaginationButton.module.css"

interface Props {
  onClick?: () => void
  left?: boolean
  disabled?: boolean
}

const PaginationButton: React.FC<Props> = ({
  onClick,
  left,
  disabled = false,
}) => {
  const isDisabled = disabled || !onClick

  return (
    <button className={styles.button} onClick={onClick} disabled={isDisabled}>
      <img
        className={classnames({ [styles.left]: left })}
        src={`/icons/chevron${isDisabled ? "-disabled" : ""}.svg`}
        alt='paginate-button'
      />
    </button>
  )
}

export default PaginationButton
