import React from "react"
import { PublicIncident } from "api/marketing"
import styles from "./FeedUnit.module.css"

interface Props {
  incident: PublicIncident
  psnName: string
}

const FeedUnit: React.FC<Props> = ({ incident, psnName }) => {
  return (
    <div className={styles.notif}>
      <div className={styles.topLine}>
        <div className={styles.date}>Just now</div>
        <div>{psnName}</div>
      </div>
      <h5 className={styles.title}>{incident.title}</h5>
      <div className={styles.address}>{incident.location}</div>
    </div>
  )
}

export default FeedUnit
