import React from "react"
import styles from "./MarketingFeedUnit.module.css"
import { PublicIncident } from "api/marketing"
import dayjs from "dayjs"

interface Props {
  incident: PublicIncident
}

const MarketingFeedUnit: React.FC<Props> = ({ incident }) => {
  return (
    <div className={styles.container}>
      <div className={styles.date}>{dayjs(incident.created_at).fromNow()}</div>
      <h5 className={styles.title}>{incident.title}</h5>
      <div className={styles.address}>{incident.location}</div>
    </div>
  )
}

export default MarketingFeedUnit
