import { useEffect } from "react"

const usePopup = (
  popupRef: React.RefObject<Element>,
  onClose: () => void,
  open: boolean,
): void => {
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (
        popupRef.current &&
        !popupRef.current.contains(e.target as Node) &&
        popupRef.current !== e.target
      ) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClick)
    }

    return (): void => {
      if (open) {
        document.removeEventListener("mousedown", handleClick)
      }
    }
  }, [onClose, open, popupRef])
}

export default usePopup
