import React, { useState } from "react"
import styles from "./TextArea.module.css"
import classnames from "classnames"
import fonts from "styles/fonts.module.css"
import inputs from "styles/inputs.module.css"

interface Props {
  placeholder?: string
  onChange?: (value: string) => void
  className?: string
}

const TextArea: React.FC<Props> = ({ placeholder, onChange, className }) => {
  const [focused, setFocused] = useState(false)

  return (
    <div
      className={classnames(styles.input, className, {
        [inputs.focused]: focused,
      })}
    >
      <textarea
        onFocus={(): void => setFocused(true)}
        onBlur={(): void => setFocused(false)}
        className={classnames(fonts.body, fonts.regular)}
        placeholder={placeholder}
        onChange={
          onChange
            ? ({ target: { value } }): void => onChange(value)
            : undefined
        }
      />
    </div>
  )
}

export default TextArea
