interface State {
  loading: boolean
  userId?: string
  username?: string
  usePassword?: boolean
  phone?: string
}

export enum ActionType {
  SetUsername = "set username",
  SetPhone = "set phone",
  SetUserId = "set user id",
  SetUsePassword = "set usePassword",
}

interface Action {
  type: ActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

const loginReducer = (state: State, action: Action): State => {
  const newState = { ...state }
  switch (action.type) {
    case ActionType.SetUsername:
      newState.username = action.data
      break
    case ActionType.SetUsePassword:
      newState.usePassword = action.data
      break
    case ActionType.SetPhone:
      newState.phone = action.data
      break
    case ActionType.SetUserId:
      newState.userId = action.data
      break
    default:
      break
  }

  return newState
}

export default loginReducer
