import React from "react"
import Container from "components/Common/Container"
import MainMenu from "components/MainMenu/MainMenu"
import useSafeTraceBase from "./SafeTraceBase.hook"
import { RouteComponentProps } from "react-router-dom"
import usePSN from "components/Hooks/usePSN"
import PSNContext from "components/Contexts/PSNContext"
import styles from "./SafeTraceBase.module.css"
import routes from "./routes"

const SafeTraceBase: React.FC<RouteComponentProps> = ({ match, location }) => {
  const psn = usePSN((match.params as { psn: string }).psn)
  const { sections, activeMenuItem } = useSafeTraceBase(location.pathname, psn)

  if (!psn) {
    return null
  }

  return (
    <PSNContext.Provider value={psn}>
      <Container className={styles.panel}>
        <MainMenu
          sections={sections}
          selectedItem={activeMenuItem}
          safeTraceEnabled={psn.safeTraceEnabled}
        />
      </Container>
      <Container fill className={styles.main} center={false}>
        {routes}
      </Container>
    </PSNContext.Provider>
  )
}

export default SafeTraceBase
