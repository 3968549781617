import { OptimusAPI, getAuthToken } from "./index"

interface Neighborhood {
  borough?: string
  city: string
  name: string
}

export const getNeighborhoods = async (
  lat: number,
  long: number,
): Promise<Neighborhood[]> => {
  const citizenJWT = getAuthToken()
  const resp = await OptimusAPI.get("/geo/neighborhoods", {
    params: { lat, long },
    headers: {
      "x-access-token": citizenJWT,
    },
  })

  return resp.data.results
}

export const getServiceAreaCode = async (
  lat: number,
  long: number,
): Promise<{ code: string }> => {
  const citizenJWT = getAuthToken()
  const response = await OptimusAPI.get("/signal/service_area_code", {
    params: { lat, long },
    headers: {
      "x-access-token": citizenJWT,
    },
  })

  return response.data
}

export const getPolice = async (
  lat: number,
  long: number,
): Promise<{ name: string }[]> => {
  const citizenJWT = getAuthToken()
  const response = await OptimusAPI.get("/geo/precincts", {
    params: { lat, long },
    headers: {
      "x-access-token": citizenJWT,
    },
  })

  return response.data.results
}
