import React, { useState, useEffect, useRef } from "react"
import styles from "./LeadForm.module.css"
import TextInput from "components/Form/TextInput"
import NumberInput from "components/Form/NumberInput"
import { submitLeadInfo } from "api/marketing"
import { Observable } from "rxjs"
import { AxiosResponse } from "axios"
import { validateEmail } from "utils/app"
import useScroll from "components/Hooks/useScroll"

interface Field {
  value: string
  error?: string
}

const DEFAULT_FIELD = {
  value: "",
}

interface Props {
  id?: string
  onShow: () => void
  onHide: () => void
  onRender: (scrollY: number) => void
  generic: boolean
}

const LeadForm: React.FC<Props> = ({
  id,
  onShow,
  onHide,
  onRender,
  generic,
}) => {
  const form = useRef<HTMLDivElement>(null)
  const [name, setName] = useState<Field>(DEFAULT_FIELD)
  const [title, setTitle] = useState<Field>(DEFAULT_FIELD)
  const [email, setEmail] = useState<Field>(DEFAULT_FIELD)
  const [number, setNumber] = useState<Field>(DEFAULT_FIELD)
  const [submitted, setSubmitted] = useState(false)
  const [shown, setShown] = useState(false)

  const isEmptyCheck = (
    field: Field,
    set: (f: Field) => void,
    validator?: (val: string) => boolean,
  ) => {
    return (): void => {
      if (!field.value) {
        set({ value: field.value, error: "This field is required." })
      } else if (validator && !validator(field.value)) {
        set({ value: field.value, error: "This field is invalid." })
      }
    }
  }

  useEffect(() => {
    if (form.current) {
      onRender(form.current.offsetTop)
    }
  }, [form, onRender])

  useScroll(window, () => {
    if (form.current) {
      const { bottom, height } = form.current.getBoundingClientRect()
      const val = bottom < window.innerHeight + height
      if (val !== shown) {
        setShown(val)
        if (val) {
          onShow()
        } else {
          onHide()
        }
      }
    }
  })

  return (
    <div className={styles.container} id={id} ref={form}>
      <h2>{generic ? "Join" : "Claim"} Your Network</h2>
      <div className={styles.form}>
        <div className={styles.row}>
          <TextInput
            className={styles.inputContainer}
            inputClassName={styles.input}
            placeholder='Name'
            onChange={(val: string): void => setName({ value: val })}
            onBlur={isEmptyCheck(name, setName)}
            error={name.error}
          />
          <TextInput
            className={styles.inputContainer}
            inputClassName={styles.input}
            placeholder='Title (optional)'
            onChange={(val: string): void => setTitle({ value: val })}
            error={title.error}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.inputContainer}
            inputClassName={styles.input}
            placeholder='Corporate Email'
            onChange={(val: string): void => setEmail({ value: val })}
            onBlur={isEmptyCheck(email, setEmail, validateEmail)}
            error={email.error}
          />
          <NumberInput
            className={styles.inputContainer}
            inputClassName={styles.input}
            placeholder='Number'
            onChange={(val: string): void => setNumber({ value: val })}
            onBlur={isEmptyCheck(number, setNumber)}
            error={number.error}
          />
        </div>
      </div>
      <button
        className={styles.submit}
        onClick={(): Observable<AxiosResponse<void>> | void => {
          isEmptyCheck(number, setNumber)()
          isEmptyCheck(name, setName)()
          isEmptyCheck(email, setEmail, validateEmail)()

          if (number.error || name.error || email.error) {
            return
          }

          submitLeadInfo({
            name: name.value,
            title: title.value,
            email: email.value,
            number: number.value,
          }).subscribe(() => setSubmitted(true))
        }}
        disabled={submitted}
      >
        {submitted
          ? "Thank you!"
          : `${generic ? "Join" : "Claim"} Safety Network`}
      </button>
      <p>or call us at (646) 397-3766</p>
    </div>
  )
}

export default React.memo(LeadForm)
