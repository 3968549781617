import React, { useState, useEffect } from "react"
import styles from "./NotificationMap.module.css"
import GoogleMapReact, { Coords } from "google-map-react"
import mapStyle from "components/Map/style.json"
import Radius, { RadiusType } from "components/Map/Radius"
import { List } from "immutable"
import { getBoundingBoxOfPolygon } from "utils/map"
import Polygon, { PolygonType } from "components/Map/Polygon"

interface Props {
  userRadius: number | null
  center: Coords
  polygon?: List<Coords>
}

const NotificationMap: React.FC<Props> = ({ userRadius, center, polygon }) => {
  const [map, setMap] = useState()

  useEffect(() => {
    let item: RadiusType | PolygonType

    if (map) {
      if (polygon) {
        const [minX, minY, maxX, maxY] = getBoundingBoxOfPolygon(polygon)
        map.map.fitBounds({ east: maxY, north: minX, south: maxX, west: minY })
        item = Polygon(map, polygon, {
          strokeColor: "#ffb300",
          fillColor: "#ffb300",
        })
      } else if (userRadius && center) {
        item = Radius(map, center, userRadius)
      }
    }

    return (): void => {
      if (item) {
        item.setMap(null)
      }
    }
  }, [center, map, userRadius, polygon])

  return (
    <div className={styles.container}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        options={{
          disableDefaultUI: true,
          streetViewControl: false,
          styles: mapStyle,
          clickableIcons: false,
          draggableCursor: "default",
        }}
        bootstrapURLKeys={
          {
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: "geometry,drawing,places",
          } as any
        }
        onGoogleApiLoaded={(m): void => {
          setMap(m)
        }}
        defaultCenter={center}
        defaultZoom={15}
        draggable={false}
      />
    </div>
  )
}

export default React.memo(NotificationMap)
