import React, { Fragment } from "react"
import Container from "components/Common/Container"
import styles from "./DashboardStat.module.css"
import Body, { BodyType } from "components/Texts/Body"
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
} from "recharts"
import Legal, { LegalType } from "components/Texts/Legal"
import { round } from "lodash"
import Arrow from "./Arrow"
import Spacing from "components/Common/Spacing"

export enum StatType {
  PieChart = "pie",
  BarGraph = "bar",
}

interface Props {
  type?: StatType
  percentageChange: number
  calloutText: string
  subText: string
  data: any
  positive: boolean
}

export const getColoring = (positive: boolean) =>
  positive ? "#25E688" : "#D95A00"

const DashboardStat: React.FC<Props> = ({
  type,
  percentageChange,
  calloutText,
  subText,
  data,
  positive,
}) => {
  const color = getColoring(positive)

  let chart = null
  switch (type) {
    case StatType.PieChart:
      chart = (
        <PieChart>
          <Pie
            stroke={0}
            dataKey='value'
            data={data}
            cx='50%'
            cy='50%'
            innerRadius={25}
            outerRadius={40}
            fill='#8884d8'
          >
            {data.map((entry: any) => (
              <Cell fill={entry.color || color} key={entry.name} />
            ))}
          </Pie>
        </PieChart>
      )
      break
    case StatType.BarGraph:
      chart = (
        <BarChart data={data} margin={{ top: 32 }}>
          <XAxis tick={false} />
          <Bar dataKey='value' fill='#8884d8' barSize={12}>
            {data.map((entry: any) => (
              <Cell fill={entry.color || color} key={entry.name} />
            ))}
          </Bar>
        </BarChart>
      )
      break
  }

  return (
    <Container
      column
      className={styles.stat}
      leftJustify
      top={4}
      left={4}
      right={4}
      bottom={4}
    >
      <Container style={{ color }}>
        {percentageChange !== 0 && (
          <Spacing right={2}>
            <Arrow fill={color} down={percentageChange < 0} />
          </Spacing>
        )}
        <Legal type={LegalType.Regular}>
          {percentageChange !== 0 ? (
            <Fragment>
              {percentageChange >= 0 ? "+" : "-"}{" "}
              {round(Math.abs(percentageChange), 2) * 100}% last 24 hours
            </Fragment>
          ) : (
            "--"
          )}
        </Legal>
      </Container>
      <Container fill>
        <ResponsiveContainer
          width={216}
          height={type === StatType.BarGraph ? 140 : 80}
        >
          {chart}
        </ResponsiveContainer>
      </Container>
      <h1 className={styles.callout} style={{ color }}>
        {calloutText}
      </h1>
      <Body type={BodyType.Bold} className={styles.subtext}>
        {subText}
      </Body>
    </Container>
  )
}

export default DashboardStat
