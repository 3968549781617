import React, { useReducer, Fragment, useEffect, useState } from "react"
import { MarkerType, Props as MarkerProps } from "components/Map/Markers/Marker"
import styles from "./IncidentForm.module.css"
import incidentFormReducer, {
  ActionType,
  State as IncidentFormState,
} from "./incidentFormReducer"
import emitter, { EventType } from "emitter"
import Dropdown from "components/Form/Dropdown"
import { capitalize, debounce, noop } from "lodash"
import TextInput from "components/Form/TextInput"
import TextArea from "components/Form/TextArea"
import LocationInput from "components/Form/LocationInput"
import Checkbox from "components/Form/Checkbox"
import { PSN } from "components/Contexts/PSNContext"
import { extract, getPlaceFromLatLng } from "utils/address"
import { ClearanceLevel } from "types/psn"
import { Coords } from "google-map-react"
import { Map } from "immutable"
import { getCenterOfPolygon } from "utils/map"
import Body, { BodyType } from "components/Texts/Body"
import Small, { SmallType } from "components/Texts/Small"
import classnames from "classnames"

export enum IncidentFormInputType {
  Visibility = "visibility",
  Title = "title",
  Location = "location",
  Update = "update",
  NotificationToAllPolygons = "notificationToAllPolygons",
}

interface Props {
  location: string | Coords
  psn: PSN
  mapInit: boolean
  onChange: (incident: IncidentFormState) => void
  numNotified: number
  levels: ClearanceLevel[]
  defaultIncident: IncidentFormState
  limitInputs?: IncidentFormInputType[]
  className?: string
}

enum NotifType {
  default = "",
  radius = "radius",
  polygon = "polygon",
}

const notifyMethodOptions = [
  { type: NotifType.default, option: "-" },
  { type: NotifType.polygon, option: "Current polygon" },
  { type: NotifType.polygon, option: "All polygons" },
  { type: NotifType.radius, option: "Radius" },
]

const radiusOptions = [1, 2, 5, 7, 10, 25, 100]

const IncidentForm: React.FC<Props> = ({
  mapInit,
  location,
  psn,
  onChange,
  numNotified,
  levels,
  defaultIncident,
  limitInputs,
  className,
}) => {
  const [incident, dispatch] = useReducer(incidentFormReducer, defaultIncident)
  const audienceOptions = levels.map(l => l.plural)

  const [selectedAudienceIndex, setSelectedAudienceIndex] = useState(0)
  const [selectedNotifIndex, setSelectedNotifIndex] = useState(0)
  const [selectedRadiusIndex, setSelectedRadiusIndex] = useState(0)

  const notificationMsg = (
    <Fragment>
      Send notification to
      <b>{` ${numNotified} users `}</b>
      at all polygons in {psn.name}.
    </Fragment>
  )
  const visibilityMapping = {
    [IncidentFormInputType.Visibility]: true,
    [IncidentFormInputType.Title]: true,
    [IncidentFormInputType.Location]: true,
    [IncidentFormInputType.Update]: true,
    [IncidentFormInputType.NotificationToAllPolygons]: true,
  }

  if (limitInputs) {
    Object.keys(visibilityMapping).forEach((p: string): void => {
      if (limitInputs.indexOf(p as IncidentFormInputType) === -1) {
        visibilityMapping[p as IncidentFormInputType] = false
      }
    })
  }

  useEffect(() => {
    let markers = Map<string, MarkerProps>()
    let center
    if (typeof location === "string") {
      const poly = psn.polygonsMapping.get(location)
      if (poly) {
        const pCenter = getCenterOfPolygon(poly)
        center = {
          lat: pCenter.geometry.coordinates[0],
          lng: pCenter.geometry.coordinates[1],
        }
      }
    } else {
      center = location
    }

    if (center) {
      markers = markers.set("pin", {
        ...center,
        id: "pin",
        type: MarkerType.Pin,
        fill: "#4DA6FF",
        focused: true,
      })
      emitter.emit(EventType.SetMarkers, markers)
      const setLocation = async ({ lat, lng }: Coords): Promise<void> => {
        const geo = await getPlaceFromLatLng({ lat, lng })
        if (geo) {
          const l = await extract(geo, psn.name)
          dispatch({ type: ActionType.SetLocation, data: l })
        }
      }

      if (mapInit) {
        setLocation(center)
      }
    }
  }, [location, mapInit, psn.name, psn.polygonsMapping])

  useEffect(() => {
    onChange(incident)
  }, [incident, onChange])

  return (
    <div className={classnames(styles.container, className)}>
      {visibilityMapping[IncidentFormInputType.Visibility] && (
        <div className={styles.form}>
          <div className={styles.labels}>
            <Body type={BodyType.Bold}>Restriction settings</Body>
            <Small type={SmallType.Regular} className={styles.description}>
              Select who can see this incident on the Citizen app.
            </Small>
          </div>
          <div className={styles.input}>
            <Dropdown
              options={audienceOptions.map(a => capitalize(a))}
              selectedIndex={selectedAudienceIndex}
              onChange={(index: number): void => {
                dispatch({
                  type: ActionType.SetAudience,
                  data: levels[index].level,
                })
                setSelectedAudienceIndex(index)
              }}
            />
          </div>
        </div>
      )}
      {visibilityMapping[IncidentFormInputType.Title] && (
        <div className={styles.form}>
          <div className={styles.labels}>
            <Body type={BodyType.Bold}>Title</Body>
          </div>
          <div className={styles.input}>
            <TextInput
              placeholder='Enter a title'
              onChange={debounce(
                (value: string): void =>
                  dispatch({ type: ActionType.SetTitle, data: value }),
                200,
              )}
              defaultValue={incident.title}
            />
          </div>
        </div>
      )}
      {visibilityMapping[IncidentFormInputType.Location] && (
        <div className={styles.form}>
          <div className={styles.labels}>
            <Body type={BodyType.Bold}>Location</Body>
            <Small type={SmallType.Regular} className={styles.description}>
              Tip: Drag pin to more precise location
            </Small>
          </div>
          <div className={styles.input}>
            <LocationInput
              onSelect={async (geo): Promise<void> => {
                const location = await extract(geo, psn.name)
                dispatch({ type: ActionType.SetLocation, data: location })
              }}
              onChange={noop}
              placeholder='Enter a location'
              // uncomment to lock location and set to psn name
              // lockDisplayTo={psn.name}
            />
          </div>
        </div>
      )}
      {visibilityMapping[IncidentFormInputType.Update] && (
        <div className={styles.form}>
          <div className={styles.labels}>
            <Body type={BodyType.Bold}>Update</Body>
            <Small type={SmallType.Regular} className={styles.description}>
              Provide a desriptive update of what’s happening.
            </Small>
          </div>
          <div className={styles.input}>
            <TextArea
              placeholder='Enter an update'
              onChange={debounce(
                (value: string): void =>
                  dispatch({ type: ActionType.SetUpdate, data: value }),
                200,
              )}
            />
          </div>
        </div>
      )}

      <div className={styles.form}>
        <div className={styles.labels}>
          <Small type={SmallType.Regular} className={styles.description}>
            Notify method
          </Small>
        </div>
        <div className={styles.input}>
          <Dropdown
            options={notifyMethodOptions.map(opt => opt.option)}
            selectedIndex={selectedNotifIndex}
            onChange={(index: number): void => {
              setSelectedNotifIndex(index)
              const { type } = notifyMethodOptions[index]
              switch (type) {
                case NotifType.polygon: {
                  const { option } = notifyMethodOptions[index]
                  if (option === "Current polygon") {
                    dispatch({
                      type: ActionType.SetCurrentPolygonNotification,
                      data: true,
                    })
                  } else if (option === "All polygons") {
                    dispatch({
                      type: ActionType.SetSendNotificationToAllPolygons,
                      data: true,
                    })
                  }
                }
                case NotifType.radius: {
                  dispatch({
                    type: ActionType.SetNotifRadius,
                    data: 1,
                  })
                }
                default:
                  dispatch({
                    type: ActionType.ClearPolygonNotification,
                    data: true,
                  })
                  break
              }
            }}
          />

          {notifyMethodOptions[selectedNotifIndex].type ===
            NotifType.radius && (
            <div className={styles.input}>
              <Dropdown
                options={radiusOptions.map(opt => `${opt} miles`)}
                selectedIndex={selectedRadiusIndex}
                onChange={(index: number): void => {
                  setSelectedRadiusIndex(index)
                  dispatch({
                    type: ActionType.SetNotifRadius,
                    data: radiusOptions[index],
                  })
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IncidentForm
