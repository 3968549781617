export enum ActionType {
  SetProperty = "set property",
  ResetState = "reset state",
}

interface Action {
  type: ActionType
  data?: any
}

const reducer = (state: any, action: Action) => {
  let newState = { ...state }

  switch (action.type) {
    case ActionType.SetProperty:
      if (newState[action.data.property]) {
        delete newState[action.data.property]
      } else {
        newState[action.data.property] = !newState[action.data.property]
      }
      break
    case ActionType.ResetState:
      newState = {}
      break
    default:
      break
  }

  return newState
}

export default reducer
