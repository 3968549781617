import React, { useRef, useContext } from "react"
import Container from "components/Common/Container"
import Body, { BodyType } from "components/Texts/Body"
import styles from "./CSVUpload.module.css"
import Spacing from "components/Common/Spacing"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import useCSVUpload from "./CSVUpload.hook"
import PSNContext from "components/Contexts/PSNContext"
import { round } from "lodash"
import classnames from "classnames"
import Small, { SmallType } from "components/Texts/Small"

interface Props {
  className?: string
}

const CSVUpload: React.FC<Props> = ({ className }) => {
  const fileUploader = useRef<HTMLInputElement>(null)
  const psn = useContext(PSNContext)
  const { onChange, file, onUpload, uploading, error } = useCSVUpload(
    psn && psn.id,
  )

  return (
    <Container
      column
      className={classnames(styles.container, className)}
      top={15}
      bottom={15}
      left={20}
      topAlign
    >
      <Spacing bottom={12}>
        <img src='/icons/file-plus.svg' alt='logo' className={styles.image} />
      </Spacing>
      <h3>Connect members via CSV</h3>
      <Body type={BodyType.Regular} className={styles.body}>
        Download, fill in, and upload the CSV file template to add members to
        your organization. You can set multiple locations and groups
      </Body>
      {file && (
        <Container top={4} bottom={4} fullWidth>
          <Container fill leftJustify>
            <Body type={BodyType.Bold}>{file.name}</Body>
          </Container>
          <Body type={BodyType.Bold}>{round(file.size / 1000, 3)}KB</Body>
        </Container>
      )}
      {file && <Container className={styles.bar} fullWidth></Container>}
      {file && error && (
        <Container fullWidth leftJustify top={2}>
          <Small type={SmallType.Regular} className={styles.error}>
            File error. Please try again.
          </Small>
        </Container>
      )}
      <Spacing top={error ? 4 : 10} bottom={4}>
        {!file && (
          <a href='/template.csv' download>
            <Button
              type={ButtonType.Secondary}
              size={ButtonSize.Medium}
              className={styles.button}
              customBody
            >
              <h5>Download template</h5>
            </Button>
          </a>
        )}
        {file && (
          <Button
            type={ButtonType.Secondary}
            size={ButtonSize.Medium}
            className={styles.button}
            customBody
            onClick={() => {
              if (fileUploader && fileUploader.current) {
                fileUploader.current.click()
              }
            }}
          >
            <h5>select file</h5>
          </Button>
        )}
      </Spacing>
      <input
        ref={fileUploader}
        type='file'
        onChange={onChange}
        title='Upload CSV'
        style={{
          display: "none",
        }}
        accept='.csv'
      />
      <Button
        type={ButtonType.Primary}
        size={ButtonSize.Medium}
        className={styles.button}
        customBody
        onClick={() => {
          if (!file && fileUploader && fileUploader.current) {
            fileUploader.current.click()
          } else if (file) {
            onUpload()
          }
        }}
      >
        <h5>{uploading ? "Uploading..." : "Upload CSV"}</h5>
      </Button>
    </Container>
  )
}

export default CSVUpload
