import React from "react"
import { ClearanceLevel } from "types/psn"
import Body, { BodyType } from "components/Texts/Body"
import styles from "./LevelBadge.module.css"
import classnames from "classnames"

interface Props {
  className?: string
  text?: string
}

const LevelBadge: React.FC<ClearanceLevel & Props> = ({
  plural,
  className,
  text,
}) => {
  return (
    <div className={classnames(styles.badge, className)}>
      <Body type={BodyType.Bold}>{text || plural}</Body>
    </div>
  )
}

export default LevelBadge
