import { OptimusAPI, getAuthToken } from "./index"
import { Observable, from } from "rxjs"
import { AxiosResponse } from "axios"
import { Incident } from "types/incident"
import { State as IncidentCreationState } from "components/Form/IncidentForm/incidentFormReducer"
import { getNotificationConfig } from "utils/notification"

export const previewNotification = (
  psnId: string,
  incident: Incident | IncidentCreationState,
): Observable<AxiosResponse<number>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psnId}/notification_recipients`,
      { configs: [getNotificationConfig(incident)] },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export const sendNotification = (
  psnId: string,
  incident: Incident,
  text: string,
  polygonIds?: string[],
): Observable<AxiosResponse<void>> | void => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.post(
      `/psn/${psnId}/incident/${incident.id}/notify`,
      {
        configs: [getNotificationConfig(incident)],
        text,
        polygonIds,
      },
      { headers: { "x-access-token": citizenJWT } },
    ),
  )
}

export const getNotificationPolygon = (
  type: string,
  name: string,
  code?: string,
): Observable<AxiosResponse<{ geometry: { coordinates: number[][][] } }>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get("/geo/geojson", {
      params: { type, name, code },
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export default {
  previewNotification,
  sendNotification,
}
