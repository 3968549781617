import { createBrowserHistory } from "history"

const history = createBrowserHistory()

export const push = (pathname: string, state: any): void => {
  if (!history.location.pathname.endsWith(pathname) || state) {
    history.push(pathname, { ...state, from: window.location.href })
  }
}

export const constructTo = (
  pathname: string,
): { pathname: string; state: { from: string } } => {
  return {
    pathname,
    state: {
      from: window.location.href,
    },
  }
}

export const replace = history.replace

export const shouldReplace = (pathname: string): boolean => {
  if (history.location.pathname.endsWith(pathname)) {
    return true
  }
  return false
}

export default history
