import { Coords } from "google-map-react"

export interface RadiusType {
  setMap: (m: any) => {}
}

export interface RadiusOptions {
  strokeColor?: string
  strokeOpacity?: number
  strokeWeight?: number
  fillColor?: string
  fillOpacity?: number
}

const Radius = (
  map: any,
  center: Coords,
  radius: number,
  options: RadiusOptions = {},
): RadiusType => {
  const circ = new map.maps.Circle({
    center,
    radius, // in meters
    strokeColor: options.strokeColor || "#ffb300",
    strokeOpacity: options.strokeColor || 0.1,
    strokeWeight: options.strokeWeight || 1,
    fillColor: options.fillColor || "#ffb300",
    fillOpacity: options.fillOpacity || 0.3,
    clickable: false,
  })
  circ.setMap(map.map)

  return circ
}

export default Radius
