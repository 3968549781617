import React from "react"
import styles from "./FadeIn.module.css"
import classnames from "classnames"

interface Props {
  children: any
  duration: number // in milliseconds
  delay: number // in milliseconds
  className?: string
  width?: number | string
}

const FadeIn: React.FC<Props> = ({
  children,
  duration,
  delay,
  className,
  width,
}) => {
  return (
    <div
      className={classnames(styles.container, className)}
      style={{
        animationDuration: `${duration}ms`,
        animationDelay: `${delay}ms`,
        width,
      }}
    >
      {children}
    </div>
  )
}

export default FadeIn
