import {
  bbox,
  polygon,
  centroid,
  random,
  inside,
  point,
  multiPolygon,
} from "turf"
import { Props as Marker, MarkerType } from "components/Map/Markers/Marker"
import { Incident } from "types/incident"
import { getSeverityStyling } from "./incident"
import { List } from "immutable"
import { Coords } from "google-map-react"
import { Feature, Point, GeoJsonProperties } from "geojson"
import { UserLocation } from "types/psn"
import { PublicIncident } from "api/marketing"
import {
  Props as MarketingMarker,
  MarketingMarkerType,
} from "components/Marketing/MarketingMap/MarketingMarker"
import { flatten } from "lodash"

export const getCenterOfPolygon = (
  coords: List<Coords>,
): Feature<Point, GeoJsonProperties> => {
  const poly = coords
    .valueSeq()
    .toArray()
    .map(c => [c.lat, c.lng])

  return centroid(polygon([poly]))
}

export const getBoundingBoxOfPolygon = (coords: List<Coords>): number[] => {
  const poly = coords
    .valueSeq()
    .toArray()
    .map(c => [c.lat, c.lng])

  return bbox(polygon([poly]))
}

export const getBoundBoxOfPolygons = (coords: List<Coords>[]): number[] => {
  const polys = coords.map(poly =>
    poly
      .valueSeq()
      .toArray()
      .map(c => [c.lat, c.lng]),
  )

  return bbox(multiPolygon([polys]))
}

export const getMarkerFromIncident = (i: Incident, psnId: string): Marker => {
  const styles = getSeverityStyling(i)

  const marker = {
    fill: styles.fill,
    lat: i.location.lat,
    lng: i.location.lng,
    id: i.id,
    type: MarkerType.Incident,
    style: {
      boxShadow: styles.boxShadow,
    },
    focused: false,
    model: i,
    link: `/${psnId}/incidents/${i.id}`,
  }

  return marker
}

export const getMarkerFromPublicIncident = (
  i: PublicIncident,
): MarketingMarker => {
  const styles = {
    fill: "#F2243C",
    boxShadow:
      "0px 1px 1px rgba(48, 48, 48, 0.3), 0px 0px 12px rgba(242, 36, 60, 0.3)",
  }

  const marker = {
    fill: styles.fill,
    lat: i.latitude,
    lng: i.longitude,
    id: i.incident_id,
    type: MarketingMarkerType.Incident,
    style: {
      boxShadow: styles.boxShadow,
    },
    focused: false,
    model: i,
  }

  return marker
}

export const getMarkerFromUserLocation = (l: UserLocation): Marker => {
  const marker = {
    fill: "#030303",
    lat: l.lat,
    lng: l.long,
    id: l.userId,
    type: MarkerType.User,
    focused: false,
  }

  return marker
}

export const roundLatLng = (latLng: Coords, decimalPlace = 0): Coords => {
  const divide = Math.pow(10, decimalPlace)

  return {
    lat: Math.round(latLng.lat * divide) / divide,
    lng: Math.round(latLng.lng * divide) / divide,
  }
}

export const isInPolygon = (p: number[], coords: List<Coords>): boolean => {
  const poly = coords
    .valueSeq()
    .toArray()
    .map(c => [c.lat, c.lng])

  return inside(point(p), polygon([poly]))
}

export const getLocationsWithinPolygon = (
  count: number,
  polygon: List<Coords>,
): List<Coords> => {
  const points = random("points", count * 2, {
    bbox: getBoundingBoxOfPolygon(polygon),
  })
  let locations = List<Coords>()

  for (const i in points.features) {
    const p = points.features[i].geometry.coordinates
    if (isInPolygon(p, polygon)) {
      locations = locations.push({ lat: p[0], lng: p[1] })
    }

    if (locations.size >= count) {
      break
    }
  }

  return locations
}

export const convertPolygonToCoords = (p: number[][][]): List<Coords> => {
  return List(
    flatten(p).map(([lng, lat]) => ({
      lat,
      lng,
    })),
  )
}
