import React, { useState, useEffect, FunctionComponent } from "react"
import styles from "./Checkbox.module.css"
import classnames from "classnames"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  description?: any
  onChange?: (value: boolean) => void
  className?: string
}

const Checkbox: FunctionComponent<Props> = ({
  description,
  onChange,
  className,
}) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (onChange) {
      onChange(checked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  return (
    <div
      className={classnames(styles.container, className, {
        [styles.checked]: checked,
      })}
    >
      <button
        className={styles.checkbox}
        onClick={(): void => {
          setChecked(!checked)
        }}
      >
        {checked && <img src='/icons/check.svg' alt='check' />}
      </button>
      <Body className={styles.description} type={BodyType.Regular}>
        {description}
      </Body>
    </div>
  )
}

export default Checkbox
