import { useEffect } from "react"

const useScroll = (
  element: Element | null | Window,
  onScroll: (e: any) => void,
): void => {
  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", onScroll)
    }

    return (): void => {
      if (element) {
        element.removeEventListener("scroll", onScroll)
      }
    }
  }, [element, onScroll])
}

export default useScroll
