import React, { useContext } from "react"
import Container from "components/Common/Container"
import useMembersPage from "./MembersPage.hook"
import Table from "components/Table/Table"
import styles from "./MembersPage.module.css"
import PSNContext from "components/Contexts/PSNContext"
import UserContext from "components/Contexts/UserContext"
import usePSNUser from "components/Hooks/usePSNUser"
import { sumBy } from "lodash"
import TestingModal from "./TestingModal/TestingModal"
import SymptomTrackingModal from "./SymptomTrackingModal/SymptomTrackingModal"
import Dropdown from "components/Form/Dropdown"

const MembersPage: React.FC = () => {
  const psn = useContext(PSNContext)
  const user = useContext(UserContext)
  const { isAdmin } = usePSNUser(psn && psn.id, user)
  const {
    members,
    headers,
    onNext,
    onPrev,
    pages,
    currentPage,
    testingModal,
    onTestingModalClose,
    symptomTrackingModal,
    onSymptomTrackingModalClose,
    onPageClick,
    locations,
    onLocationSelect,
    locationFilter,
  } = useMembersPage(psn && psn.id, isAdmin)
  const minWidth = sumBy(headers, h => h.width || 0)

  if (!psn) {
    return null
  }

  return (
    <Container fill column style={{ minWidth, overflowX: "auto" }}>
      <Container fullWidth className={styles.heading} leftJustify left={8}>
        <Container className={styles.dropdown}>
          <Dropdown
            options={locations}
            selectedIndex={locationFilter}
            onChange={onLocationSelect}
          />
        </Container>
      </Container>
      <Table
        headers={headers}
        rows={members}
        onNext={onNext}
        onPrev={onPrev}
        pages={pages}
        currentPage={currentPage}
        onPageClick={onPageClick}
      />
      <TestingModal data={testingModal} onClose={onTestingModalClose} />
      <SymptomTrackingModal
        data={symptomTrackingModal}
        onClose={onSymptomTrackingModalClose}
      />
    </Container>
  )
}

export default MembersPage
