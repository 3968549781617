import React, { useState, FunctionComponent } from "react"
import TextInput from "./TextInput"
import styles from "./PasswordInput.module.css"
import classnames from "classnames"
import inputs from "styles/inputs.module.css"
import Small, { SmallType } from "components/Texts/Small"

interface Props {
  maxLength?: number
  onChange: (val: string) => void
  error?: string
  onEnter?: () => void
  className?: string
}

const PasswordInput: FunctionComponent<Props> = ({
  maxLength,
  onChange,
  error,
  onEnter,
  className,
}) => {
  const [show, setShow] = useState(false)
  const [focused, setFocused] = useState(false)

  return (
    <div>
      <div
        className={classnames(styles.container, className, {
          [inputs.focused]: focused,
          [styles.focused]: focused,
          [styles.error]: error,
        })}
      >
        <TextInput
          className={styles.inputContainer}
          inputClassName={styles.input}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={(): void => setFocused(true)}
          onBlur={(): void => setFocused(false)}
          onKeyDown={(e): void => {
            if (e.key === "Enter" && onEnter) {
              onEnter()
            }
          }}
          hideInput={!show}
          placeholder='Enter password'
        />
        <button className={styles.icon} onClick={(): void => setShow(!show)}>
          <i
            className={classnames("fas", {
              "fa-eye": show,
              "fa-eye-slash": !show,
            })}
          ></i>
        </button>
      </div>
      {error && (
        <div className={classnames(styles.description, styles.error)}>
          <Small type={SmallType.Regular}>{error}</Small>
        </div>
      )}
    </div>
  )
}

export default PasswordInput
