import React, { FunctionComponent } from "react"
import styles from "./MapControls.module.css"

interface Props {
  onZoomIn: () => void
  onZoomOut: () => void
  onCenter?: () => void
}

const MapControls: FunctionComponent<Props> = ({
  onZoomIn,
  onZoomOut,
  onCenter,
}) => {
  return (
    <div className={styles.controls}>
      {onCenter && (
        <button className={styles.recenter} onClick={onCenter}>
          <div className={styles.inner}>
            <img src='/icons/recenter.svg' alt='recenter' />
          </div>
        </button>
      )}
      <div className={styles.zoom}>
        <button onClick={onZoomIn}>
          <div className={styles.inner}>
            <img src='/icons/plus.svg' alt='zoom-in' />
          </div>
        </button>
        <button onClick={onZoomOut}>
          <div className={styles.inner}>
            <img src='/icons/zoom-out.svg' alt='zoom-out' />
          </div>
        </button>
      </div>
    </div>
  )
}

export default MapControls
