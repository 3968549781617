import { useEffect } from "react"
import emitter, { EventType } from "emitter"
import { EventSubscription } from "fbemitter"
import _ from "lodash"

const useEmitter = (eventMapping: { [event: string]: Function }): void => {
  useEffect(() => {
    let subscriptions: EventSubscription[] = []

    subscriptions = (_.map(
      eventMapping,
      (callback: Function, event: EventType): EventSubscription => {
        return emitter.addListener(event, callback)
      },
    ) as unknown) as EventSubscription[]

    return (): void => {
      subscriptions.forEach(s => s.remove())
    }
  }, [eventMapping])
}

export default useEmitter
