import React from "react"
import styles from "./VerifiedBadge.module.css"

const VerifiedBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <div className={styles.icon}>
        <img src='/icons/check.svg' alt='check' />
      </div>
      <h5>Verified</h5>
    </div>
  )
}

export default VerifiedBadge
