import React, { useState } from "react"
import styles from "./PasswordScreen.module.css"
import { validatePassword, login } from "api/user"
import { User } from "types/user"
import PasswordInput from "components/Form/PasswordInput"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import Body, { BodyType } from "components/Texts/Body"
import Small, { SmallType } from "components/Texts/Small"

interface Props {
  onSubmit: (userId: string, password?: string) => void
  phone: string
  user?: User
}

const PasswordScreen: React.FC<Props> = ({ onSubmit, phone, user }) => {
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const submit = (): void => {
    if (!user) {
      setLoading(true)
      validatePassword(phone, password).subscribe(
        ({ data }) => {
          login(data.userToken)
          onSubmit(data.uid)
          setLoading(false)
        },
        err => {
          setError(err.message || "Something went wrong")
          setLoading(false)
        },
      )
    } else {
      if (!password) {
        setError("Please enter a password.")
      } else if (password.length < 8) {
        setError("Password must be at least 8 characters.")
      } else if (password.length > 16) {
        setError("Password can be at most 16 characters.")
      } else {
        onSubmit(user.id, password)
      }
    }
  }

  const disabled = password.length < 8 || password.length > 16
  return (
    <div className={styles.container}>
      <Body type={BodyType.Regular} className={styles.text}>
        {user ? "Create a password" : "Enter your password"}
      </Body>
      <Small type={SmallType.Regular} className={styles.subtext}>
        8 characters minimum, 16 characters maximum
      </Small>
      <div className={styles.input}>
        <PasswordInput
          maxLength={17}
          onChange={(val): void => {
            if (error) {
              setError(error)
            }
            setPassword(val)
          }}
          error={error}
          onEnter={!disabled ? submit : undefined}
        />
      </div>
      <Button
        className={styles.button}
        type={disabled ? ButtonType.Disabled : ButtonType.Primary}
        size={ButtonSize.Large}
        onClick={submit}
      >
        {loading ? "Fetching..." : "Next"}
      </Button>
    </div>
  )
}

export default PasswordScreen
