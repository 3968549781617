import React, { useState, useEffect } from "react"
import { TestingStatus, setMemberTesting } from "api/member"
import dayjs from "dayjs"

enum TestingOptions {
  Positive = "Positive test",
  Negative = "Negative test",
}
const OPTIONS = [TestingOptions.Positive, TestingOptions.Negative]

const useTestingModal = (
  onClose: (refetch?: boolean) => void,
  data?: any,
  psn?: string,
) => {
  const [date, setDate] = useState(new Date())
  const [selected, setSelected] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data && data.testStatus && data.testStatus.result) {
      setSelected(
        data.testStatus.result === TestingStatus.Positive
          ? TestingOptions.Positive
          : TestingOptions.Negative,
      )
      setDate(dayjs(data.testStatus.testDate).toDate())
    } else if (data) {
      setSelected(undefined)
      setDate(new Date())
    }
    setLoading(false)
  }, [data])

  const onSubmit = () => {
    if (psn) {
      setLoading(true)
      setMemberTesting(
        psn,
        data.phone,
        TestingOptions.Positive === selected
          ? TestingStatus.Positive
          : TestingStatus.Negative,
        date,
      ).subscribe(() => onClose(true))
    }
  }

  return {
    options: OPTIONS,
    date,
    setDate,
    selected,
    setSelected,
    onSubmit,
    loading,
  }
}

export default useTestingModal
