import React, { FunctionComponent, useEffect } from "react"
import FadeIn from "components/Animations/FadeIn"

interface Props {
  duration: number // in ms
  show: boolean
  onHide: () => void
  id?: string
}

const ANIMATION_DURATION = 500

const Popup: FunctionComponent<Props> = ({
  children,
  duration,
  show,
  onHide,
  id,
}) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (show) {
      timeout = setTimeout(onHide, duration)
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, show])

  useEffect(() => {
    return onHide
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (!show) {
    return null
  }

  return (
    <FadeIn duration={ANIMATION_DURATION} delay={0}>
      <div>{children}</div>
    </FadeIn>
  )
}

export default Popup
