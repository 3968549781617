import React from "react"
import ReactPlayer from "react-player/lib/players/FilePlayer"
import styles from "./StreamPlayer.module.css"
import VerifiedBadge from "components/Badges/VerifiedBadge"
import classnames from "classnames"

interface Props {
  url: string
  live: boolean
  id: string
  verified: boolean
  className?: string
  playing?: boolean
  style?: any
  muted?: boolean
  controls?: boolean
}

const StreamPlayer: React.FC<Props> = ({
  url,
  live,
  id,
  verified,
  className,
  playing,
  style,
  muted = true,
  controls = true,
}) => {
  return (
    <div className={classnames(className, styles.container)} style={style}>
      <ReactPlayer
        url={url}
        width='100%'
        height='100%'
        controls={controls}
        playing={playing}
        light
        muted={muted}
        config={{
          file: {
            attributes: {
              volume: 0,
              muted,
              loop: true,
              playsInline: true,
            },
          },
        }}
      />
      {verified && (
        <div className={styles.top}>
          <VerifiedBadge />
        </div>
      )}
    </div>
  )
}

export default React.memo(StreamPlayer)
