import React, { FunctionComponent } from "react"
import PSNContext from "components/Contexts/PSNContext"
import styles from "./MainMenu.module.css"
import { shouldReplace, constructTo } from "utils/history"
import { Link } from "react-router-dom"
import classnames from "classnames"
import Body, { BodyType } from "components/Texts/Body"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"
import AppDropdown from "./AppDropdown/AppDropdown"

export interface MenuItem {
  id: string
  label: string
  count: number
  path: string
}

export interface Section {
  items: MenuItem[]
  heading?: string
}

interface Props {
  sections: Section[]
  selectedItem: string
  title?: string
  onOverflowMenuClick?: (e: React.MouseEvent) => void
  activeOverflowMenu?: string
  safeTraceEnabled?: boolean
}

export const MainMenu: FunctionComponent<Props & { logo: string }> = ({
  sections,
  selectedItem,
  title,
  logo,
  onOverflowMenuClick,
  activeOverflowMenu,
  safeTraceEnabled,
}) => {
  return (
    <div className={styles.menu}>
      {logo && (
        <div className={styles.top}>
          <div className={styles.logo}>
            <img src={logo} alt='logo' />
          </div>
        </div>
      )}
      {safeTraceEnabled && <AppDropdown />}
      {title && !safeTraceEnabled && <h2>{title}</h2>}
      <div className={styles.items}>
        {sections.map((section, i) => {
          const { items, heading } = section
          return (
            <div key={heading || i} className={styles.section}>
              {!!heading && <h5>{heading}</h5>}
              {items.map(item => {
                return (
                  <div
                    className={classnames(styles.itemContainer, {
                      [styles.selected]: selectedItem === item.id,
                      [styles.hasMenu]:
                        selectedItem === item.id && !!onOverflowMenuClick,
                      [styles.active]: activeOverflowMenu === item.id,
                    })}
                    key={item.id}
                  >
                    <Link
                      to={constructTo(item.path)}
                      replace={shouldReplace(item.path)}
                    >
                      <div className={styles.item}>
                        <h3>{item.label}</h3>
                        <div>
                          {!!item.count && (
                            <Body
                              type={BodyType.Regular}
                              className={styles.count}
                            >
                              {item.count}
                            </Body>
                          )}
                          {selectedItem === item.id && onOverflowMenuClick && (
                            <div className={styles.overflow}>
                              <Button
                                type={ButtonType.OverflowMenu}
                                size={ButtonSize.Medium}
                                onClick={onOverflowMenuClick}
                                data={{ "data-option": item.id }}
                                active={activeOverflowMenu === item.id}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Wrapper = (props: Props): any => {
  return (
    <PSNContext.Consumer>
      {(psn): any => {
        if (!psn) {
          return null
        } else {
          return <MainMenu {...props} logo={psn.logoUrl} />
        }
      }}
    </PSNContext.Consumer>
  )
}

export default React.memo(Wrapper)
