import { PSN } from "components/Contexts/PSNContext"
import { get, last } from "lodash"

enum SafeTraceMenuItem {
  Dashboard = "Dashboard",
  Organization = "Organization",
  Members = "Member Activity",
  None = "None",
}

const useSafeTraceBase = (path: string, psn?: PSN) => {
  const items = [
    {
      id: SafeTraceMenuItem.Dashboard,
      label: SafeTraceMenuItem.Dashboard,
      path: `/${get(psn, "id", "")}/safetrace/dashboard`,
      count: 0,
    },
    {
      id: SafeTraceMenuItem.Members,
      label: SafeTraceMenuItem.Members,
      path: `/${get(psn, "id", "")}/safetrace/members`,
      count: 0,
    },
    {
      id: SafeTraceMenuItem.Organization,
      label: SafeTraceMenuItem.Organization,
      path: `/${get(psn, "id", "")}/safetrace/organization`,
      count: 0,
    },
  ]
  const sections = [
    {
      items,
    },
  ]

  const getActiveMenuItem = () => {
    switch (last(path.split("/"))) {
      case "dashboard":
        return SafeTraceMenuItem.Dashboard
      case "organization":
        return SafeTraceMenuItem.Organization
      case "members":
        return SafeTraceMenuItem.Members
      default:
        return ""
    }
  }

  return {
    sections,
    activeMenuItem: getActiveMenuItem(),
  }
}

export default useSafeTraceBase
