import { useEffect } from "react"

const useBlurListener = (node: any, onBlur: () => void) => {
  useEffect(() => {
    const click = ({ target }: any): void => {
      if (node && !node.contains(target) && node !== target) {
        onBlur()
      }
    }
    document.addEventListener("click", click)

    return (): void => {
      document.removeEventListener("click", click)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])
}

export default useBlurListener
