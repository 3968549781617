import React from "react"
import classnames from "classnames"
import styles from "./SafeTraceRadioButton.module.css"
import Container from "components/Common/Container"
import Body, { BodyType } from "components/Texts/Body"

interface Props {
  on?: boolean
  displayName: string
  onClick: (option: string) => void
}

const SafeTraceTraceRadioButton: React.FC<Props> = ({
  on,
  displayName,
  onClick,
}) => {
  return (
    <button
      className={classnames(styles.button, {
        [styles.on]: on,
      })}
      onClick={() => onClick(displayName)}
    >
      <Container leftJustify>
        <Container
          className={classnames(styles.check, {
            [styles.on]: on,
          })}
        >
          {on && <img src='/icons/check.svg' alt="check" />}
        </Container>
        <Container fill leftJustify left={3}>
          <Body type={BodyType.Bold} className={styles.name}>
            {displayName}
          </Body>
        </Container>
      </Container>
    </button>
  )
}

export default SafeTraceTraceRadioButton
