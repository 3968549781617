import React, { useState } from "react"
import styles from "./NavBar.module.css"
import classnames from "classnames"
import useScroll from "components/Hooks/useScroll"

interface Props {
  onClaim: () => void
  overrideCta?: string
}

const NavBar: React.FC<Props> = ({ onClaim, overrideCta }) => {
  const [background, setBackground] = useState(false)
  useScroll(window, () => {
    const show = window.scrollY > 0
    if (show !== background) {
      setBackground(show)
    }
  })

  return (
    <div
      className={classnames(styles.container, {
        [styles.background]: background,
      })}
    >
      <div className={styles.logo}>
        <img src='/citizen-logo.svg' alt='logo' />
      </div>
      <div className={styles.right}>
        <span>Call us at (646) 397-3766</span>
        <button className={styles.claim} onClick={onClaim}>
          {overrideCta || "Claim Now"}
        </button>
      </div>
    </div>
  )
}

export default React.memo(NavBar)
