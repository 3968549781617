import React, { useState, useEffect } from "react"
import styles from "./App.module.css"
import { Switch, Route, Router, Redirect } from "react-router-dom"
import history from "utils/history"
import MapBase from "components/MapBase/MapBase"
import Login from "components/Login/Login"
import { ErrorType } from "api"
import { getUser } from "api/user"
import UserContext from "components/Contexts/UserContext"
import { isEmpty } from "lodash"
import PSNPicker from "components/PSNPicker/PSNPicker"
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary"
import { User } from "types/user"
import "styles/fonts.module.css"
import config from "config"
import { Helmet } from "react-helmet"
import { identify } from "utils/analytics"
import Marketing from "components/Marketing/Marketing"
import SafeTraceBase from "components/SafeTraceBase/SafeTraceBase"
import CallbackPage from "views/CallbackPage/CallbackPage"

const App: React.FC = () => {
  const [user, setUser] = useState<User | {}>()
  const [loading, setLoading] = useState(true)
  const isDev = window.location.hostname.startsWith("localhost")

  useEffect(() => {
    if (
      window.location.protocol !== "https:" &&
      !window.location.hostname.startsWith("localhost")
    ) {
      window.location.assign(
        `https://${window.location.hostname}${window.location.pathname}`,
      )
    }

    if (!window.location.pathname.match("/claim/")) {
      try {
        getUser().subscribe(
          resp => {
            if (resp && resp.data) {
              setUser(resp.data)
            } else {
              setUser({})
            }
            setLoading(false)
          },
          e => {
            if (
              e.response.data.error &&
              e.response.data.error
                .toLowerCase()
                .match(ErrorType.InvalidToken.toLowerCase())
            ) {
              history.push("/login")
            }
          },
        )
      } catch (e) {
        if (e.message.match(ErrorType.NotAuthenticated)) {
          history.push("/login")
        }
      }
    }
  }, [])

  useEffect(() => {
    if (user && !isEmpty(user) && !isDev) {
      const u = user as User
      identify(u.id, { name: u.username, email: u.email })
    }
  }, [isDev, user])

  return (
    <ErrorBoundary>
      <Helmet>
        {!isDev && <script src='/scripts/ga.js' async />}
        {!isDev && (
          <script
            src='/scripts/segment.js'
            async
            data-key={config.segmentKey}
          />
        )}
      </Helmet>
      <Router history={history}>
        <UserContext.Provider
          value={isEmpty(user) ? undefined : (user as User)}
        >
          <div className={styles.app}>
            <Switch>
              <Route
                path='/login'
                render={(): any => (
                  <Login
                    onLogin={(u): void => setUser(u)}
                    user={isEmpty(user) ? undefined : (user as User)}
                  />
                )}
                exact
              />
              <Route
                path='/claim/:id'
                render={({
                  match: {
                    params: { id },
                  },
                  location: { hash },
                }): any => {
                  return <Marketing psnId={id} hash={hash} />
                }}
                exact
              />
              <Route
                path='/join/:id'
                render={({
                  match: {
                    params: { id },
                  },
                  location: { hash },
                }): any => {
                  return <Marketing psnId={id} hash={hash} generic />
                }}
                exact
              />
              <Route
                path='/join'
                render={({ location: { hash } }): any => {
                  const id = process.env.REACT_APP_PSN_DEMO_ID

                  if (!id) {
                    return null
                  }
                  return <Marketing psnId={id} hash={hash} generic />
                }}
                exact
              />
              <Route path='/auth/callback' component={CallbackPage} />
              {user && !isEmpty(user) && (
                <Route path='/:psn/safetrace' component={SafeTraceBase} />
              )}
              {user && !isEmpty(user) && (
                <Route path='/:psn' component={MapBase} />
              )}
              {user && !isEmpty(user) && (
                <Route
                  path='/'
                  render={(): any => (
                    <PSNPicker
                      onSelect={(p): void => {
                        history.push(`/${p.id}`)
                      }}
                      user={user as User}
                      onLogout={(): void => {
                        setUser(undefined)
                        history.push("/login")
                      }}
                    />
                  )}
                />
              )}
              {!loading && (
                <Redirect to={!user || isEmpty(user) ? "/login" : "/"} />
              )}
            </Switch>
          </div>
        </UserContext.Provider>
      </Router>
      <div id='modal-root' />
    </ErrorBoundary>
  )
}

export default App
