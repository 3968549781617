import { OptimusAPI, getAuthToken } from "./index"
import { Observable, from } from "rxjs"
import { AxiosResponse } from "axios"
import dayjs from "dayjs"

export interface SafetraceStatsResponse {
  memberCount: number

  participation: {
    numOnCitizen: number
    numWithSafePassDisabled: number
    percOnCitizen: number
    percWithSafePassDisabled: number
  }

  symptomTracking: {
    numCompleted: number
    numHasSymptoms: number
    percCompleted: number
    percHasSymptoms: number
  }

  testing: {
    numPositive: number
    percPositive: number
  }

  tracing: {
    numActive: number
    numPotentialExposure: number
    percActive: number
    percPotentialExposure: number
  }
}

export const getSafetraceStats = (
  id: string,
): Observable<AxiosResponse<SafetraceStatsResponse>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${id}/safetrace/stats`, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}

export const getSafetraceAggregateStats = (
  id: string,
  start_date: string = dayjs()
    .subtract(6, "day")
    .format("YYYY-MM-DD"),
  end_date: string = dayjs().format("YYYY-MM-DD"),
): Observable<AxiosResponse<{ [date: string]: SafetraceStatsResponse }>> => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${id}/safetrace/stats/daily_aggs`, {
      headers: { "x-access-token": citizenJWT },
      params: {
        start_date,
        end_date,
      },
    }),
  )
}

export const getSafetraceLocations = (id: string) => {
  const citizenJWT = getAuthToken()

  return from(
    OptimusAPI.get(`/psn/${id}/members/filter_values`, {
      headers: { "x-access-token": citizenJWT },
    }),
  )
}
