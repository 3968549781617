import React from "react"
import Container from "components/Common/Container"
import styles from "./Table.module.css"
import Body, { BodyType } from "components/Texts/Body"
import PaginationButton from "./PaginationButtom"
import Spacing from "components/Common/Spacing"
import { get } from "lodash"
import Pagination from "./Pagination"

export interface Header {
  display: string
  property: string
  width?: number
  getStyling?: (data: any) => any
  getDisplay?: (data: any) => any
  renderItem?: (data: any) => any
  onClick?: (data: any) => any
}

interface Props {
  headers: Header[]
  rows: any[]
  identifier?: string
  onPrev?: () => void
  onNext?: () => void
  pages: number
  currentPage: number
  onRowClick?: (data: any) => any
  onPageClick: (page: number) => void
}

const Table: React.FC<Props> = ({
  headers,
  rows,
  identifier,
  onPrev,
  onNext,
  pages,
  currentPage,
  onPageClick,
}) => {
  return (
    <Container fill column center={false} leftJustify>
      <Container fullWidth>
        <Container leftJustify fill>
          {headers.map(({ display, property, width }) => {
            return (
              <Container
                key={property}
                style={{ width }}
                className={styles.header}
                leftJustify
                fullWidth
              >
                <h6>{display}</h6>
              </Container>
            )
          })}
        </Container>
      </Container>
      {rows.map(data => {
        const key = identifier ? data[identifier] : data.id
        return (
          <Container key={key} className={styles.row} fullWidth leftJustify>
            {headers.map(
              ({
                property,
                width,
                getStyling,
                getDisplay,
                renderItem,
                onClick,
              }) => {
                const value = get(data, property, undefined)

                return (
                  <Container
                    key={`${key}-${property}`}
                    style={{
                      width,
                      ...(getStyling ? getStyling(value) : {}),
                    }}
                    leftJustify
                    onClick={onClick ? () => onClick(data) : undefined}
                  >
                    {renderItem ? (
                      renderItem(data)
                    ) : (
                      <>
                        <Body type={BodyType.Regular}>
                          {getDisplay ? getDisplay(data) : value}
                        </Body>
                        <Spacing left={3}>
                          {onClick && (
                            <img
                              className={styles.plus}
                              src='/icons/plus.svg'
                              alt='click'
                            />
                          )}
                        </Spacing>
                      </>
                    )}
                  </Container>
                )
              },
            )}
          </Container>
        )
      })}
      {!!pages && (
        <Container fullWidth top={10} bottom={10}>
          <Spacing right={4} left={4}>
            <PaginationButton onClick={onPrev} left />
          </Spacing>
          <Pagination totalPages={pages} currentPage={currentPage} onClick={onPageClick} />
          <Spacing right={4} left={4}>
            <PaginationButton onClick={onNext} />
          </Spacing>
        </Container>
      )}
    </Container>
  )
}

export default Table
