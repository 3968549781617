import React from "react"
import Container from "components/Common/Container"
import useDashboardSection from "./DashboardSection.hook"
import DashboardStat from "./DashboardStat"
import styles from "./DashboardSection.module.css"
import Spacing from "components/Common/Spacing"

interface Props {
  id: string
  label: string
  aggregateStats?: any
}

const DashboardSection: React.FC<Props> = ({ id, label, aggregateStats }) => {
  const { items } = useDashboardSection(id, aggregateStats)
  if (!aggregateStats) {
    return null
  }

  return (
    <Container column leftJustify className={styles.wrapper} left={2} top={8}>
      <Spacing bottom={4} left={4} right={3}>
        <Container fullWidth>
          <h5 className={styles.label}>{label}</h5>
          <Container className={styles.divider} fill />
        </Container>
      </Spacing>
      <Container
        className={styles.container}
        top={5}
        bottom={5}
        left={3}
        right={3}
        leftJustify
        topAlign
      >
        {items.map(item => {
          return (
            <DashboardStat
              key={item.id}
              percentageChange={item.percentageChange}
              calloutText={item.calloutText}
              subText={item.subText}
              type={item.type}
              data={item.data}
              positive={item.positive}
            />
          )
        })}
      </Container>
    </Container>
  )
}

export default DashboardSection
