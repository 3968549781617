import React from "react"
import Container from "components/Common/Container"
import GSuiteLogin from "./GSuiteLogin"
import CSVUpload from "./CSVUpload"
import styles from "./ImportOrganizationPage.module.css"

const ImportOrganizationPage: React.FC = () => {
  return (
    <Container fill>
      <Container topAlign>
        <GSuiteLogin />
        <CSVUpload className={styles.border} />
      </Container>
    </Container>
  )
}

export default ImportOrganizationPage
