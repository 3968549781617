import { round } from "lodash"
import { StatType } from "./DashboardStat"
import dayjs from "dayjs"
import { SafetraceStatsResponse } from "api/safetrace"
import { map } from "lodash"

export enum DashboardSectionItem {
  Participation = "Participation",
  SafeTrace = "SafeTrace",
  Symptoms = "Symptoms",
  Testing = "Testing",
}

export enum DashboardStatItem {
  HasCitizen = "HasCitizen",
  SafePassDisabled = "SafePassDisabled",
  HasSafeTrace = "HasSafeTrace",
  PotentialExposure = "PotentialExposure",
  SymptomTracking = "SymptomTracking",
  HasSymptoms = "HasSymptoms",
  ActiveCases = "ActiveCases",
}

const useDashboardSection = (id: string, aggregateStats: any) => {
  const getItems = (): any[] => {
    if (!aggregateStats) {
      return []
    }

    const today = dayjs()
      .format("YYYY-MM-DD")
    const yesterday = dayjs()
      .subtract(1, "day")
      .format("YYYY-MM-DD")

    const todayStats: SafetraceStatsResponse = aggregateStats[today]
    const yesterdayStats: SafetraceStatsResponse = aggregateStats[yesterday]
    let stats: any = {}
    let prevStats: any = {}
    let memberCount = todayStats.memberCount

    switch (id) {
      case DashboardSectionItem.Participation:
        stats = todayStats.participation
        prevStats = yesterdayStats.participation

        return [
          {
            id: DashboardStatItem.HasCitizen,
            percentageChange: stats.percOnCitizen - prevStats.percOnCitizen,
            calloutText: `${round(stats.percOnCitizen * 100)}%`,
            subText: `Members on Citizen: ${stats.numOnCitizen} out of ${memberCount}`,
            type: StatType.PieChart,
            positive: stats.percOnCitizen >= prevStats.percOnCitizen,
            data: [
              {
                name: "true",
                value: stats.numOnCitizen,
              },
              {
                name: "false",
                value: memberCount - stats.numOnCitizen,
                color: "#404040",
              },
            ],
          },
          {
            id: DashboardStatItem.SafePassDisabled,
            percentageChange:
              stats.percWithSafePassDisabled -
              prevStats.percWithSafePassDisabled,
            calloutText: `${round(stats.percWithSafePassDisabled * 100)}%`,
            subText: `Members with SafePass disabled: ${stats.numWithSafePassDisabled} out of ${memberCount}`,
            type: StatType.PieChart,
            positive:
              stats.percWithSafePassDisabled <=
              prevStats.percWithSafePassDisabled,
            data: [
              {
                name: "true",
                value: stats.numWithSafePassDisabled,
              },
              {
                name: "false",
                value: memberCount - stats.numWithSafePassDisabled,
                color: "#404040",
              },
            ],
          },
        ]
      case DashboardSectionItem.SafeTrace:
        stats = todayStats.tracing
        prevStats = yesterdayStats.tracing

        return [
          {
            id: DashboardStatItem.HasSafeTrace,
            percentageChange: stats.percActive - prevStats.percActive,
            calloutText: `${round(stats.percActive * 100)}%`,
            subText: `Members SafeTracing: ${stats.numActive} out of ${memberCount}`,
            type: StatType.PieChart,
            positive: stats.percActive >= prevStats.percActive,
            data: [
              {
                name: "true",
                value: stats.numActive,
              },
              {
                name: "false",
                value: memberCount - stats.numActive,
                color: "#404040",
              },
            ],
          },
          {
            id: DashboardStatItem.PotentialExposure,
            percentageChange: stats.percPotentialExposure - prevStats.percPotentialExposure,
            calloutText: stats.numPotentialExposure,
            subText: `Members with potential exposure to COVID-19`,
            type: StatType.BarGraph,
            positive:
              stats.percPotentialExposure <= prevStats.percPotentialExposure,
            data: map(
              aggregateStats,
              (stats: SafetraceStatsResponse, date: string, index: number) => {
                return {
                  name: date,
                  value: stats.tracing.numPotentialExposure,
                  color: date === today ? undefined : "#404040",
                }
              },
            ),
          },
        ]
      case DashboardSectionItem.Symptoms:
        stats = todayStats.symptomTracking
        prevStats = yesterdayStats.symptomTracking

        return [
          {
            id: DashboardStatItem.SymptomTracking,
            percentageChange: stats.percCompleted - prevStats.percCompleted,
            calloutText: `${round(stats.percCompleted * 100)}%`,
            subText: `Members completing symptom check today: ${stats.numCompleted} out of ${memberCount}`,
            type: StatType.PieChart,
            positive: stats.percCompleted >= prevStats.percCompleted,
            data: [
              {
                name: "true",
                value: stats.numCompleted,
              },
              {
                name: "false",
                value: memberCount - stats.numCompleted,
                color: "#404040",
              },
            ],
          },
          {
            id: DashboardStatItem.HasSymptoms,
            percentageChange: stats.percHasSymptoms - prevStats.percHasSymptoms,
            calloutText: stats.numHasSymptoms,
            subText: `Members with symptoms today`,
            type: StatType.BarGraph,
            positive: stats.percHasSymptoms <= prevStats.percHasSymptoms,
            data: map(
              aggregateStats,
              (stats: SafetraceStatsResponse, date: string, index: number) => {
                return {
                  name: date,
                  value: stats.symptomTracking.numHasSymptoms,
                  color: date === today ? undefined : "#404040",
                }
              },
            ),
          },
        ]
      case DashboardSectionItem.Testing:
        stats = todayStats.testing
        prevStats = yesterdayStats.testing

        return [
          {
            id: DashboardStatItem.ActiveCases,
            percentageChange: stats.percPositive - prevStats.percPositive,
            calloutText: stats.numPositive,
            subText: `Active COVID-19 cases`,
            type: StatType.BarGraph,
            positive: stats.percPositive <= prevStats.percPositive,
            data: map(
              aggregateStats,
              (stats: SafetraceStatsResponse, date: string, index: number) => {
                return {
                  name: date,
                  value: stats.testing.numPositive,
                  color: date === today ? undefined : "#404040",
                }
              },
            ),
          },
        ]
    }

    return []
  }

  return {
    items: getItems(),
  }
}

export default useDashboardSection
