import React from "react"
import Modal from "./Modal"
import styles from "./ConfirmationModal.module.css"
import Body, { BodyType } from "components/Texts/Body"
import Button, { ButtonType, ButtonSize } from "components/Buttons/Button"

export interface Props {
  title: string
  description?: string
  cancelText?: string
  confirmText?: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  description,
  cancelText = "Cancel",
  confirmText = "Confirm",
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.text}>
          <h2>{title}</h2>
          {description && (
            <Body className={styles.description} type={BodyType.Regular}>
              {description}
            </Body>
          )}
        </div>
        <div className={styles.actions}>
          <Button
            type={ButtonType.Subtle}
            size={ButtonSize.Medium}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            type={ButtonType.Primary}
            size={ButtonSize.Medium}
            onClick={onConfirm}
            className={styles.confirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
