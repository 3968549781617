import React, { useEffect, useState } from "react"
import classnames from "classnames"
import styles from "./MarketingMarker.module.css"
import emitter, { EventType } from "emitter"
import { PublicIncident, getPublicIncident, StreamLite } from "api/marketing"
import MarkerDetail from "./MarkerDetail/MarkerDetail"
import useResize from "components/Hooks/useResize"
import { track } from "utils/analytics"

export enum MarketingMarkerType {
  Incident = "incident",
  Location = "location",
}

export interface Props {
  fill?: string
  lat: number
  lng: number
  id: string
  style?: any
  focused: boolean
  model?: PublicIncident
  onClick?: (id: string, stream?: StreamLite, offsetY?: number) => void
  type: MarketingMarkerType
}

const Marker: React.FC<Props> = ({
  fill,
  style = {},
  focused,
  model,
  onClick,
  type = MarketingMarkerType.Incident,
  id,
}) => {
  const markerStyle: any = { backgroundColor: fill }
  const [stream, setStream] = useState()
  const [isMobile, setIsMobile] = useState(window.outerWidth <= 600)
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    if (model) {
      track("Marketing", "Show", "Incident", model.incident_id)
      getPublicIncident(model.incident_id).subscribe(({ data }) => {
        if (data.streams && data.streams.length) {
          setStream(data.streams[0])
        }
      })
    }
  }, [model])

  useResize(window, () => {
    const m = window.outerWidth <= 600
    if (isMobile !== m) {
      setIsMobile(m)
    }
  })

  if (type === MarketingMarkerType.Location) {
    return (
      <div key={id} className={styles.location} style={style}>
        <div className={styles.dot} />
      </div>
    )
  }

  if (!model) {
    return null
  }

  return (
    <div className={styles.wrapper} key={id}>
      <button
        className={classnames(styles.container, { [styles.focused]: focused })}
        onMouseEnter={(): void => {
          if (model) {
            emitter.emit(EventType.SetFocusedMarker, model.incident_id)
          }
        }}
        onMouseLeave={(): void => {
          if (model) {
            emitter.emit(EventType.SetFocusedMarker, undefined)
          }
        }}
        onClick={(e: React.MouseEvent): void => {
          if (isMobile) {
            setShowVideo(true)
          }

          if (onClick) {
            track("Marketing", "Click", "Incident Marker", model.incident_id)
            onClick(model.incident_id, isMobile ? (stream || null) : undefined, e.clientY)
          }
        }}
      >
        {focused && !isMobile && (
          <MarkerDetail
            stream={stream}
            className={styles.video}
            incident={model}
          />
        )}
        {focused && isMobile && !showVideo && (
          <div className={classnames(styles.tooltip, styles.info)}>
            {stream ? "Tap to see video" : "Tap to see details"}
          </div>
        )}
        {!focused && <div className={styles.tooltip}>{model.title}</div>}
        <div
          className={classnames(styles.marker, styles.incident)}
          style={style}
        >
          <div style={markerStyle} />
        </div>
      </button>
    </div>
  )
}

const areEqual = (prevProps: any, nextProps: any): boolean => {
  const compare: string[] = ["fill", "focused", "lat", "lng", "id"]

  return compare.filter(p => prevProps[p] !== nextProps[p]).length === 0
}

export default React.memo(Marker, areEqual)
