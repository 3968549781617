import React from "react"
import TextInput from "./TextInput"

interface Props {
  tabIndex?: number
  maxLength?: number
  placeholder?: string
  onChange: (val: string) => void
  className?: string
  error?: string
  onEnter?: () => void
  id?: string
  inputClassName?: string
  onBlur?: () => void
}

const NumberInput: React.FC<Props> = ({
  tabIndex,
  maxLength,
  placeholder,
  onChange,
  className,
  error,
  onEnter,
  id,
  inputClassName,
  onBlur,
}) => {
  return (
    <TextInput
      inputClassName={inputClassName}
      id={id}
      className={className}
      error={error}
      tabIndex={tabIndex}
      maxLength={maxLength}
      placeholder={placeholder}
      onKeyDown={(e): void => {
        const allowlist = ["-", "(", ")", "Backspace", "Tab"]
        const num = parseInt(e.key)

        if (e.key === "Enter" && onEnter) {
          onEnter()
        }

        if (
          !num &&
          num !== 0 &&
          allowlist.indexOf(e.key) === -1 &&
          !e.metaKey
        ) {
          e.preventDefault()
        }
      }}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

export default NumberInput
