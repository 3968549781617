import { List, Map } from "immutable"
import { Coords } from "google-map-react"
import { Props as Marker } from "../Map/Markers/Marker"

interface State {
  polygons: Map<string, List<Coords>>
  markers: Map<string, Marker>
  focusedMarker?: string
  draggable: boolean
  defaultCenter: Coords
  defaultZoom: number
  defaultBounds?: number[]
  center: Coords
  zoom: number
  bounds?: number[]
  selectedPolygon?: string
  savedViewport?: {
    zoom: number
    center: Coords
  }
}

export enum ActionType {
  ShowPolygon = "show polygon",
  SetPolygons = "set polygons",
  SetMarkers = "set markers",
  AddMarker = "add marker",
  UpdateMarker = "update marker",
  SetFocusedMarker = "set focused marker",
  SetDraggable = "set draggable",
  SetDefaultCenter = "set default center",
  SetDefaultZoom = "set default zoom",
  SetCenter = "set center",
  SetZoom = "set zoom",
  SetSelectedPolygon = "set selected polygon",
  SetDefaultBounds = "set default bounds",
  SetBounds = "set bounds",
  SaveViewport = "save viewport",
}

interface Action {
  type: ActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

const mapReducer = (state: State, action: Action): State => {
  const newState = { ...state }
  switch (action.type) {
    case ActionType.SetPolygons:
      newState.polygons = action.data
      break
    case ActionType.SetMarkers:
      if (state.focusedMarker) {
        if (action.data.get(state.focusedMarker)) {
          action.data = action.data.set(state.focusedMarker, {
            ...(action.data.get(state.focusedMarker) as Marker),
            focused: true,
          })
        } else {
          newState.focusedMarker = undefined
        }
      }
      newState.markers = action.data
      break
    case ActionType.AddMarker:
      newState.markers = newState.markers.set(action.data.id, action.data)
      break
    case ActionType.UpdateMarker:
      newState.markers = newState.markers.set(action.data.id, action.data)
      break
    case ActionType.SetFocusedMarker:
      let newMarkers = state.markers
      if (state.focusedMarker && newMarkers.get(state.focusedMarker)) {
        newMarkers = newMarkers.set(state.focusedMarker, {
          ...(newMarkers.get(state.focusedMarker) as Marker),
          focused: false,
        })
      }

      if (action.data && newMarkers.get(action.data)) {
        newMarkers = newMarkers.set(action.data, {
          ...(newMarkers.get(action.data) as Marker),
          focused: true,
        })
      }
      newState.focusedMarker = action.data
      newState.markers = newMarkers
      break
    case ActionType.SetDraggable:
      newState.draggable = action.data
      break
    case ActionType.SetDefaultCenter:
      newState.defaultCenter = action.data
      break
    case ActionType.SetDefaultZoom:
      newState.defaultZoom = action.data
      break
    case ActionType.SetCenter:
      newState.center = action.data
      break
    case ActionType.SetZoom:
      newState.zoom = action.data
      break
    case ActionType.SetSelectedPolygon:
      newState.selectedPolygon = action.data
      break
    case ActionType.SetDefaultBounds:
      newState.defaultBounds = action.data
      break
    case ActionType.SetBounds:
      newState.bounds = action.data
      break
    case ActionType.SaveViewport:
      newState.savedViewport = {
        center: state.center,
        zoom: state.zoom,
      }
      break
    default:
      break
  }

  return newState
}

export default mapReducer
