import { sendSlackMsg } from "api/incident"
import React, { useState } from "react"
import { Incident } from "types/incident"

import styles from "./Arrpoval.module.css"

interface Props {
  incident: Incident
}

interface Suggestion {
  suggestion: string
  incidentId: string
  incidentLocation: string
  incidentDescription: string
  requestDelete: boolean
  requestReview: boolean
  confirmIncident: boolean
}

const defaultSuggestion = {
  suggestion: "",
  incidentId: "",
  incidentLocation: "",
  incidentDescription: "",
  requestDelete: false,
  requestReview: false,
  confirmIncident: false,
}

const Approval: React.FC<Props> = ({ incident }) => {
  const [suggestion, setSuggestion] = useState<Suggestion>(defaultSuggestion)
  const [status, setStatus] = useState<string>("")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSuggestion({ ...suggestion, [name]: value })
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setSuggestion({ ...suggestion, [name]: value })
  }

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    let text = `Suggestion: ${suggestion.suggestion}\n`

    if (suggestion.requestReview) {
      text += `Request incident be reviewed for accuracy\n`
    }

    if (suggestion.confirmIncident) {
      text += `Confirm incident is accurate\n`
    }

    if (suggestion.requestDelete) {
      text += `Request incident be deleted\n`
    }

    let payload = {
      incident_id: incident.id,
      title: incident.title,
      text: text,
      location: incident.location,
    }

    sendSlackMsg(payload).subscribe(
      res => {
        setStatus("submitted")
      },
      err => {
        setStatus(err.message)
      },
    )
  }

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <input
            className={styles.input}
            type='checkbox'
            name='requestReview'
            onChange={handleInputChange}
          />
          <label className={styles.label}>
            Request incident be reviewed for accuracy
          </label>
        </div>

        <div className={styles.formGroup}>
          <input
            className={styles.input}
            type='checkbox'
            name='confirmIncident'
            onChange={handleInputChange}
          />
          <label className={styles.label}>Confirm incident is accurate </label>
        </div>

        <div className={styles.formGroup}>
          <input
            className={styles.input}
            type='checkbox'
            name='requestDelete'
            onChange={handleInputChange}
          />
          <label className={styles.label}>Request incident be deleted</label>
        </div>

        <div className={styles.input_group}>
          <label>Suggestion</label>
          <textarea
            className={styles.textarea}
            placeholder='Provide incident suggestions to analysts'
            name='suggestion'
            onChange={handleTextAreaChange}
          />
        </div>

        <button className={styles.button} onClick={handleOnClick}>
          Submit for review
        </button>
        <div className={styles.flex}>
          <p className={styles.status}>{status}</p>{" "}
          {status && (
            <span className={styles.small_button} onClick={() => setStatus("")}>
              x
            </span>
          )}
        </div>
      </form>
    </div>
  )
}

export default Approval
