import React, { FunctionComponent } from "react"
import styles from "./Small.module.css"
import classnames from "classnames"

export enum SmallType {
  Regular = "regular",
  Semibold = "semibold",
  Bold = "bold",
}

interface Props {
  type: SmallType
  className?: string
}

const Small: FunctionComponent<Props> = ({ type, children, className }) => {
  return (
    <small
      className={classnames(
        styles.small,
        styles[type.toLowerCase()],
        className,
      )}
    >
      {children}
    </small>
  )
}

export default Small
