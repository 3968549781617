import { flatMap, compact, get, first } from "lodash"
import { Coords } from "google-map-react"
import { getNeighborhoods, getServiceAreaCode, getPolice } from "api/address"
import { Location } from "types/incident"
import { LatLng, LatLngBounds } from "types/map"

interface GeocoderAddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface GeocoderPlace {
  gmaps: GoogleMapsMeta
  types: string[]
  description: string
  suggestion?: string
  location: Coords
}

interface GoogleMapsMeta {
  address_components: GeocoderAddressComponent[]
  types: string[]
  formatted_address: string
  geometry: PlaceGeometry
}

interface PlaceGeometry {
  location: LatLng
  viewport: LatLngBounds
}

const magic = 0.00062137119223733
export const toMeters = (miles = 0): number => miles / magic
export const toMiles = (meters = 0): number => meters * magic

export const extractAddressComponents = (
  place: GeocoderPlace,
): {
  [type: string]: GeocoderAddressComponent | undefined
} => {
  const addressComponents: GeocoderAddressComponent[] =
    place.gmaps.address_components
  const components: {
    [type: string]: GeocoderAddressComponent | undefined
  } = {}
  flatMap(addressComponents, "types").forEach((type: string): void => {
    components[type] = addressComponents.find(x => x.types.includes(type))
  })
  return components
}

export const extractLocation = (place: GeocoderPlace): string => {
  let name
  const { types = [] } = place.gmaps
  switch (types.length > 0) {
    case types.includes("subway_station"):
      name = place.description.split(",")[0]
      name = name.replace(" - ", "–")
      break
    default:
      const address = place.suggestion || place.gmaps.formatted_address
      name = address.split(",")[0]
  }
  return name
}

export const extractNeighborhood = (place: GeocoderPlace): string => {
  const components = extractAddressComponents(place)

  let locality = get(components, "locality.short_name")
  let sublocality =
    get(components, "sublocality_level_1.long_name") ||
    get(components, "sublocality.long_name") ||
    get(components, "neighborhood.long_name")
  if (!sublocality) {
    sublocality = get(components, "locality.short_name")
    locality =
      get(components, "administrative_area_level_2.short_name") ||
      get(components, "administrative_area_level_1.short_name")
  }

  const neighborhood = compact([sublocality, locality]).join(", ")
  return neighborhood
}

export const fetchNeighborhood = async (
  place: GeocoderPlace,
): Promise<string> => {
  try {
    const { gmaps, location } = place
    const { lat, lng } = location || gmaps.geometry.location
    const results = await getNeighborhoods(lat, lng)
    const [first] = results
    first.city = first.borough || first.city
    const neighborhood = `${first.name}, ${first.city}`
    // console.log('fetchNeighborhood:', neighborhood)
    return neighborhood
  } catch (e) {
    const neighborhood = await extractNeighborhood(place)
    return neighborhood
  }
}

export const fetchServiceAreaCode = async ({
  gmaps,
  location,
}: GeocoderPlace): Promise<string | undefined> => {
  try {
    const { lat, lng } = location || gmaps.geometry.location
    const results = await getServiceAreaCode(lat, lng)
    const { code } = results
    return code === "" ? undefined : code
  } catch (e) {
    return undefined
  }
}

export const fetchPolice = async ({
  gmaps,
  location,
}: GeocoderPlace): Promise<string | undefined> => {
  try {
    const { lat, lng } = location || gmaps.geometry.location
    const results = await getPolice(lat, lng)
    const [first] = results
    const police = first.name
    return police
  } catch (e) {
    return undefined
  }
}

export const extract = async (
  place: GeocoderPlace,
  forceDisplayName?: string,
): Promise<Location | false> => {
  if (!place || !place.hasOwnProperty("gmaps")) {
    return false
  }

  const location = extractLocation(place)

  const [neighborhood, cityCode, police] = await Promise.all([
    fetchNeighborhood(place),
    fetchServiceAreaCode(place),
    fetchPolice(place),
  ])

  const result = {
    address: place.suggestion || place.gmaps.formatted_address,
    location: forceDisplayName || location,
    neighborhood,
    cityCode,
    police,
    ...place.location,
  }

  // console.log("extract out", { result })
  return result
}

export const getPlacesFromLatLng = async ({
  lat,
  lng,
}: Coords): Promise<any[] | void> => {
  const geocoder = new window.google.maps.Geocoder()
  const resp = await new Promise<any[]>((resolve, reject) =>
    geocoder.geocode({ location: { lat, lng } }, (res, status) => {
      if (status === "OK") {
        resolve(res)
      } else {
        reject(status)
      }
    }),
  ).catch(e => {
    // eslint-disable-next-line no-console
    console.log(e)
  })

  return resp
}

export const getPlaceFromLatLng = async ({
  lat,
  lng,
}: Coords): Promise<GeocoderPlace | undefined> => {
  const resp = await getPlacesFromLatLng({ lat, lng })

  if (resp && resp.length) {
    const place = first(resp)
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div"),
    )
    const sessionToken = new window.google.maps.places.AutocompleteSessionToken()
    const { results, status } = await new Promise(resolve =>
      placesService.getDetails(
        { placeId: place.place_id, sessionToken },
        (results: any, status: any) => resolve({ results, status }),
      ),
    )

    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      return undefined
    }
    const gmaps = results
    const location = gmaps.geometry.location
    const geo = {
      ...place,
      gmaps,
      location: { lat: location.lat(), lng: location.lng() },
    }

    return geo
  }
}

export default {
  extract,
}
