import { useState, useEffect } from "react"
import { User, PSNUser } from "types/user"
import { get } from "lodash"
import { RoleType } from "api/member"

const usePSNUser = (psn?: string, user?: User) => {
  const [psnUser, setPsnUser] = useState<PSNUser>()

  useEffect(() => {
    if (psn && user) {
      setPsnUser(user.psns[psn])
    }
  }, [psn, user])

  return {
    psnUser,
    isAdmin: get(psnUser || {}, "role", false) === RoleType.SuperOperator,
  }
}

export default usePSNUser
