import React from "react"
import { Coords } from "google-map-react"
import { LatLngBounds } from "types/map"

export interface State {
  bounds?: LatLngBounds
  center?: Coords
  zoom?: number
}

const MapContext = React.createContext<State>({})

export default MapContext
