import React, { useState, useRef, FunctionComponent } from "react"
import styles from "./TextInput.module.css"
import classnames from "classnames"
import { EventType } from "emitter"
import useEmitter from "components/Hooks/useEmitter"
import Small, { SmallType } from "components/Texts/Small"
import fonts from "styles/fonts.module.css"

interface Props {
  placeholder?: string
  onChange?: (value: string) => void
  className?: string
  error?: string
  onKeyDown?: (e: React.KeyboardEvent) => void
  maxLength?: number
  tabIndex?: number
  inputClassName?: string
  onFocus?: () => void
  onBlur?: () => void
  id?: string
  value?: string
  description?: string
  disabled?: boolean
  allowClear?: boolean
  defaultValue?: string
  hideInput?: boolean
}

const TextInput: FunctionComponent<Props> = ({
  placeholder,
  onChange,
  className,
  error,
  onKeyDown,
  maxLength,
  tabIndex,
  inputClassName,
  onFocus,
  onBlur,
  id,
  value,
  description,
  disabled,
  allowClear,
  defaultValue,
  hideInput = false,
}) => {
  const [focused, setFocused] = useState(false)
  const [inputVal, setInputVal] = useState("")
  const input = useRef<HTMLInputElement>(null)
  const eventMapping: { [event: string]: Function } = {}
  if (id) {
    eventMapping[EventType.FocusInput] = (data: string): void => {
      if (id === data && input.current) {
        input.current.focus()
      }
    }
  }
  useEmitter(eventMapping)

  return (
    <div className={classnames(styles.container, className)}>
      <div
        className={classnames(styles.input, inputClassName, {
          [styles.focused]: focused,
          [styles.disabled]: disabled,
          [styles.error]: !!error,
        })}
      >
        <input
          defaultValue={defaultValue}
          className={classnames(fonts.body, fonts.regular)}
          value={value}
          ref={input}
          tabIndex={tabIndex}
          type={hideInput ? "password" : "text"}
          placeholder={placeholder}
          onFocus={(): void => {
            setFocused(true)
            if (onFocus) {
              onFocus()
            }
          }}
          onBlur={(): void => {
            setFocused(false)
            if (onBlur) {
              onBlur()
            }
          }}
          onKeyDown={onKeyDown}
          onChange={({ target: { value } }): void => {
            setInputVal(value)
            if (onChange) {
              onChange(value)
            }
          }}
          maxLength={maxLength}
          disabled={disabled}
        ></input>
        {disabled ? (
          <div>
            <img src='/icons/lock.svg' alt='disabled' />
          </div>
        ) : (
          allowClear &&
          !!inputVal && (
            <button
              className={styles.clear}
              onClick={(): void => {
                if (input.current) {
                  input.current.value = ""
                  setInputVal("")
                  if (onChange) {
                    onChange("")
                  }
                }
              }}
            >
              <img src='/icons/clear.svg' alt='clear' />
            </button>
          )
        )}
      </div>
      {(description || error) && (
        <div
          className={classnames(styles.description, { [styles.error]: error })}
        >
          <Small type={SmallType.Regular}>{error || description}</Small>
        </div>
      )}
    </div>
  )
}

export default TextInput
